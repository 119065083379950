import React, { useState } from 'react';
import { Bonusdedicate1style } from "./style"
import TextEditor from '../../../../Preview/TextEditor';
import MediaEditor from '../../../../Preview/MediaEditor';
import TemplateLogo from '../../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../../Preview/TemplateWatermark';
import black_bg from "../../../../../../images/bonus-bg-1.png";
import price_bg from "../../../../../../images/price-tag-1.svg";

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Bonusdedicate1style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview ">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="tag-background-wrapper"

                                    style={{
                                        backgroundPosition: 'top',
                                        position: 'absolute',
                                        top: '0px',
                                        width: '100%',
                                        left: '1px',
                                        height: '212px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundImage: ` url( ${black_bg})`,

                                    }}

                                >

                                </div>
                            </div>
                            <div className="col-sm-12 p-0">
                                <div className="upper-content">
                                    <div className="head-box">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].heading.is_selected}
                                            layerId={props.templateText[0].id}
                                            // textId={props.templateText[0].heading.id}
                                            textId={3}
                                            text={props.templateText[0].heading.text}
                                            style={props.templateText[0].heading.style}
                                            type={props.templateType}
                                            setTextLength={props.setTextLength}
                                            templateNumber={props.templateNumber}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'h1',
                                                }

                                            }
                                        />
                                    </div>

                                    <div className="col-sm-6"
                                        style={{
                                            margin: "0 auto",
                                        }}
                                    >
                                        <div className={`product-wrappers  ${layer.image ? 'image-bg-border' : ''}`}>
                                            <MediaEditor
                                                layer={layer}
                                                setLayer={setLayer}
                                                data={props.templateText[0].image}
                                                mediaType="pImage"
                                                type={props.templateText[0].image.includes("mp4") ? 'video' : 'image'}
                                                setSelectImageLayer={props.setSelectImageLayer}
                                            />
                                        </div>
                                        <div className="price-tag">
                                            <img src={price_bg} alt="" />
                                            <div className="price-value">
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={props.templateText[0].is_selected}
                                                    textStatus={props.templateText[0].value.is_selected}
                                                    layerId={props.templateText[0].id}
                                                    // textId={props.templateText[0].value.id}
                                                    textId={1}
                                                    text={props.templateText[0].value.text}
                                                    style={props.templateText[0].value.style}
                                                    type={props.templateType}
                                                    blockType={
                                                        {
                                                            tag: 'span',
                                                        }

                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="text-wrappers m-2 ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            // textId={props.templateText[0].text.id}
                                            textId={2}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            templateNumber={props.templateNumber}
                                            type={props.templateType}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </Bonusdedicate1style >

    );

};
export default Template;