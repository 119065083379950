import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { addNewSide } from '../../../actions/videoAction';
import { FaCloudUploadAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { RiErrorWarningFill } from "react-icons/ri";
import closeModalx from "../../../images/closeModal.svg"
import introduction from "../../../images/introduction-template.svg"
import Product_Description from "../../../images/product-description.svg"
import faeature_dedicated from "../../../images/faeature-dedicated-slide.svg"
import pros_and_cons from "../../../images/pros-and-cons.svg"
import testimonial_and_reviews from "../../../images/testimonial-and-reviews.svg"
import bonuses from "../../../images/bonuses.svg"
import demo_video from "../../../images/demo-video.svg"
import Conclusion from "../../../images/conclusion.svg"
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { setAlert } from '../../../actions/alert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiTrash2 } from "react-icons/fi";
import swal from "sweetalert";

const AddSlide = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const videoData = useSelector(state => state.videos)
    const [activeId, setActiveId] = useState(0);
    const [message, setMessage] = useState('');
    const [percent, setPercent] = useState(0);
    const [addSlideLoader, setAddSlideLoader] = useState(false)
    const [loader, setLoader] = useState({
        status: false,
        imageName: 'Upload an Image'
    })
    const [demoDuration, setDemoDuration] = useState({
        demoUrl: '',
        duration: -1,
        limitMessage: ''
    })
    const [slide, setSlide] = useState([
        {
            catName: "Introduction",
            categoryId: 1,
            imgUrl: introduction,
            data: {
                campaignId: '',
                categoryId: '',
                productName: ''
            }
        },
        {
            catName: "Pros and Con",
            categoryId: 2,
            imgUrl: pros_and_cons,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
                prosAndCons: {
                    enable: true,
                    pros: [{
                        id: 0,
                        prosText: ''
                    }],
                    cons: [{
                        id: 0,
                        consText: ''
                    }]
                }
            }
        },
        {
            catName: "Product Description",
            categoryId: 3,
            imgUrl: Product_Description,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
            }
        },
        {
            catName: "Feature",
            categoryId: 4,
            imgUrl: faeature_dedicated,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
                features: {
                    enable: true,
                    feature:
                        [{
                            id: 0,
                            heading: '',
                            image: '',
                            details: ''
                        }]
                },
            }
        },
        {
            catName: "Testimonial",
            categoryId: 6,
            imgUrl: testimonial_and_reviews,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
                testimonials: {
                    enable: true,
                    testimonial:
                        [{
                            id: 0,
                            name: '',
                            designation: '',
                            image: '',
                            message: '',
                        }]
                },
            }
        },
        {
            catName: "Conclusion",
            categoryId: 7,
            imgUrl: Conclusion,
            data: {
                campaignId: '',
                categoryId: '',
                productName: ''
            }
        },
        {
            catName: "Bonuses",
            categoryId: 8,
            imgUrl: bonuses,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
                bonuses: {
                    enable: true,
                    bonus:
                        [{
                            id: 0,
                            name: '',
                            value: '',
                            description: '',
                            image: ''
                        }]
                },
            }
        },
        {
            catName: "Demo",
            categoryId: 10,
            imgUrl: demo_video,
            data: {
                campaignId: '',
                categoryId: '',
                productName: '',
                demo: {
                    enable: true,
                    demo: [{
                        id: 0,
                        src: '',
                        type: 'file',
                        url: ''
                    }]
                },
            }
        }
    ])


    useEffect(() => {
        if (videoData) {
            let val = [...slide]
            val.forEach((curElem) => {
                curElem.data = {
                    ...curElem.data,
                    campaignId: videoData.data.campaignId,
                    categoryId: curElem.categoryId,
                    productName: videoData.data.productName
                }
            })
            setSlide(val)
        }
    }, [videoData])



    const handleCategory = (index) => {
        setActiveId(index)
    }

    const handleAdd = (type) => {
        let prosId = 0
        let consId = 0
        let text
        let val

        if (slide[1].data.prosAndCons.pros.length > 0) {
            prosId = slide[1].data.prosAndCons.pros[slide[1].data.prosAndCons.pros.length - 1].id
            prosId += 1
        }
        if (slide[1].data.prosAndCons.cons.length > 0) {
            consId = slide[1].data.prosAndCons.cons[slide[1].data.prosAndCons.cons.length - 1].id
            consId += 1
        }

        if (type === 'PROS') {
            text = [...slide[1].data.prosAndCons.pros]
            text.push({ id: prosId, prosText: '' })
            val = [...slide]
            val[1].data.prosAndCons.pros = text
        }
        else if (type === 'CONS') {
            text = [...slide[1].data.prosAndCons.cons]
            text.push({ id: consId, consText: '' })
            val = [...slide]
            val[1].data.prosAndCons.cons = text
        }
        setSlide(val)
    }

    const UpdateSlideAfterAdd = (activeSlideId) => {
        let data, val
        val = [...slide]
        if (activeSlideId === 3) {
            data = [...slide[activeSlideId].data.features.feature]
            data[0] = {
                ...data[0],
                heading: '',
                image: '',
                details: ''
            }
            val[activeSlideId].data.features.feature = data
        }
        else if (activeSlideId === 4) {
            data = [...slide[activeSlideId].data.testimonials.testimonial]
            data[0] = {
                ...data[0],
                name: '',
                designation: '',
                image: '',
                message: '',
            }
            val[activeSlideId].data.testimonials.testimonial = data
        }
        else if (activeSlideId === 6) {
            data = [...slide[activeSlideId].data.bonuses.bonus]
            data[0] = {
                ...data[0],
                name: '',
                value: '',
                description: '',
                image: ''
            }
            val[activeSlideId].data.bonuses.bonus = data
        }
        setSlide(val)
        setLoader({
            ...loader,
            imageName: 'Upload an Image'
        })
    }

    const handleSubmit = (e, catId) => {
        e.preventDefault()
        setAddSlideLoader(true)
        const data = slide[catId].data
        axios({
            method: 'POST',
            url: `${baseURL}add-slide`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then((res) => {
            if (res.data.status === true) {
                dispatch(addNewSide(res.data.data))
                UpdateSlideAfterAdd(catId)
            }
            else {
                dispatch(setAlert(res.data.message, "danger"))
            }
            setAddSlideLoader(false)
            handleClose(false)
        }).catch((error) => {
            console.log(error)
            setAddSlideLoader(false)
        })
    }

    const handleProsAndCons = (e, index) => {
        const { name, value } = e.target
        let val = [...slide]
        if (name === 'prosText') {

            let text = [...slide[1].data.prosAndCons.pros]
            text[index][name] = value
            val[1].data.prosAndCons.pros = text
        }
        else if (name === 'consText') {
            let text = [...slide[1].data.prosAndCons.cons]
            text[index][name] = value
            val[1].data.prosAndCons.cons = text
        }
        setSlide(val)
    }

    const handleDelete = (index, type) => {
        let val = [...slide]
        if (type === 'PROS') {
            let text = [...slide[1].data.prosAndCons.pros]
            text.splice(index, 1)
            val[1].data.prosAndCons.pros = text
        }
        else if (type === 'CONS') {
            let text = [...slide[1].data.prosAndCons.pros]
            text.splice(index, 1)
            val[1].data.prosAndCons.cons = text
        }
        setSlide(val)
    }

    const handleFeature = (e) => {
        const { name, value } = e.target
        let data = [...slide[3].data.features.feature]
        data[0][name] = value
        let val = [...slide]
        val[3].data.features.feature = data
        setSlide(val)
    }
    const handleTestimonial = (e) => {
        const { name, value } = e.target
        let data = [...slide[4].data.testimonials.testimonial]
        data[0][name] = value
        let val = [...slide]
        val[4].data.testimonials.testimonial = data
        setSlide(val)
    }
    const handleBonuses = (e) => {
        const { name, value } = e.target
        let data = [...slide[6].data.bonuses.bonus]
        let temp = value
        if (name === "value") {
            temp = value.match('[$₹£]?[0-9]*')
            temp = temp[0]
        }
        data[0][name] = temp
        let val = [...slide]
        val[6].data.bonuses.bonus = data
        setSlide(val)
    }
    const handleDemo = (e) => {
        const { name, value } = e.target
        let data = [...slide[7].data.demo.demo]
        data[0][name] = value
        if (data[0].type === "customUrl" && name === "src") {
            setDemoDuration({
                ...demoDuration,
                demoUrl: value,
            })
        }
        if (e.target.name === 'type') {
            data[0].src = ''
        }
        let val = [...slide]
        val[7].data.demo.demo = data
        setSlide(val)
    }

    useEffect(() => {
        if (demoDuration.duration > 300) {
            setDemoDuration({
                ...demoDuration,
                limitMessage: 'Your demo video URL duration is more than 5 minutes, please use a maximum of 5 minutes video'
            })
        }
    }, [demoDuration.duration])

    if (demoDuration.limitMessage !== '') {
        setTimeout(() => {
            setDemoDuration({
                ...demoDuration,
                limitMessage: '',
                demoUrl: '',
                duration: -1
            })
            let data = [...slide[7].data.demo.demo]
            data[0].src = ''
            let val = [...slide]
            val[7].data.demo.demo = data
            setSlide(val)
        }, 5000)
    }

    useEffect(() => {
        let au = document.createElement('video');
        au.src = demoDuration.demoUrl;
        au.addEventListener('loadedmetadata', function () {
            setDemoDuration({
                ...demoDuration,
                duration: Number((au.duration).toFixed(1))
            });
        }, false);

    }, [slide[7].data.demo.demo])

    const updateResponse = (temp) => {
        const { name, path, type } = temp

        if (type === 'feature') {
            let data = [...slide[3].data.features.feature]
            data[0] = {
                ...data[0],
                image: path
            }
            let val = [...slide]
            val[3].data.features.feature = data
            setSlide(val)
        }
        else if (type === 'testimonial') {
            let data = [...slide[4].data.testimonials.testimonial]
            data[0] = {
                ...data[0],
                image: path
            }
            let val = [...slide]
            val[4].data.testimonials.testimonial = data
            setSlide(val)



        }
        else if (type === 'bonus') {

            let data = [...slide[6].data.bonuses.bonus]
            data[0] = {
                ...data[0],
                image: path
            }
            let val = [...slide]
            val[6].data.bonuses.bonus = data
            setSlide(val)


        }
        else if (type === 'video') {
            let data = [...slide[7].data.demo.demo]
            data[0] = {
                ...data[0],
                src: path,
                url: temp.thumbnial
            }
            let val = [...slide]
            val[7].data.demo.demo = data
            setSlide(val)
            // setDemoThumb(thumbnial)
        }
        let imageName = name.length > 15 ? name.slice(0, 15) + "..." : name
        setLoader({
            ...loader,
            status: false,
            imageName: imageName
        })
    }
    const onInputFile = (e, type) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let num = Math.floor((loaded * 100) / total);
                setPercent(num);
            },
            headers: {
                'Authorization': auth.token
            }
        }

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {
                let allowedSize = 5000000;
                if (e.target.files[0].size < allowedSize) {
                    setLoader({
                        ...loader,
                        status: true
                    })
                    const formData = new FormData();
                    formData.append('type', type);
                    formData.append('file', e.target.files[0])
                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                updateResponse(res.data)
                            }
                            setPercent(0)
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }
                else {
                    swal("Oops!", "Max allowed size for image 5MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }
    const onInputVideoFile = (e) => {
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let num = Math.floor((loaded * 100) / total);
                setPercent(num);
            },
            headers: {
                'Authorization': auth.token
            }
        }
        let allowedSize = 20000000;
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].type === "video/mp4") {
                if (e.target.files[0].size < allowedSize) {
                    setLoader({
                        ...loader,
                        status: true
                    })
                    const formData = new FormData();
                    formData.append('type', 'video');
                    formData.append('file', e.target.files[0])
                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                updateResponse(res.data)
                            }
                            setPercent(0);
                        }).catch((error) => {
                            console.log(error)
                        })

                }
                else {
                    swal("Oops!", "Max allowed size for video is 20MB");
                }
            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }


    const cards = {
        prosAndCons: <div className="personal-details-box">
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="form-box form-space mt-2">
                        <div className="lineTxt-wrap">
                            <h6 className="lineTxt"><img src={pros_and_cons} alt="" /> Pros and Cons</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="singleTitle">
                                    <span>Pros</span>
                                    <FiPlusCircle
                                        onClick={() => handleAdd('PROS')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>

                                {slide[1].data.prosAndCons.pros.map((curElem, index) => {
                                    return (
                                        <OverlayTrigger
                                            key={index}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Benefits of the product and what it does well.
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group add">
                                                <textarea
                                                    className="form-control"
                                                    placeholder=""
                                                    name="prosText"
                                                    value={curElem.prosText}
                                                    required
                                                    onChange={(e) => handleProsAndCons(e, index)}
                                                />
                                                <FiTrash2
                                                    style={{ cursor: 'pointer' }}
                                                    className="delIcon"
                                                    onClick={() => handleDelete(index, 'PROS')}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )
                                })}



                            </div>
                            <div className="col-md-6">
                                <div className="singleTitle">
                                    <span>Cons</span>
                                    <FiPlusCircle
                                        onClick={() => handleAdd('CONS')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                {slide[1].data.prosAndCons.cons.map((curElem, index) => {
                                    return (
                                        <OverlayTrigger
                                            key={index}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Disadvantages of the product and what it can improve on.
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group add">
                                                <textarea
                                                    className="form-control"
                                                    placeholder=""
                                                    name="consText"
                                                    required
                                                    value={curElem.consText}
                                                    onChange={(e) => handleProsAndCons(e, index)}
                                                />
                                                <FiTrash2
                                                    style={{ cursor: 'pointer' }}
                                                    className="delIcon"
                                                    onClick={() => handleDelete(index, 'CONS')}
                                                />

                                            </div>
                                        </OverlayTrigger>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        feature: <div className="personal-details-box">
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="form-box form-space mt-2">
                        <div className="lineTxt-wrap">
                            <h6 className="lineTxt"><img src={faeature_dedicated} alt="" /> Feature </h6>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="singleTitle mb-3">
                                    <span>Add details about feature</span>
                                </div>
                            </div>
                        </div>

                        <div className="groupDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Name of the product feature you want to highlight.
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Heading</small>
                                            <textarea
                                                className="form-control"
                                                placeholder="Heading"
                                                name="heading"
                                                onChange={(e) => handleFeature(e)}
                                                required
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Image of the product you are reviewing
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Image</small>
                                            <div className="imgUp">
                                                <input
                                                    type="file"
                                                    name="featureImage"
                                                    onChange={(e) => onInputFile(e, 'feature')}
                                                />
                                                {
                                                    loader.status ?
                                                        <>
                                                            <span style={{ position: 'absolute' }}>{percent}%</span>
                                                            < i className="fa fa-spinner fa-spin resize-loader" />
                                                        </>
                                                        :
                                                        <div className={slide[3].data.features.feature[0].image !== '' ? 'color-image-name' : ''}>
                                                            <FaCloudUploadAlt />
                                                            {loader.imageName}
                                                        </div>
                                                }
                                            </div>
                                            <div className="img-box ">
                                                <img src={slide[3].data.features.feature[0].image} alt="" className="create-video-image" />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        A detailed description of the product feature.
                                    </Tooltip>
                                }
                            >
                                <div className="form-group">
                                    <small className="form-text text-muted">Details</small>
                                    <textarea
                                        type="text"
                                        name="details"
                                        className="form-control"
                                        placeholder="Details"
                                        onChange={(e) => handleFeature(e)}
                                    />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        testimonial: <div className="personal-details-box">
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="form-box form-space mt-2">
                        <div className="lineTxt-wrap">
                            <h6 className="lineTxt">
                                <img src={testimonial_and_reviews} alt="" /> Testimonial</h6>
                        </div>
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="singleTitle mb-3">
                                        <span>Add Testimonial</span>
                                    </div>
                                </div>
                            </div>

                            <div className="groupDetails">
                                <div className="row">
                                    <div className="col-md-6">
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Name of the person/s who provided the testimonial.
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group">
                                                <small className="form-text text-muted">Name</small>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    name="name"
                                                    onChange={(e) => handleTestimonial(e)}
                                                    required />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="col-md-6">
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Occupation or distinction of the person/s who provided the testimonial.
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group">
                                                <small className="form-text text-muted">Title</small>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Title"
                                                    name="designation"
                                                    onChange={(e) => handleTestimonial(e)}
                                                    required
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Image of the product you are reviewing
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group">
                                                <small className="form-text text-muted">Image</small>
                                                <div className="imgUp">
                                                    <input
                                                        type="file"
                                                        name="testimonialImage"
                                                        onChange={(e) => onInputFile(e, 'testimonial')}
                                                        required
                                                    />
                                                    {
                                                        loader.status ?
                                                            <>
                                                                <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                < i className="fa fa-spinner fa-spin resize-loader" />
                                                            </>
                                                            :
                                                            <div className={slide[4].data.testimonials.testimonial[0].image !== '' ? 'color-image-name' : ''}>
                                                                <FaCloudUploadAlt />
                                                                {loader.imageName}
                                                            </div>
                                                    }
                                                </div>
                                                <div className="img-box ">
                                                    <img src={slide[4].data.testimonials.testimonial[0].image} alt="" className="create-video-image" />
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="col-md-6">
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    The feedback given by the person/s who provided the testimonial.
                                                </Tooltip>
                                            }
                                        >
                                            <div className="form-group">
                                                <small className="form-text text-muted">Message</small>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Heading"
                                                    name="message"
                                                    onChange={(e) => handleTestimonial(e)}
                                                    required
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>,
        bonuses: <div className="personal-details-box">
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="form-box form-space mt-2">
                        <div className="lineTxt-wrap">
                            <h6 className="lineTxt"><img src={bonuses} alt="" /> Bonus</h6>

                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="singleTitle mb-3">
                                    <span>Add Bonus</span>
                                </div>
                            </div>
                        </div>

                        <div className="groupDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Name of the bonus pack that you'll be including with the product.
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Name</small>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                name="name"
                                                onChange={(e) => handleBonuses(e)}
                                                required
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Total monetary worth of the bonus pack.
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Value</small>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="$999"
                                                name="value"
                                                value={slide[6].data.bonuses.bonus[0].value}
                                                min={1}
                                                onChange={(e) => handleBonuses(e)}
                                                required
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                Definition of the bonus pack you'll be including with the product.
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Description</small>
                                            <textarea
                                                className="form-control"
                                                placeholder="Heading"
                                                name="description"
                                                onChange={(e) => handleBonuses(e)}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Image of the product you are reviewing
                                            </Tooltip>
                                        }
                                    >
                                        <div className="form-group">
                                            <small className="form-text text-muted">Image</small>
                                            <div className="imgUp">

                                                <input
                                                    type="file"
                                                    name="bonusImage"
                                                    onChange={(e) => onInputFile(e, 'bonus')}
                                                />
                                                {
                                                    loader.status ?
                                                        <>
                                                            <span style={{ position: 'absolute' }}>{percent}%</span>
                                                            < i className="fa fa-spinner fa-spin resize-loader" />
                                                        </>
                                                        :
                                                        <div className={slide[6].data.bonuses.bonus[0].image !== '' ? 'color-image-name' : ''}>
                                                            <FaCloudUploadAlt />
                                                            {loader.imageName}
                                                        </div>
                                                }
                                            </div>
                                            <div className="img-box ">
                                                <img src={slide[6].data.bonuses.bonus[0].image} alt="" className="create-video-image" />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        demo: <div className="personal-details-box">
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="form-box form-space mt-2">
                        <div className="lineTxt-wrap">
                            <h6 className="lineTxt"><img src={demo_video} alt="" /> Demo</h6>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="singleTitle mb-3">
                                    <span>Add Demo</span>
                                </div>
                            </div>
                        </div>

                        <div className="groupDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Image of the product you are reviewing
                                            </Tooltip>
                                        }
                                    >
                                        <>
                                            {slide[7].data.demo.demo[0].type !== 'file' ?
                                                <div className="form-group">
                                                    <small className="form-text text-muted text-capitalize" >Enter {slide[7].data.demo.demo[0].type.replace("Url", "")} URL</small> {/* set dynamic url #sanjay sir(demo video option) */}
                                                    <input
                                                        type="text"
                                                        placeholder={`${slide[7].data.demo.demo[0].type.replace("Url", "")} URL`}
                                                        className="form-control alt text-capitalize"
                                                        name="src"
                                                        value={slide[7].data.demo.demo[0].src}
                                                        onChange={(e) => handleDemo(e)}

                                                    />
                                                </div> :
                                                <div className="form-group">

                                                    {/* <small className="form-text text-muted">Enter Video File</small> */}
                                                    <div className="imgUp ">
                                                        <input
                                                            type="file"
                                                            name="demoUrl"
                                                            onChange={e => onInputVideoFile(e)}
                                                        />

                                                        {
                                                            loader.status ?
                                                                <>
                                                                    <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                    < i className="fa fa-spinner fa-spin resize-loader" />
                                                                </>
                                                                :
                                                                <div className={slide[7].data.demo.demo[0].src !== '' ? 'color-image-name' : ''}>
                                                                    <FaCloudUploadAlt />
                                                                    {slide[7].data.demo.demo[0].src !== '' ? loader.imageName : loader.imageName.replace('an Image', 'Video')}
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className=" demo-box ">
                                                        <img src={slide[7].data.demo.demo[0].url} alt="" className="create-video-image" />
                                                    </div>
                                                </div>
                                            }
                                        </>

                                    </OverlayTrigger>

                                </div>

                                <div className="col-md-6">
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                The definition of the product and what it does.
                                            </Tooltip>
                                        }
                                    >

                                        <div className="form-group">
                                            <small className="form-text text-muted">Choose Video Type</small>
                                            <select
                                                className="form-control"
                                                name="type"
                                                onChange={(e) => handleDemo(e)}
                                            >
                                                <option value={"file"}>Import File From Computer</option>
                                                <option value={"youtube"}> YouTube </option>
                                                <option value={"vimeo"}>  Vimeo</option>
                                                <option value={"customUrl"}>URL</option>
                                            </select>

                                        </div>
                                    </OverlayTrigger>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="text-light demo-message">
                {demoDuration.limitMessage !== '' ?
                    <p><RiErrorWarningFill fontSize={20} color={"crimson"} /> {demoDuration.limitMessage}</p> : ''}
            </div>
        </div>

    }



    return (
        <Modal centered show={show} onHide={handleClose}>
            <form className="modal-dialog modalEdit" role="document" onSubmit={(e) => handleSubmit(e, activeId)}>
                <div className="modal-content slide-content">
                    <div className="modal-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 className="modal-title ">Please select a category to create a new slide</h5>
                            </div>
                        </div>
                    </div>
                    <button onClick={handleClose} type="button" className="close img-close" >
                        <img src={closeModalx} alt="" />
                    </button>
                    <div className="modal-body">
                        <div className="row">

                        </div>
                        <div className="row">
                            {slide.map((curElem, index) => {
                                return (
                                    <div
                                        className="col-lg-3"
                                        key={index}
                                        onClick={() => handleCategory(index)}
                                    >
                                        <div className={`cateSingle ${index === activeId ? 'cate-active' : ''}`}>
                                            <div className="cateImg text-center">
                                                <img src={curElem.imgUrl} alt="" />
                                            </div>
                                            <p>{curElem.catName}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='text-center text-light'>
                                    {message}
                                </div>
                                {slide[activeId].categoryId === 2 ?
                                    cards.prosAndCons : ''
                                }
                                {slide[activeId].categoryId === 4 ?
                                    cards.feature : ''
                                }
                                {slide[activeId].categoryId === 6 ?
                                    cards.testimonial : ''
                                }
                                {slide[activeId].categoryId === 8 ?
                                    cards.bonuses : ''
                                }
                                {slide[activeId].categoryId === 10 ?
                                    cards.demo : ''
                                }
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-3 mx-auto">
                                <div className="cate-submit-btn">
                                    <button
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        type="submit"
                                        className="btn"
                                    >
                                        {addSlideLoader ? <>
                                            Adding Slide <i className="fa fa-spinner fa-spin" />  </> : 'Add Slide'}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </form>

        </Modal>
    );
}

export default AddSlide;
