import React, { useState } from 'react';
import { Conclusion1style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Conclusion1style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview ">
                    <div className="container p-0">
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 p-0">
                                <div className="head-box">
                                    <h1 m-0>conclusion</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row m-0 p-0">
                            <div className="col-sm-12 p-0">
                                <hr className="hr_line" style={{ height: "3px", opacity: "1", color: "#000" }} />
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-sm-12 p-0 ">
                                <div className="text-wrappers ">
                                    <TextEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        layerStatus={props.templateText[0].is_selected}
                                        textStatus={props.templateText[0].text.is_selected}
                                        layerId={props.templateText[0].id}
                                        text={props.templateText[0].text.text}
                                        style={props.templateText[0].text.style}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        blockType={
                                            {
                                                tag: 'p',
                                            }

                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 p-0">
                                <div className="text-wrappers-2 ">
                                    <TextEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        layerStatus={props.templateText[1].is_selected}
                                        textStatus={props.templateText[1].text.is_selected}
                                        layerId={props.templateText[1].id}
                                        text={props.templateText[1].text.text}
                                        style={props.templateText[1].text.style}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        blockType={
                                            {
                                                tag: 'p',
                                            }

                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </Conclusion1style >
    );

};
export default Template;