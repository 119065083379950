import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateAffiliateStatus,
    updateAffiliatePos,
    updateAffiliateColor,
    updateAffiliateBgColor,
    updateAffiliateSize,
    updateAffiliateFamily,
    addAffiliateText,
}
    from "../../../actions/videoAction";
import { SketchPicker } from "react-color";
import rgbHex from "rgb-hex";


const Affiliate = ({ fonts }) => {
    const dispatch = useDispatch();
    const video = useSelector(state => state.videos.data)
    const [position, setPosition] = useState([
        {
            is_select: false,
            pos: 'top-left'
        },
        {
            is_select: false,
            pos: 'top-center'
        },
        {
            is_select: false,
            pos: 'top-right'
        },
        {
            is_select: false,
            pos: 'center-left'
        },
        {
            is_select: false,
            pos: 'center'
        },
        {
            is_select: false,
            pos: 'center-right'
        },
        {
            is_select: false,
            pos: 'bottom-left'
        },
        {
            is_select: true,
            pos: 'bottom-center'
        },
        {
            is_select: false,
            pos: 'bottom-right'
        },
    ])
    const [textColor, setTextColor] = useState({
        enable: false,
        r: 255,
        g: 255,
        b: 255
    });
    const [showBgColor, setShowBgColor] = useState({
        enable: false,
        r: 40,
        g: 39,
        b: 39
    });

    const handleChange = (text) => {
        dispatch(addAffiliateText(text))
    }
    const handleToggle = (toggle) => {
        dispatch(updateAffiliateStatus(toggle))
    }
    const onInputFontSizeChange = (size) => {
        dispatch(updateAffiliateSize(size))
    }
    const onInputFontFamilyChange = (family) => {
        dispatch(updateAffiliateFamily(family))
    }

    const handlePos = (index) => {
        let data = [...position]
        data.forEach((curElem) => {
            if (curElem.is_select === true) {
                curElem.is_select = false
            }
        })
        data[index].is_select = true
        dispatch(updateAffiliatePos(data[index].pos))
        setPosition(data)
    }

    const getHexNumbers = (color, type) => {
        let rgbArray = color.split("(")
        rgbArray = rgbArray[1].split(",")
        let splitB = rgbArray[2].split(")")

        const r = rgbArray[0]
        const g = rgbArray[1]
        const b = splitB[0]
        if (type === 'TEXT') {
            setTextColor({
                ...textColor,
                r: r,
                g: g,
                b: b
            })
        }
        else {
            setShowBgColor({
                ...showBgColor,
                r: r,
                g: g,
                b: b
            })
        }
    }
    const onChangeColor = (color) => {
        getHexNumbers(color, 'TEXT')
        let hex = '#' + rgbHex(color);
        dispatch(updateAffiliateColor(hex));
    }

    const onChangeBgColor = (color) => {
        getHexNumbers(color, 'BG')
        let hex = '#' + rgbHex(color);
        dispatch(updateAffiliateBgColor(hex));
    }

    const onInputColor = (e) => {
        let rgbaColor = e.hex
        setTextColor({
            ...textColor,
            r: e.rgb.r,
            g: e.rgb.g,
            b: e.rgb.b
        })
        dispatch(updateAffiliateColor(rgbaColor));
    }

    const onInputBgColor = (e) => {
        let rgbaColor = e.hex
        setShowBgColor({
            ...showBgColor,
            r: e.rgb.r,
            g: e.rgb.g,
            b: e.rgb.b
        })
        dispatch(updateAffiliateBgColor(rgbaColor));
    }

    const removeBgColor = () => {
        dispatch(updateAffiliateBgColor(false));
    }


    return (

        <div className="add-block-single">
            {video.affiliate_link !== "" ?
                <div className="add-block-main">
                    <div className="add-block-title mb-2 ">
                        <h2 style={{ letterSpacing: '1px' }}>Affiliate URL</h2>
                        <div className="switch-single">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={video.affiliate_link.enable}
                                    onChange={(e) => handleToggle(e.target.checked)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    {video.affiliate_link.enable ?
                        <>
                            <div className="mediaList">
                                <div className=" row">
                                    <div className=" col-md-7">
                                        <div className="form-group">
                                            <small className="form-text  mb-2" style={{ fontSize: "14px", color: "#fff" }}>Text</small>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Affiliate Text "
                                                name="message"
                                                required
                                                rows="4"
                                                value={video.affiliate_link.text}
                                                onChange={(e) => handleChange(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className=" col-md-5 p-0">
                                        <div className="position-text-box">
                                            <div className="text-position-dropdown">
                                                <label>Position</label>
                                                <div className="box-wrapper" >
                                                    {position.map((curElem, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`box ${curElem.is_select ? 'box-active' : ''}`}
                                                                onClick={() => handlePos(index)}
                                                            >
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="txt-format">
                                <div className="fontStyle-block">
                                    <h2 className='mb-2'>Font Family</h2>
                                    <div className="row">
                                        <div className="col-8 ">
                                            <select
                                                classNamePrefix='mySelect'
                                                id="sel-font"
                                                name="font_family"
                                                className='cursor-pointer text-hover-effect'
                                                onChange={(e) => onInputFontFamilyChange(e.target.value)}
                                            >
                                                <option disabled selected>Select Font</option>
                                                {
                                                    fonts.length > 0 ?
                                                        fonts.map((font, index) => {
                                                            return (
                                                                <option style={{ fontFamily: font.value }}
                                                                    selected={font.value === video.affiliate_link.fontFamily}
                                                                    value={font.value} key={index}>{font.name}</option>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                        <div className="col-4" data-toggle="tooltip" title="Font Size">
                                            <input
                                                id="f-size"
                                                type="number"
                                                className="edit-input-font-size text-hover-effect"
                                                name="f-size"
                                                min="10"
                                                value={video.affiliate_link.fontSize}
                                                onChange={(e) => onInputFontSizeChange(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="fontStyle-block-wrap-2">
                                    <h2>Font Color</h2>
                                    <div className="fontStyle-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="inpWrap">
                                                    <input
                                                        className="pl-5"
                                                        type="text"
                                                        value={video.affiliate_link.color}
                                                        readOnly
                                                    />
                                                    <span
                                                        className="colorValue cursor-pointer"
                                                        id="t-color-btn"
                                                        style={{ background: `${video.affiliate_link.color !== false ? video.affiliate_link.color : '#fff'}` }}
                                                        onClick={() => setTextColor({
                                                            ...textColor,
                                                            enable: true
                                                        })}
                                                    ></span>
                                                    <span className="label">HEX</span>

                                                    {textColor.enable ?
                                                        <>
                                                            <div
                                                                className="swatch-cover"
                                                                onClick={(e) => setTextColor({
                                                                    ...textColor,
                                                                    enable: false
                                                                })}
                                                            />
                                                            <SketchPicker color={video.affiliate_link.color} onChange={(e) => onInputColor(e)} className="Affi-color-picker" />
                                                        </> : null}

                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={textColor.r} readOnly />
                                                    <span className="label">R</span>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={textColor.g} readOnly />
                                                    <span className="label">G</span>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={textColor.b} readOnly />
                                                    <span className="label">B</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="color-palette">
                                        <ul>

                                            <li>
                                                <div
                                                    style={{ background: "#FFF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(255, 255, 255)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#E1E1E1" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(225, 225, 225)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#D7D7D7" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(215, 215, 215)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#CCCCCC" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(204, 204, 204)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#C2C2C2" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(194, 194, 194)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#B7B7B7" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(183, 183, 183)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#A1A1A1" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(161, 161, 161)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#959595" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(149, 149, 149)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#E8C9FF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(232, 201, 255)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#C87EFF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(200, 126, 255)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#F34AFF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(243, 74, 255)")}>
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#D19DF8" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(209, 157, 248)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#9B33E9" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(155, 51, 233)")}>
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#8133DB" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(129, 51, 219)")}>
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#7033D5" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(112, 51, 213)")}>
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#23803F" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(35, 128, 63)")}
                                                >

                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#54C423" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(84, 196, 35)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#606888" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(96, 104, 136)")}>

                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#7C83AC" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(124, 131, 172)")}
                                                >
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#5B69BE" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(91, 105, 190)")}>

                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#30409B" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(48, 64, 155)")}
                                                >

                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    style={{ background: "#223CDB" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeColor("rgb(34, 60, 219)")}
                                                >

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="fontStyle-block-wrap-2">
                                    <h2>Font Background</h2>
                                    <div className="fontStyle-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="inpWrap">
                                                    <input
                                                        className="pl-5"
                                                        type="text"
                                                        value={video.affiliate_link.bgColor !== false ? video.affiliate_link.bgColor : '#282727'}
                                                        readOnly
                                                    />
                                                    <span
                                                        className="colorValue cursor-pointer"
                                                        style={{ backgroundColor: `${video.affiliate_link.bgColor !== false ? video.affiliate_link.bgColor : '#282727'}` }}
                                                        onClick={() => setShowBgColor({
                                                            ...showBgColor,
                                                            enable: true
                                                        })}
                                                    ></span>
                                                    {showBgColor.enable ?
                                                        <>
                                                            <div className="swatch-cover" onClick={(e) => setShowBgColor({
                                                                ...showBgColor,
                                                                enable: false
                                                            })}
                                                            />

                                                            <SketchPicker color={video.affiliate_link.bgColor !== false ? video.affiliate_link.bgColor : '#282727'}
                                                                onChange={(e) => onInputBgColor(e)} className="Affi-color-picker-2"
                                                            />
                                                        </> : null}
                                                    <span className="label">HEX</span>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={showBgColor.r} />
                                                    <span className="label">R</span>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={showBgColor.g} />
                                                    <span className="label">G</span>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="inpWrap">
                                                    <input className="text-center" type="text" value={showBgColor.b} />
                                                    <span className="label">B</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="color-palette">
                                        <ul>

                                            <li style={{ position: 'relative', overflow: 'hidden' }}>
                                                <div
                                                    style={{ background: "#FFF" }}
                                                    className="color-palette-single cursor-pointer no-color"
                                                    onClick={() => removeBgColor()}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#E1E1E1" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(225, 225, 225)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#D7D7D7" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(215, 215, 215)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#CCCCCC" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(204, 204, 204)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#C2C2C2" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(194, 194, 194)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#B7B7B7" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(183, 183, 183)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#A1A1A1" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(161, 161, 161)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#959595" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(149, 149, 149)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#E8C9FF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(232, 201, 255)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#C87EFF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(200, 126, 255)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#F34AFF" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(243, 74, 255)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#D19DF8" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(209, 157, 248)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#9B33E9" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(155, 51, 233)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#8133DB" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(129, 51, 219)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#7033D5" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(112, 51, 213)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#23803F" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(35, 128, 63)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#54C423" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(84, 196, 35)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#606888" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(96, 104, 136)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#7C83AC" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(124, 131, 172)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#5B69BE" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(91, 105, 190)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#30409B" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(48, 64, 155)")}
                                                >
                                                </div>
                                            </li>


                                            <li>
                                                <div
                                                    style={{ background: "#223CDB" }}
                                                    className="color-palette-single cursor-pointer"
                                                    onClick={() => onChangeBgColor("rgb(255, 255, 255)")}
                                                >
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </>
                        : ''}
                </div> : ''}
        </div>


    )
}
export default Affiliate 
