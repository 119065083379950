import styled from "styled-components";
export const Props_Cons2style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
    max-height: 100%;
   
  }

.template-preview {
    width:100%;
    position: absolute;
    top:0;
}
.text-wrappers{
    width:190px;
}
.card-text{
    position: absolute;
    top: 34px;
}

.card-wrapper-1 {
    position: absolute;
    top:30px;
}

.card-wrapper-2 {
    position: absolute;
    right: 13px;
    top:30px;
}

.circle_wrapper {
    position: absolute;
    left: -5px;
    top: 6px;
    z-index: 1;
}

.circle_wrapper-2 {
    position: absolute;
    left: -13px;
    top: 0;
    z-index: 1;
}

.circle-shadow {
    filter: drop-shadow(3px 3px 0px rgba(50, 50, 0, 0.5)) !important;
}

.circle-1{
    width: 50px;
    height: 50px;
    background: #EE7D31;
    position: absolute;
    top: -26px;
    left:94px;

}

.circle-2{
    width: 50px;
    height: 50px;
    background: #599BD5;
    position: absolute;
    top: -26px;
    left:106px;
}


.card{
    border: none;
    border-radius: 0;

}

.shadow-wrapper{
    filter: drop-shadow(3px 3px 1px rgba(50, 50, 0, 0.5)) !important;
}

.box-1{
    width: 14rem;
    height: 18rem;
    position: relative;
}

.box-2{
    width: 14rem;
    height: 18rem;
    position: relative;
}

.pro-text{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 13px;
    color: #fff;
    font-size: 20px;
    font-family: 'PT Serif Caption', serif;
}

.con-text{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 13px;
    color: #fff;
    font-size: 20px;
    font-family: 'PT Serif Caption', serif;
    transform: rotate(180deg);
}

.arrow-box{
    position: relative;
    top: 125px;
    left: 34px;
}

.arrow-1{
    clip-path: polygon(0% 0%,80% 0%,100% 50%,80% 100%,0% 100%);
    width: 7rem;
    height: 3.5rem;
    top: 2px;
    left: 24px;
    position: absolute;
    background: #599BD5;
}


.arrow_wrapper-2 {
    position: relative;
    transform: rotate(180deg);
    display: inline-block;
    top: 6rem;
    left: 1rem;
}


.arrow-2 {
    clip-path: polygon(0% 0%,80% 0%,100% 50%,80% 100%,0% 100%);
    width: 7rem;
    height: 3.5rem;
    background: #EE7D31;
    position: absolute;
    top: -7px;
    left: 12px;
}

@media (max-width: 1199.98px){ 
    
    .card-wrapper-1{
        position: relative;
        top: 29px;
       
    }
    .card-wrapper-2{
        position: relative;
        top: 29px;
        left:-32px;
    }
        
    .text-wrappers {
        width: 166px;
        text-align: left;
    
    } 
    text-wrappers p{
        padding-top: 27px!important;

    }
    .box-1{
        width: 12rem;
        height: 16.5rem;
        position: relative;
    }
    .box-2{
        width: 12rem;
        height: 16.5rem;
        position: relative;
    }

    .pro-text {
        font-size: 18px;
    }

    .con-text {
        font-size: 18px;
    }

    .arrow-box {
        position: relative;
        top: 125px;
        left: 17px;
    }

    .arrow-2 {
        width: 6rem;
        height: 3rem;
        position: absolute;
        top: 9px;
        left: -4px;
    }
     .arrow-1 {
        width: 6rem;
        height: 3rem;
        left: 30px;
        position: absolute;
        background: #599BD5;
    }
    .circle-2 {
        width: 40px;
        height: 40px;
        background: #599BD5;
        position: absolute;
        top: -26px;
        left: 89px;
    }
    .circle-1{
        width: 40px;
        height: 40px;
        background: #EE7D31;
        position: absolute;
        top: -26px;
        left:76px;
    
    }
    
}
`;