import React, { useState } from 'react';
import { Dedicated2style } from "./style"
import TextEditor from '../../../../Preview/TextEditor';
import MediaEditor from '../../../../Preview/MediaEditor';
import TemplateLogo from '../../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../../Preview/TemplateWatermark';
import white_bg from "../../../../../../images/feature_back-whitespace.png"

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Dedicated2style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 p-0">
                                <div className="background-white-space"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        width: '100%',
                                        left: ' 1px',
                                        height: '358px',
                                        backgroundImage: ` url( ${white_bg})`,

                                    }}
                                > </div>
                                <div className={`product-wrappers ${layer.image ? 'image-bg-border' : ''}`}>
                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        data={props.templateText[0].image}
                                        mediaType="pImage"
                                        type={props.templateText[0].image.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    />
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-sm-6">
                                <div className="product-intro">
                                    <div className="head-box">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].heading.is_selected}
                                            layerId={props.templateText[0].id}
                                            textId={props.templateText[0].heading.id}
                                            text={props.templateText[0].heading.text}
                                            style={props.templateText[0].heading.style}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'h1',
                                                }

                                            }
                                        />

                                    </div>
                                    <div className="text-wrappers ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            textId={props.templateText[0].text.id}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Dedicated2style >

    );

};
export default Template;