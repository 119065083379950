import React from 'react';
import ReactPlayer from "react-player";

const week3 = () => {

    const videos = [

        {
            id: 1,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video01.mp4"
        },

        {
            id: 2,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video02.mp4"
        },

        {
            id: 3,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video03.mp4"
        },

        {
            id: 4,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video04.mp4"
        },

        {
            id: 5,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video05.mp4"
        },

        {
            id: 6,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video06.mp4"
        },

        {
            id: 7,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video07.mp4"
        },

        {
            id: 8,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video08.mp4"
        },

        {
            id: 9,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video09.mp4"
        },

        {
            id: 10,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+3+Compressed/video10.mp4"
        },




    ]

    return (
        <div className="row">
            {videos.map((curElem) => {
                return (
                    <div className="col-md-6 mx-auto p-0" key={curElem.id}>
                        <div className="master-box-content me-2 me-mb-0">

                            <ReactPlayer
                                url={curElem.url}
                                loop="true"
                                muted
                                controls
                            />
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}
export default week3;