import React, { useState, useCallback } from 'react';
import axios from 'axios'
import noimg from "../../../images//no-img.jpg";
import { baseURL } from '../../../global/global'
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateSlideImage, updateSlideProductImage, updateSlideTextImage } from "../../../actions/videoAction";
import getCroppedImg from "./CropImage";
import Alert from "../../Alert";
import Cropper from "react-easy-crop";

import { setAlert } from "../../../actions/alert";


const ImageMedia = ({ selectedSlideIndex, selectedTextIndex, selectImageLayer, slideData }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)


    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'image',
        page: 1
    });

    const [imageData, setImageData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });
        setImageData([]);
        setCheckImage(false)
    }
    const closePopup = () => {
        setCropModel(false);
        setOutputImage(false);
    }

    const searchImage = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-images`,
            data: JSON.stringify({ keyword: search.keyword, search_type: search.search_type, page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                if (res.data.data) {
                    setError(false);

                    let arr = [...imageData];
                    setImageData(arr.concat(res.data.data));
                }

            } else {
                setError(res.data.message);
            }

            setSearch({ ...search, page: page });

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
    }

    const handleClick = () => {
        setError(false);
        searchImage();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchImage();
    }

    const selectImage = (image, index) => {
        setCheckImage(index);
        if (selectImageLayer) {
            if (slideData[selectedSlideIndex].text[selectedTextIndex].image && slideData[selectedSlideIndex].text.length > 0) {
                dispatch(updateSlideTextImage(image.url, selectedSlideIndex, selectedTextIndex))
            }
            else {
                dispatch(updateSlideProductImage(image.url, selectedSlideIndex))
            }
        }
        else {
            if (slideData[selectedSlideIndex].categoryID !== 10) {
                let jsonData = {
                    fileUrl: image.url,
                    poster: image.thumbnail,
                    type: 'image'
                }
                dispatch(updateSlideImage(jsonData, selectedSlideIndex))
            }
        }
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
        setOutputImage(false)
    }

    const selectCropImage = (image) => {
        if (selectImageLayer) {
            dispatch(updateSlideProductImage(image, selectedSlideIndex))
        }
        else {
            let jsonData = {
                fileUrl: image,
                poster: image,
                type: 'image'
            }
            dispatch(updateSlideImage(jsonData, selectedSlideIndex))
        }

        setCropModel(false);
        setOutputImage(false);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                rotation
            )
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];

        setCropLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}generate-file`,
            data: JSON.stringify({ data: imageData, format: ext }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setOutputImage(res.data.data);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err);
            setCropLoader(false);
        })
    }
    return (
        <>
            <Alert />
            <div className="innertab">
                <form className="tabSearch" method='POST' onSubmit={(e) => onFormSubmit(e)}>
                    <input
                        type="text"
                        placeholder="Search"
                        onInput={(e) => onInputChange(e)}
                    />
                    <span
                        className="inp-icon"
                        onClick={() => handleClick()}
                    ><i className="fa fa-search" aria-hidden="true"></i></span>
                </form>

                <div className="innertab-scroll" id="scrollableMeme">
                    <div className='row'>
                        <ul>
                            <InfiniteScroll
                                dataLength={imageData.length} //This is important field to render the next data
                                next={() => searchImage(search.page + 1)}
                                hasMore={true}
                                scrollableTarget="scrollableMeme"
                            >
                                {imageData.length > 0 ?
                                    imageData.map((curElem, index) => {
                                        return (
                                            <li className='col-md-6 myImage-section cursor-pointer' key={index}>
                                                <div className="mediaList-single myImage-respo m-1 " style={{ textAlign: 'center' }}>
                                                    <img className="mediaImg" src={curElem.thumbnail} alt={`${search.keyword + " " + index}`} onClick={() => selectImage(curElem, index)} />
                                                </div>
                                                {checkImage === index ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                                }
                                                <div className="crop-icon" onClick={() => selectCrop(curElem.url)}>
                                                    <i className="fa fa-crop"></i>
                                                </div>
                                            </li>

                                        )
                                    }) : ''}
                                {
                                    loader ?
                                        <div className="col-md-12">
                                            <h4 className='text-center'><i className="fa fa-spinner fa-spin " /></h4>
                                        </div>
                                        : ''
                                }
                                {
                                    error ?
                                        <div className="col-md-12">
                                            <h6 className='text-center'>{error}</h6>
                                        </div>
                                        : ''
                                }


                            </InfiniteScroll>
                        </ul>
                    </div>
                </div>

                <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header crop-header">
                                <h5 className="modal-title text-center">Crop Image</h5>
                                <button onClick={(e) => setCropModel(false)} type="button" className="close img-close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                                <Cropper
                                    image={currentImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={16 / 9}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    objectFit="contain"
                                />
                                {
                                    croppedAreaPixels === null ? <div className="crop-icon-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: "#F68802", fontSize: '25px' }} /></div> : ''
                                }

                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                    {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    {cropLoader ? 'Cropping' : 'Crop'}
                                </button>
                                <button
                                    className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                    type="button"
                                    disabled={outputImage === false ? true : false}
                                    onClick={() => selectCropImage(outputImage)}
                                >
                                    Use
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ImageMedia;
