import React, { useState } from 'react';
import addReelIcon from "../../../images/add-reel.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedTiles, updateSlidesData, handleDragStatus, updateVideoThumbnail, removeTtsFromSlides } from '../../.././actions/videoAction';
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from '../../Alert'
import AddSlide from './AddSlide';
import { BiMicrophoneOff } from 'react-icons/bi'
import { BiMicrophone } from 'react-icons/bi'
import { setAlert } from '../../../actions/alert';
import { OverlayTrigger, Tooltip } from "react-bootstrap";



const VideoTiles = ({ slideData }) => {

    const dispatch = useDispatch()
    const slides = useSelector(state => state.videos.data.slides)

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [dragCamp, setDragCamp] = useState(true);
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteTileDetails, setDeleteTileDetails] = useState({
        indexNumber: false,
        indexActiveStatus: false
    })

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleSweetAlert = (indexNumber, indexActiveStatus) => {
        setDeleteTileDetails({
            ...deleteTileDetails,
            indexNumber: indexNumber,
            indexActiveStatus: indexActiveStatus
        })
        setShowDelete(true);
    }
    const onConfirm = (e) => {
        handleDeleteTile();
    }

    const onCancel = () => {
        setShowDelete(false);
    }
    const onSetRecorder = (result) => {

        if (!result.destination) {
            return;
        }

        const items = reorder(
            slideData,
            result.source.index,
            result.destination.index
        );
        dispatch(updateSlidesData(items));
        dispatch(updateVideoThumbnail(items[0].poster));
        dispatch(handleDragStatus());
    }

    const onSelectTile = (index) => {
        dispatch(updateSelectedTiles(index))
    }

    const handleDeleteTile = () => {
        let data = [...slideData]
        const indexNumber = deleteTileDetails.indexNumber
        const indexActiveStatus = deleteTileDetails.indexActiveStatus
        setButton('Deleting...');

        let newIndex = indexNumber
        if (indexActiveStatus === false) {
            data.splice(indexNumber, 1)
            dispatch(updateSlidesData(data))
            setShowDelete(false)
            setButton('Delete');
        }
        else {
            if (data.length > 1) {
                if (indexNumber === 0) {
                    newIndex = indexNumber;
                } else {
                    newIndex = indexNumber - 1;
                }
                data.splice(indexNumber, 1);
                data[newIndex].is_selected = true
                dispatch(updateSlidesData(data));
                setShowDelete(false)
                setButton('Delete');

            }
            else {
                swal('Oops..', 'You cannot delete last slide');
            }
        }
    }

    const handleTts = (slideIndex) => {
        dispatch(setAlert("Voiceover removed successfully", "success"))
        dispatch(removeTtsFromSlides(slideIndex))
    }

    return (
        <>
            <Alert />
            <div className="editorReelwrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="editorReel-scroll">
                                <DragDropContext onDragEnd={onSetRecorder}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided) => (
                                            <ul
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    slideData.length > 0 ?
                                                        slideData.map((curElem, index) => {
                                                            return (
                                                                <>
                                                                    <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                                        {(provided) => (
                                                                            <li
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={index}
                                                                                className={curElem.is_selected ? 'selected-tile' : 'selected-tile-hover'}
                                                                            >
                                                                                <div

                                                                                    className="editorReel-single "
                                                                                    style={curElem.is_selected ? { border: 'none' } : {}}
                                                                                >
                                                                                    <img
                                                                                        onClick={() => onSelectTile(index)}
                                                                                        src={curElem.backgroundData.poster}
                                                                                        alt='tiles-poster'
                                                                                        style={{ width: '100%', height: '100%', borderRadius: '9px', cursor: 'pointer' }}
                                                                                    />
                                                                                    <FiTrash2
                                                                                        className="delIcon"
                                                                                        color='#fff'
                                                                                        style={{ cursor: 'pointer', color: `${curElem.is_selected ? '#ffa200' : ''}` }}
                                                                                        onClick={() => {
                                                                                            slides.length === 1 ? handleDeleteTile() : handleSweetAlert(index, curElem.is_selected)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {curElem.audio.enable ?
                                                                                        <OverlayTrigger

                                                                                            placement={'top'}
                                                                                            overlay={
                                                                                                <Tooltip id="tooltip-top" className='tooltipsarrow' >
                                                                                                    TTS Active
                                                                                                </Tooltip>
                                                                                            }

                                                                                        >
                                                                                            <div>
                                                                                                <BiMicrophone
                                                                                                    className="voiceIcn"
                                                                                                    style={{ cursor: 'pointer', color: `${curElem.is_selected ? '#ffa200' : ''}` }}
                                                                                                    onClick={() => handleTts(index)}
                                                                                                />
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                        :
                                                                                        <OverlayTrigger
                                                                                            placement={'top'}
                                                                                            overlay={
                                                                                                <Tooltip id="tooltip-top" className='tooltipsarrow'>
                                                                                                    TTS Inactive
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <div>
                                                                                                <BiMicrophoneOff
                                                                                                    className="voiceIcn-off"
                                                                                                    style={{ cursor: 'pointer', color: `${curElem.is_selected ? '#ffa200' : ''}` }}
                                                                                                />
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    }
                                                                                </div>
                                                                            </li>
                                                                        )}
                                                                    </Draggable>
                                                                    <div className="addReelBt" onClick={handleShow}><img src={addReelIcon} alt="" /></div>
                                                                </>
                                                            )
                                                        }) : ''
                                                }
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>


                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText={button}
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    title="Are you sure?"
                                    onConfirm={(e) => onConfirm(e)}
                                    onCancel={(e) => onCancel(e)}
                                    focusCancelBtn
                                    show={showDelete}
                                >
                                    Do you want to delete this Tile
                                </SweetAlert>

                                <AddSlide
                                    handleClose={handleClose}
                                    show={show}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoTiles;
