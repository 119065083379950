import styled from "styled-components";
export const Dedicated6style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  

.product-wrappers {
  height: 156px;
  position: absolute;
  top: 116px;
  left: 69px;
  z-index: 1;
}
.product-wrappers-2 {
    height: 130px;
    position: absolute;
    top: 116px;
    left: 57px;
    z-index: 1;
}

 .product-wrappers img{
   max-height:100%
 }
 .shape-1{
    position: absolute;
    top: 0;
    width: 115px;
    height: 80px;
    background:#17255A;
    left: 40%;
}
 .box-1{
    position: absolute;
    top: 15px;
    width: 35px;
    height: 60px;
    background: #BF5E2B;
    -webkit-transform: rotate(241deg);
    -ms-transform: rotate(241deg);
    transform: rotate(241deg);
    left: -12%;
}
 .shape-2{
    position: absolute;
    bottom: 7px;
    width: 63px;
    height: 60px;
    background:#17255A;

    left: 0;
}
 .box-2{
    position: absolute;
    bottom: 40px;
    width: 24px;
    height: 36px;
    background: #BF5E2B;
    transform: rotate(308deg);
    -ms-transform: rotate(241deg);
    left: 44px;
}
 .shape-3{
    position: absolute;
    bottom: 0;
    width: 119px;
    height: 40px;
    background:#17255A;
    left: 40%;
}
 .box-3{
    position: absolute;
    bottom: 25px;
    width: 28px;
    right: -11px;
    height: 33px;
    background: #BF5E2B;
    -webkit-transform: rotate(241deg);
    -ms-transform: rotate(241deg);
    transform: rotate(307deg);
}
 .shape-4{
    position: absolute;
    top: 40%;
    width: 32px;
    height: 56px;
    background:#17255A;
    right: 0;
}
 .box-4{
    position: absolute;
    top: 73%;
    width: 34px;
    height: 18px;
    background: #BF5E2B;
    -webkit-transform: rotate(241deg);
    -ms-transform: rotate(241deg);
    transform: rotate(228deg);
    right: 15px;
}

.head-box {
    width: 100%;
    position: absolute;
    top: 118px;
    left: 20px; 
 }

.head-box h1 {
    font-size: 30px;
}


.text-wrappers {
    width: 100%;
    position: absolute;
    top: 178px;
    left: 20px;
}

.video-player{
  width:180px!important;
  max-height:100%;
}



@media (max-width: 1199.98px) { 
  
    .text-wrappers {
      top: 135px;
    }
    .product-wrappers {
        height: 190px;
    }
     .head-box {
        width: 100%;
        position: absolute;
        top: 78px;
        left: 20px;
    }

  .product-wrappers {
    height: 135px;
    position: absolute;
    top: 61px;
    left: 53px;
    z-index: 1;
  }

  .product-wrappers-2 {
    height: 114px;
    position: absolute;
    top: 86px;
    left: 53px;
    z-index: 1;
  }
    .shape-1 {
        position: absolute;
        top: 0;
        width: 100px;
        height: 57px;
        background: #17255A;
        left: 40%;
    }
     .box-1 {
        position: absolute;
        top: 1px;
        width: 30px;
        height: 55px;
    }
    .shape-2 {
        position: absolute;
        bottom: 4px;
        width: 47px;
        height: 49px;
        background: #17255A;
        left: 0;
    }
    .box-2 {
        position: absolute;
        bottom: 32px;
        width: 21px;
        height: 36px;
        background: #BF5E2B;
        left: 31px;
    }
   .shape-3 {
        height: 34px;
    }
    .box-3 {
        position: absolute;
        bottom: 19px;
        width: 22px;
        right: -8px;
        height: 33px;
    }
    .shape-4 {
        width: 27px;
        
    }
    .box-4 {
        position: absolute;
        top: 80%;
        width: 34px;
        height: 18px;
        right: 10px;
    }
}


`;