import React from "react";
import imac from "../../images/IMAC.png"
import mac from "../../images/MAC.png"
import mac2 from "../../images/MAC2.png"
import mac3 from "../../images/MAC3.png"

const DashboardAbout = () => {
    return(
        <>
            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Write a new video script in a few clicks</h6>
                                <h4 className="mt-3">Instantly generate a full-blown video script in seconds</h4>
                                <p className="mt-4">ReviewReel does all the grunt work for you. It instantly gives you in-depth and engaging cripts that you can readily use for product review videos in any niche. Say goodbye to hours of brainstorming, drafting your script, and copyediting. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5">
                                <img src={imac} alt="mac" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={mac} alt="mac" />
                            </div>
                        </div>


                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Sit back and let the A.I. do all the work for you</h6>
                                <h4 className="mt-3">Crank out 100s of product review videos instantly</h4>
                                <p className="mt-4">No more need to spend hours creating a single product review video. With ReviewReel’s powerful A.I., all you need to do is answer a few questions and it automatically gives you a ready-to-upload, click-magnet video review.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Make video reviews that speak your brand</h6>
                                <h4 className="mt-3">Fully customize and edit your videos easily</h4>
                                <p className="mt-4">Turn your videos into marketing machines that boost brand retention. ReviewReel makes it easy for you to create video reviews that work by adding your custom color, logos, images, text, watermark, call to action, and much more.  </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5">
                                <img src={mac2} alt="mac" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={mac3} alt="" />
                            </div>
                        </div>


                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                <h6>Make videos that stand out</h6>
                                <h4 className="mt-3 pr-lg-5">Access a Rich Library of Images, Fonts, Music And Videos </h4>
                                <p className="mt-4">Choose from millions of images and videos, background music and sleek fonts to effortlessly make all your videos professional-looking. No need to pay anything extra to get this robust library of assets you can add to your video reviews.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;