import React from "react";
import SocialNetwork from "./SocialNetwork";

import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/youtube-active.png";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import sonorityIcon from "../../images/logo-sonority.png";

import facebookDisable from "../../images/FB_DISABLE.png";
import youtubeDisable from "../../images/YT_DISABLE.png";
import syvidDisable from "../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../images/SCRIPT_DISABLE.png";
import sonorityDisable from "../../images/SONORITY_DISABLE.png";
import trimDisable from "../../images/TRIM_DISABLE.png";
import videoIcon from "../../images/videoIcon.png";
import videoDisable from "../../images/videoDisable.png";
import { useSelector } from "react-redux";


const IntegrationHead = () => {
    const logoActive = useSelector(state => state.social)
    return (
        <>
            <section className="integration-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="integration-head text-white text-center">
                                <h1>Integrations</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mx-auto">
                            <div className="integration-box">
                                <ul className="list-inline d-flex  flex-wrap justify-content-center">
                                    <SocialNetwork
                                        icon={logoActive.facebook.length > 0 ? facebookIcon : facebookDisable}
                                        status={logoActive.facebook.length > 0 ? true : false}
                                        name="Facebook"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.youtube.length > 0 ? youtubeIcon : youtubeDisable}
                                        status={logoActive.youtube.length > 0 ? true : false}
                                        name="Youtube"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.syvid.length > 0 ? syvidIcon : syvidDisable}
                                        status={logoActive.syvid.length > 0 ? true : false}
                                        name="Syvid"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.scriptreel.length > 0 ? scriptIcon : scriptDisable}
                                        status={logoActive.scriptreel.length > 0 ? true : false}
                                        name="ScriptReel"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.trimreel.length > 0 ? trimIcon : trimDisable}
                                        status={logoActive.trimreel.length > 0 ? true : false}
                                        name="TrimReel"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.livereel.length > 0 ? liveIcon : liveDisable}
                                        status={logoActive.livereel.length > 0 ? true : false}
                                        name="LiveReel"
                                    />
                                    <SocialNetwork
                                        icon={logoActive.sonority.length > 0 ? sonorityIcon : sonorityDisable}
                                        status={logoActive.sonority.length > 0 ? true : false}
                                        name="Sonority"
                                    />

                                    <SocialNetwork
                                        icon={logoActive.videoreel.length > 0 ? videoIcon : videoDisable}
                                        status={logoActive.videoreel.length > 0 ? true : false}
                                        name="VideoReel"
                                    />

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationHead;