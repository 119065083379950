import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { updateSlideImage, updateSlideVideo } from '../../../actions/videoAction';
const TemplateAsset = ({ slideData, selectedSlideIndex }) => {

    const dispatch = useDispatch()
    const asset = useSelector(state => state.tempVar.varient)

    const [tempNumber, setTempNumber] = useState(1)

    useEffect(() => {
        if (slideData.length > 0 && selectedSlideIndex !== -1) {
            setTempNumber(slideData[selectedSlideIndex].templateNumber)
        }
    }, [selectedSlideIndex, slideData])

    const [urlData, setUrlData] = useState({
        fileUrl: false,
        poster: false,
        type: 'image'
    })
    const [loader, setLoader] = useState(true)

    const updateVarient = (varient) => {

        const data = varient.filter((curElem) => {
            if (curElem.template_number === tempNumber) {
                return curElem
            }
        })
        if (data.length > 0) {
            setUrlData({
                ...urlData,
                fileUrl: data[0].preview_bgassets_url,
                poster: data[0].preview_image_url,
                type: data[0].preview_bgassets_url.includes(".mp4") ? "video" : "image"
            })
        }
    }
    useEffect(() => {
        if (asset) {
            setLoader(true)
            updateVarient(asset)
        }
        return () => {
            setLoader(false)
        }
    }, [asset, tempNumber])

    const handleStartVideo = () => {
        setLoader(false)
    }



    const handleAddImage = (imageUrl, thumb) => {

        let jsonData = {
            fileUrl: imageUrl,
            poster: thumb,
            type: 'image'
        }
        dispatch(updateSlideImage(jsonData, selectedSlideIndex))
    }

    const handleVarient = (videoUrl, thumb) => {

        let jsonData = {
            fileUrl: videoUrl,
            poster: thumb,
            type: 'video'
        }
        dispatch(updateSlideVideo(jsonData, selectedSlideIndex))
    }


    return (
        <div className="innertab">
            <div className="innertab-scroll">
                {slideData[selectedSlideIndex].categoryID != 10 ?
                    <div className="mediaList">
                        <ul>
                            <li>
                                <div className="mediaList-single cursor-pointer">
                                    {
                                        urlData.type === "video" ?
                                            <>
                                                {loader ?
                                                    <div className="loader-center-temp1">
                                                        <i className="fa fa-spinner fa-spin mr-2" />
                                                    </div>
                                                    : ''}
                                                < ReactPlayer
                                                    url={urlData.fileUrl ? urlData.fileUrl : ''}
                                                    playing={true}
                                                    loop={true}
                                                    onClick={() => handleVarient(urlData.fileUrl, urlData.poster)}
                                                    className="video-player-css "
                                                    width={'100%'}
                                                    onStart={() => handleStartVideo()}
                                                    style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}
                                                />
                                            </>
                                            :
                                            <>
                                                {loader ?
                                                    <div className="loader-center-temp1">
                                                        <i className="fa fa-spinner fa-spin mr-2" />
                                                    </div>
                                                    : ''}
                                                <img
                                                    className="mediaImg"
                                                    src={urlData.fileUrl ? urlData.fileUrl : ''}
                                                    onClick={() => handleAddImage(urlData.fileUrl, urlData.poster)}
                                                    onLoad={() => setLoader(false)}
                                                    alt=""
                                                    style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}

                                                />
                                            </>

                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                    : ''}
            </div>
        </div >
    );
}

export default TemplateAsset;
