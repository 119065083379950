import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SketchPicker } from 'react-color'
import { baseURL } from "../../global/global";
import { useHistory } from "react-router-dom";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { RiErrorWarningFill } from "react-icons/ri";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Title from "../Title";
import Alert from "../Alert";
import axios from "axios";
import image1 from "../../images/introduction-template.svg"
import image2 from "../../images/pros-and-cons.svg"
import image3 from "../../images/faeature-dedicated-slide.svg"
import image4 from "../../images/testimonial-and-reviews.svg"
import image5 from "../../images/bonuses.svg"
import image6 from "../../images/demo-video.svg"
import image7 from "../../images/conclusion.svg"
import image8 from "../../images/product-description.svg"
import './createVideo.css'


const CeeateVideo = () => {


    const auth = useSelector(state => state.auth)
    const history = useHistory()
    const dispatch = useDispatch()

    const [percent, setPercent] = useState(0);
    const [demoDuration, setDemoDuration] = useState({
        demoUrl: '',
        duration: -1,
        limitMessage: ''
    })
    const [loader, setLoader] = useState({
        product: {
            status: false,
            productImageName: 'Upload an Image',
        },
        feature: [{
            status: false,
            featureImageName: 'Upload an Image',
        }],
        testimonial: [{
            status: false,
            testimonialImageName: 'Upload an Image',

        }],
        bonus: [{
            status: false,
            bonusImageName: 'Upload an Image',

        }],
        demo: [{
            status: false,
            demoImageName: 'Upload a Video',
        }],
        submit: false,
    });

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];

    const [colorPicker, setColorPicker] = useState({
        togglePrimary: false,
        toggleSecondary: false,
        toggleText: false
    })
    const [reviewData, setReviewData] = useState({
        campaingName: '',
        productName: '',
        productType: '',
        productDescription: '',
        productSubDescription: '',
        productImage: '',
        productTask: '',
        productResult: '',
        prosAndCons: {
            enable: false,
            pros: [{
                id: 0,
                prosText: ''
            }],
            cons: [{
                id: 0,
                consText: ''
            }]
        },
        features: {
            enable: false,
            feature:
                [{
                    id: 0,
                    heading: '',
                    image: '',
                    details: ''
                }]
        },

        testimonials: {
            enable: false,
            testimonial:
                [{
                    id: 0,
                    name: '',
                    designation: '',
                    image: '',
                    message: '',
                }]
        },
        bonuses: {
            enable: false,
            bonus:
                [{
                    id: 0,
                    name: '',
                    value: '',
                    description: '',
                    image: ''
                }]
        },
        demo: {
            enable: false,
            demo: [{
                id: 0,
                src: '',
                type: 'file',
                url: ''
            }]
        },
        color: {
            primary: '#f68802',
            secondary: '#e7a766',
            textColor: '#dddcdb'
        }
    }
    )



    const updateResponse = (path, type, index, demoThumb) => {

        if (type === 'productImage') {

            setReviewData({
                ...reviewData,
                productImage: path
            })

        }
        else if (type === 'featureImage') {

            let data = [...reviewData.features.feature]
            data[index].image = path

            setReviewData({
                ...reviewData,
                features: {
                    ...reviewData.features,
                    feature: data
                }
            })
        }
        else if (type === 'testimonialImage') {

            let data = [...reviewData.testimonials.testimonial]
            data[index].image = path

            setReviewData({
                ...reviewData,
                testimonials: {
                    ...reviewData.testimonials,
                    testimonial: data
                }
            })

        }
        else if (type === 'bonusImage') {

            let data = [...reviewData.bonuses.bonus]
            data[index].image = path

            setReviewData({
                ...reviewData,
                bonuses: {
                    ...reviewData.bonuses,
                    bonus: data
                }
            })

        }
        else if (type === 'demoUrl') {
            let data = [...reviewData.demo.demo]
            data[index].src = path
            data[index].url = demoThumb
            setReviewData({
                ...reviewData,
                demo: {
                    ...reviewData.demo,
                    demo: data
                }
            })
        }
    }

    const handleLoader = (status, type, index, name, demoThumb) => {
        if (name && name !== 'error' && name.length > 10) {
            name = name.slice(0, 9)
            name = name + '...'
        }

        if (type === 'productImage') {
            setLoader({
                ...loader,
                product: {
                    ...loader.product,
                    status: status,
                }
            });
            if (status === false && name !== 'error') {
                setLoader({
                    ...loader,
                    product: {
                        ...loader.product,
                        productImageName: name
                    }
                })
            }
        }
        else if (type === 'featureImage') {

            let val = [...loader.feature]
            val[index].status = status
            if (status === false && name !== 'error') {
                val[index].featureImageName = name
            }

            setLoader({
                ...loader,
                feature: val
            })
        }
        else if (type === 'testimonialImage') {
            let val = [...loader.testimonial]
            val[index].status = status
            if (status === false && name !== 'error') {
                val[index].testimonialImageName = name
            }

            setLoader({
                ...loader,
                testimonial: val
            })
        }
        else if (type === 'bonusImage') {

            let val = [...loader.bonus]
            val[index].status = status
            if (status === false && name !== 'error') {
                val[index].bonusImageName = name
            }
            setLoader({
                ...loader,
                bonus: val
            })
        }
        else if (type === 'demoUrl') {
            let val = [...loader.demo]
            val[index].status = status
            if (status === false && name !== 'error') {
                val[index].demoImageName = name
            }
            setLoader({
                ...loader,
                demo: val
            })
        }
    }

    const onInputVideoFile = (e, index) => {
        const { name } = e.target
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let num = Math.floor((loaded * 100) / total);
                setPercent(num);
            },
            headers: {
                'Authorization': auth.token
            }
        }
        let allowedSize = 200000000;
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].type === "video/mp4") {
                if (e.target.files[0].size < allowedSize) {
                    handleLoader(true, name, index)
                    const formData = new FormData();
                    formData.append('type', 'video');
                    formData.append('file', e.target.files[0])


                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                updateResponse(res.data.path, name, index, res.data.thumbnial)
                                handleLoader(false, name, index, res.data.name, res.data.thumbnial)

                            }
                            setPercent(0);
                        }).catch((error) => {
                            handleLoader(false, name, index, 'error')
                            console.log(error)
                        })

                }
                else {
                    swal("Oops!", "Max allowed size for video is 200MB");
                }
            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const onInputFile = (e, type, index) => {
        const { name } = e.target
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let num = Math.floor((loaded * 100) / total);
                setPercent(num);
            },
            headers: {
                'Authorization': auth.token
            }
        }

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {
                let allowedSize = 5000000;
                if (e.target.files[0].size < allowedSize) {

                    const formData = new FormData();
                    formData.append('type', type);
                    formData.append('file', e.target.files[0])

                    handleLoader(true, name, index)

                    axios.post(`${baseURL}upload-user-media`, formData, options)
                        .then(res => {
                            if (res.data.status === true) {
                                updateResponse(res.data.path, name, index)
                                handleLoader(false, name, index, res.data.name)
                            }
                            setPercent(0)
                        })
                        .catch((error) => {
                            handleLoader(false, name, index, 'error')
                            console.log(error)
                        })

                }
                else {
                    swal("Oops!", "Max allowed size for image 5MB");
                }
            }
            else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    // all toggle
    const handleToggle = (e) => {
        const { name, checked } = e.target

        if (name === 'ProsAndCons') {
            setReviewData({
                ...reviewData,
                prosAndCons: {
                    ...reviewData.prosAndCons,
                    enable: checked
                }
            })
        }
        else if (name === 'Features') {
            setReviewData({
                ...reviewData,
                features: {
                    ...reviewData.features,
                    enable: checked
                }
            })
        }
        else if (name === 'Testimonial') {
            setReviewData({
                ...reviewData,
                testimonials: {
                    ...reviewData.testimonials,
                    enable: checked
                }
            })
        }
        else if (name === 'Bonus') {
            setReviewData({
                ...reviewData,
                bonuses: {
                    ...reviewData.bonuses,
                    enable: checked
                }
            })
        }
        else if (name === 'Demo') {
            setReviewData({
                ...reviewData,
                demo: {
                    ...reviewData.demo,
                    enable: checked
                }
            })
        }
    }

    //adding new index in object array with object data

    const handleAdd = (type) => {
        let prosId = 0
        let consId = 0
        let featureId = 0
        let testimonialId = 0
        let bonusId = 0
        let demosId = 0

        if (reviewData.prosAndCons.pros.length > 0) {
            prosId = reviewData.prosAndCons.pros[reviewData.prosAndCons.pros.length - 1].id
            prosId += 1
        }
        if (reviewData.prosAndCons.cons.length > 0) {
            consId = reviewData.prosAndCons.cons[reviewData.prosAndCons.cons.length - 1].id
            consId += 1
        }
        if (reviewData.features.feature.length > 0) {
            featureId = reviewData.features.feature[reviewData.features.feature.length - 1].id
            featureId += 1

        }
        if (reviewData.testimonials.testimonial.length > 0) {
            testimonialId = reviewData.testimonials.testimonial[reviewData.testimonials.testimonial.length - 1].id
            testimonialId += 1
        }
        if (reviewData.bonuses.bonus.length > 0) {
            bonusId = reviewData.bonuses.bonus[reviewData.bonuses.bonus.length - 1].id
            bonusId += 1
        }
        if (reviewData.demo.demo.length > 0) {
            demosId = reviewData.demo.demo[reviewData.demo.demo.length - 1].id
            demosId += 1
        }
        if (type === 'PROS') {
            if (reviewData.prosAndCons.pros.length >= 9) {
                dispatch(setAlert("You can add only 9 Pros maximum", 'warning'))
            }
            else {
                setReviewData({
                    ...reviewData,
                    prosAndCons: {
                        ...reviewData.prosAndCons,
                        pros: [...reviewData.prosAndCons.pros, { id: prosId, prosText: '' }]
                    }
                })
            }
        }
        else if (type === 'CONS') {
            if (reviewData.prosAndCons.cons.length >= 9) {
                dispatch(setAlert("You can add only 9 Cons maximum", 'warning'))
            }
            else {
                setReviewData({
                    ...reviewData,
                    prosAndCons: {
                        ...reviewData.prosAndCons,
                        cons: [...reviewData.prosAndCons.cons, { id: consId, consText: '' }]
                    }
                })
            }
        }
        else if (type === 'FEATURES') {

            if (reviewData.features.feature.length >= 8) {
                dispatch(setAlert("You can add only 8 Features maximum", 'warning'))
            }
            else {
                setLoader({
                    ...loader,
                    feature: [...loader.feature, { status: false, featureImageName: 'Upload an Image' }]
                })
                setReviewData({
                    ...reviewData,
                    features: {
                        ...reviewData.features,
                        feature: [...reviewData.features.feature, { id: featureId, heading: '', image: '', details: '' }]
                    }
                })
            }

        }
        else if (type === 'TESTIMONIAL') {
            if (reviewData.testimonials.testimonial.length >= 8) {
                dispatch(setAlert("You can add only 8 Testimonials maximum", 'warning'))
            }
            else {
                setLoader({
                    ...loader,
                    testimonial: [...loader.testimonial, { status: false, testimonialImageName: 'Upload an Image' }]
                })
                setReviewData({
                    ...reviewData,
                    testimonials: {
                        ...reviewData.testimonials,
                        testimonial: [...reviewData.testimonials.testimonial, { id: testimonialId, name: '', designation: '', image: '', message: '', }]
                    }
                })
            }
        }
        else if (type === 'BONUS') {
            if (reviewData.bonuses.bonus.length >= 8) {
                dispatch(setAlert("You can add only 8 Bonuses maximum", 'warning'))
            }
            else {
                setLoader({
                    ...loader,
                    bonus: [...loader.bonus, { status: false, bonusImageName: 'Upload an Image' }]
                })
                setReviewData({
                    ...reviewData,
                    bonuses: {
                        ...reviewData.bonuses,
                        bonus: [...reviewData.bonuses.bonus, { id: bonusId, name: '', value: '', description: '', image: '' }]
                    }
                })
            }
        }
        else if (type === 'DEMO') {
            setLoader({
                ...loader,
                demo: [...loader.demo, { status: false, demoImageName: 'Upload Video' }]
            })
            setReviewData({
                ...reviewData,
                demo: {
                    ...reviewData.demo,
                    demo: [...reviewData.demo.demo, { id: demosId, src: '', type: 'file', url: '' }]
                }
            })
        }
    }

    // onChnage of input fields

    const handleChange = (e) => {
        const { name, value } = e.target
        setReviewData({
            ...reviewData, [name]: value
        })
    }

    // onChnage of pros and cons input fields

    const handleChangeProsAndCons = (e, index) => {
        const { name, value } = e.target
        if (name === 'prosText') {
            let data = [...reviewData.prosAndCons.pros]
            data[index][name] = value
            setReviewData({
                ...reviewData,
                prosAndCons: {
                    ...reviewData.prosAndCons,
                    pros: data
                }
            })

        }
        else if (name === 'consText') {
            let data = [...reviewData.prosAndCons.cons]
            data[index][name] = value
            setReviewData({
                ...reviewData,
                prosAndCons: {
                    ...reviewData.prosAndCons,
                    cons: data
                }
            })
        }
    }
    // onChnage of feature input fields

    const handleChangeFeature = (e, index) => {
        const { name, value } = e.target
        let data = [...reviewData.features.feature]
        data[index][name] = value
        setReviewData({
            ...reviewData,
            features: {
                ...reviewData.features,
                feature: data
            }
        })

    }
    // onChnage of testomonial input fields

    const handleChangeTestimonial = (e, index) => {
        const { name, value } = e.target
        let data = [...reviewData.testimonials.testimonial]
        data[index][name] = value
        setReviewData({
            ...reviewData,
            testimonials: {
                ...reviewData.testimonials,
                testimonial: data
            }
        })
    }

    // onChnage of bonus input fields

    const handleChangeBonus = (e, index) => {
        const { name, value } = e.target
        let val = value
        if (name === "value") {
            val = value.match('[$₹£]?[0-9]*')
            val = val[0]
        }
        let data = [...reviewData.bonuses.bonus]
        data[index][name] = val

        setReviewData({
            ...reviewData,
            bonuses: {
                ...reviewData.bonuses,
                bonus: data
            }
        })
    }

    const handleChangeDemo = (e, index) => {
        const { name, value } = e.target

        let data = [...reviewData.demo.demo]
        data[index][name] = value
        if (reviewData.demo.demo[index].type === "customUrl" && name === "src") {
            setDemoDuration({
                ...demoDuration,
                demoUrl: value,
            })
        }
        if (e.target.name === 'type') {
            data[index].src = ''
        }
        setReviewData({
            ...reviewData,
            demo: {
                ...reviewData.demo,
                demo: data
            }
        })
    }

    useEffect(() => {
        if (demoDuration.duration > 300) {
            setDemoDuration({
                ...demoDuration,
                limitMessage: 'Your demo video URL duration is more than 5 minutes, please use a maximum of 5 minutes video'
            })
        }
    }, [demoDuration.duration])

    if (demoDuration.limitMessage !== '') {
        setTimeout(() => {
            setDemoDuration({
                ...demoDuration,
                limitMessage: '',
                demoUrl : '',
                duration : -1
            })
            let data = [...reviewData.demo.demo]
            data[reviewData.demo.demo.length - 1].src = ''
            setReviewData({
                ...reviewData,
                demo: {
                    ...reviewData.demo,
                    demo: data
                }
            })
        }, 5000)
    }

    useEffect(() => {
        let au = document.createElement('video');
        au.src = demoDuration.demoUrl;
        au.addEventListener('loadedmetadata', function () {
            setDemoDuration({
                ...demoDuration,
                duration: Number((au.duration).toFixed(1))
            });
        }, false);

    }, [reviewData.demo.demo])

    // onChnage of color input fields

    const handleColorPicker = (e, type) => {
        let rgbaColor = e.hex

        setReviewData({
            ...reviewData,
            color: {
                ...reviewData.color,
                [type]: rgbaColor
            }
        })
    }
    const handleChangeColor = (e) => {
        const { name, value } = e.target
        setReviewData({
            ...reviewData,
            color: {
                ...reviewData.color,
                [name]: value
            }
        })
    }


    const handleDelete = (index, type) => {
        if (type === 'PROS') {
            let data = [...reviewData.prosAndCons.pros]
            data.splice(index, 1)
            setReviewData({
                ...reviewData,
                prosAndCons: {
                    ...reviewData.prosAndCons,
                    pros: data
                }
            })
        }
        else if (type === 'CONS') {
            let data = [...reviewData.prosAndCons.cons]
            data.splice(index, 1)
            setReviewData({
                ...reviewData,
                prosAndCons: {
                    ...reviewData.prosAndCons,
                    cons: data
                }
            })
        }

        else if (type === 'FEATURE') {
            let data = [...reviewData.features.feature]
            data.splice(index, 1)

            let val = [...loader.feature]
            val.splice(index, 1)

            setLoader({
                ...loader,
                feature: val
            })
            setReviewData({
                ...reviewData,
                features: {
                    ...reviewData.features,
                    feature: data
                }
            })
        }
        else if (type === 'TESTIMONIAL') {
            let data = [...reviewData.testimonials.testimonial]
            data.splice(index, 1)

            let val = [...loader.testimonial]
            val.splice(index, 1)

            setLoader({
                ...loader,
                testimonial: val
            })

            setReviewData({
                ...reviewData,
                testimonials: {
                    ...reviewData.testimonials,
                    testimonial: data
                }
            })
        }
        else if (type === 'BONUS') {
            let data = [...reviewData.bonuses.bonus]
            data.splice(index, 1)

            let val = [...loader.bonus]
            val.splice(index, 1)

            setLoader({
                ...loader,
                bonus: val
            })

            setReviewData({
                ...reviewData,
                bonuses: {
                    ...reviewData.bonuses,
                    bonus: data
                }
            })
        }
        else if (type === 'DEMO') {
            let data = [...reviewData.demo.demo]
            data.splice(index, 1)

            let val = [...loader.demo]
            val.splice(index, 1)

            setLoader({
                ...loader,
                demo: val
            })

            setReviewData({
                ...reviewData,
                demo: {
                    ...reviewData.demo,
                    demo: data
                }
            })
        }
    }

    // submiting data
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submit: true
        })
        await axios({
            method: 'POST',
            url: `${baseURL}project-create`,
            data: reviewData,
            headers: { 'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem('state')).auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setLoader({
                    ...loader,
                    submit: false
                })
                history.push(`/editor?id=${res.data.data.campaignId}`)
            }
            else {
                setLoader({
                    ...loader,
                    submit: false
                })
                dispatch(setAlert(res.data.message, 'danger'))
            }
        }).catch(error => {
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(error.message, 'danger'))
            console.log(error)
        })
    }



    return (
        <>
            <Alert />
            <Title title="Create Video" />
            <Navbar />

            <section className="pageOuter">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="pageHeading">
                                    <h4 className="text-white">CREATE VIDEO</h4>
                                </div>
                                <div className="form-box mt-5">
                                    <h6 className="lineTxt"><img src={image8} alt="" /> Campaign Name</h6>
                                    <div className="form-group m-0">
                                        <small className="form-text text-muted">Give a Campaign Name</small>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campaign Name"
                                            name="campaingName"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image1} alt="" /> Introduction</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top" >
                                                        Name of the product you want to make a review video on.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Product Name</small>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product Name"
                                                        name="productName"
                                                        onChange={handleChange}
                                                        maxLength={20}
                                                    />
                                                    <div className="counter">{reviewData.productName.length}/20</div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        A generic category of the product you want to review.
                                                        (Example: application, website builder, autoresponder, etc.)
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Product Type</small>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product Type"
                                                        name="productType"
                                                        onChange={handleChange}
                                                        maxLength={60}
                                                    />
                                                    <div className="counter">{reviewData.productType.length}/60</div>
                                                </div>

                                            </OverlayTrigger>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        The definition of the product and what it does.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted"> Brief Description</small>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Brief Description"
                                                        name="productSubDescription"
                                                        onChange={handleChange}
                                                        required
                                                        maxLength={90}
                                                    />
                                                    <div className="counter">{reviewData.productSubDescription.length}/90</div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        The definition of the product and what it does.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Detail Description</small>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Detail Description"
                                                        name="productDescription"
                                                        onChange={handleChange}
                                                        required
                                                        maxLength={300}
                                                    />
                                                    <div className="counter">{reviewData.productDescription.length}/300</div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="col-md-12">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        Image of the product you are reviewing
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Product Image</small>
                                                    <div className="imgUp">
                                                        <input
                                                            required
                                                            type="file"
                                                            name="productImage"
                                                            onChange={(e) => onInputFile(e, 'product')}

                                                        />
                                                        {
                                                            loader.product.status ?
                                                                <>
                                                                    <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                    < i className="fa fa-spinner fa-spin resize-loader" />
                                                                </>
                                                                :
                                                                <div className={reviewData.productImage !== '' ? 'color-image-name' : ''}>
                                                                    <FaCloudUploadAlt />
                                                                    {loader.product.productImageName}
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="img-box img-box2 img-fluid">
                                                        {reviewData.productImage !== '' ?
                                                            <img src={reviewData.productImage} alt="" className="create-video-image" /> : ''}
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        The function of the product.
                                                        (Example: create websites, write sales copies, build websites, design graphics, etc.)
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Function of Product</small>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Function of Product"
                                                        name="productTask"
                                                        onChange={handleChange}
                                                        maxLength={120}
                                                    />
                                                    <div className="counter">{reviewData.productTask.length}/120</div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="col-md-6">
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        The goal of the product.
                                                        (Example: more conversion, higher open rates, stronger online presence, etc.)
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Goal of Product</small>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Goal of Product"
                                                        name="productResult"
                                                        onChange={handleChange}
                                                        maxLength={120}
                                                    />
                                                    <div className="counter">{reviewData.productResult.length}/120</div>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image2} alt="" /> Pros and Cons</h6>
                                        <div className="switch-single abs">
                                            <label className="switch">
                                                <input
                                                    name="ProsAndCons"
                                                    type="checkbox"
                                                    onChange={(e) => handleToggle(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                    {reviewData.prosAndCons.enable ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="singleTitle">
                                                    <span>Pros</span>
                                                    <FiPlusCircle onClick={() => handleAdd('PROS')} style={{ cursor: 'pointer' }} />
                                                </div>
                                                {reviewData.prosAndCons.pros.map((curElem, index) => {
                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Benefits of the product and what it does well.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="form-group add" >
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="prosText"
                                                                    value={curElem.prosText}
                                                                    onChange={(e) => handleChangeProsAndCons(e, index)}
                                                                    maxLength={50}
                                                                />
                                                                <div className="counter">{curElem.prosText.length}/50</div>
                                                                <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'PROS')} />
                                                            </div>
                                                        </OverlayTrigger>
                                                    )

                                                })}

                                            </div>
                                            <div className="col-md-6">
                                                <div className="singleTitle">
                                                    <span>Cons</span>
                                                    <FiPlusCircle onClick={() => handleAdd('CONS')} style={{ cursor: 'pointer' }} />
                                                </div>
                                                {reviewData.prosAndCons.cons.map((curElem, index) => {
                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Disadvantages of the product and what it can improve on.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="form-group add" >
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="consText"
                                                                    value={curElem.consText}
                                                                    onChange={(e) => handleChangeProsAndCons(e, index)}
                                                                    maxLength={50}
                                                                />
                                                                <div className="counter">{curElem.consText.length}/50</div>
                                                                <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'CONS')} />
                                                            </div>
                                                        </OverlayTrigger>
                                                    )

                                                })}
                                            </div>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image3} alt="" /> Features </h6>
                                        <div className="switch-single abs">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="Features"
                                                    onChange={(e) => handleToggle(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {reviewData.features.enable ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="singleTitle mb-3">
                                                        <span>Add details about features</span>
                                                        <FiPlusCircle onClick={() => handleAdd('FEATURES')} style={{ cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            {reviewData.features.feature.map((curElem, index) => {
                                                return (
                                                    <div className="groupDetails" key={index}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Name of the product feature you want to highlight.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Heading</small>
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder="Heading"
                                                                            name="heading"
                                                                            value={curElem.heading}
                                                                            onChange={(e) => handleChangeFeature(e, index)}
                                                                            required
                                                                            maxLength={50}

                                                                        />
                                                                        <div className="counter">{curElem.heading.length}/50</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Image of the product you are reviewing
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Image</small>
                                                                        <div className="imgUp">
                                                                            <input
                                                                                type="file"
                                                                                name="featureImage"
                                                                                onChange={(e) => onInputFile(e, 'feature', index)}
                                                                            />

                                                                            {
                                                                                loader.feature[index].status ?
                                                                                    <>
                                                                                        <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                                        < i className="fa fa-spinner fa-spin resize-loader" />
                                                                                    </>
                                                                                    :
                                                                                    <div className={reviewData.features.feature[index].image !== '' ? 'color-image-name' : ''}>
                                                                                        <FaCloudUploadAlt />
                                                                                        {loader.feature[index].featureImageName}
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        <div className="img-box ">
                                                                            {curElem.image !== '' ?
                                                                                <img src={curElem.image} alt="" className="create-video-image" /> : ''}
                                                                        </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <OverlayTrigger
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    A detailed description of the product feature.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="form-group">
                                                                <small className="form-text text-muted">Details</small>
                                                                <textarea
                                                                    type="text"
                                                                    name="details"
                                                                    className="form-control"
                                                                    placeholder="Details"
                                                                    value={curElem.details}
                                                                    onChange={(e) => handleChangeFeature(e, index)}
                                                                    maxLength={300}
                                                                />
                                                                <div className="counter">{curElem.details.length}/300</div>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'FEATURE')} />
                                                    </div>
                                                )
                                            })}

                                        </> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt">
                                            <img src={image4} alt="" /> Testimonials</h6>
                                        <div className="switch-single abs">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="Testimonial"
                                                    onChange={(e) => handleToggle(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {reviewData.testimonials.enable ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="singleTitle mb-3">
                                                        <span>Add Testimonials</span>
                                                        <FiPlusCircle style={{ cursor: 'pointer' }} onClick={() => handleAdd('TESTIMONIAL')} />
                                                    </div>
                                                </div>
                                            </div>
                                            {reviewData.testimonials.testimonial.map((curElem, index) => {
                                                return (
                                                    <div className="groupDetails" key={index}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Name of the person/s who provided the testimonial.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Name</small>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Name"
                                                                            name="name"
                                                                            value={curElem.name}
                                                                            required
                                                                            onChange={e => handleChangeTestimonial(e, index)}
                                                                            maxLength={15}
                                                                        />
                                                                        <div className="counter">{curElem.name.length}/15</div>
                                                                    </div>

                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Occupation or distinction of the person/s who provided the testimonial.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Title</small>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Title"
                                                                            name="designation"
                                                                            value={curElem.designation}
                                                                            required
                                                                            onChange={e => handleChangeTestimonial(e, index)}
                                                                            maxLength={15}
                                                                        />
                                                                        <div className="counter">{curElem.designation.length}/15</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Image of the product you are reviewing
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Image</small>
                                                                        <div className="imgUp">
                                                                            <input
                                                                                type="file"
                                                                                name="testimonialImage"
                                                                                required
                                                                                onChange={e => onInputFile(e, 'testimonial', index)}
                                                                            />
                                                                            {
                                                                                loader.testimonial[index].status ?
                                                                                    <>
                                                                                        <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                                        < i className="fa fa-spinner fa-spin resize-loader" />
                                                                                    </>
                                                                                    :
                                                                                    <div className={reviewData.testimonials.testimonial[index].image !== '' ? 'color-image-name' : ''}>
                                                                                        <FaCloudUploadAlt />
                                                                                        {loader.testimonial[index].testimonialImageName}
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        <div className="img-box ">
                                                                            {curElem.image ?
                                                                                <img src={curElem.image} alt="" className="create-video-image" /> : ''}
                                                                        </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            The feedback given by the person/s who provided the testimonial.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Message</small>
                                                                        <textarea
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Heading"
                                                                            name="message"
                                                                            required
                                                                            value={curElem.message}
                                                                            onChange={e => handleChangeTestimonial(e, index)}
                                                                            maxLength={150}
                                                                        />
                                                                        <div className="counter">{curElem.message.length}/150</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'TESTIMONIAL')} />
                                                    </div>
                                                )
                                            })}

                                        </> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image5} alt="" /> Bonus</h6>
                                        <div className="switch-single abs">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="Bonus"
                                                    onChange={(e) => handleToggle(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {reviewData.bonuses.enable ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="singleTitle mb-3">
                                                        <span>Add Bonus</span>
                                                        <FiPlusCircle style={{ cursor: 'pointer' }} onClick={() => handleAdd('BONUS')} />
                                                    </div>
                                                </div>
                                            </div>
                                            {reviewData.bonuses.bonus.map((curElem, index) => {
                                                return (
                                                    <div className="groupDetails" key={index}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Name of the bonus pack that you'll be including with the product.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Name</small>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Name"
                                                                            name="name"
                                                                            value={curElem.name}
                                                                            required
                                                                            onChange={e => handleChangeBonus(e, index)}
                                                                            maxLength={20}
                                                                        />
                                                                        <div className="counter">{curElem.name.length}/20</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Total monetary worth of the bonus pack.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Value</small>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="$999"
                                                                            name="value"
                                                                            min={1}
                                                                            value={curElem.value}
                                                                            required
                                                                            onChange={e => handleChangeBonus(e, index)}
                                                                            maxLength={5}
                                                                        />
                                                                        <div className="counter">{curElem.value.length}/5</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Definition of the bonus pack you'll be including with the product.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Description</small>
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder="Description"
                                                                            name="description"
                                                                            value={curElem.description}
                                                                            onChange={e => handleChangeBonus(e, index)}
                                                                            maxLength={300}
                                                                        />
                                                                        <div className="counter">{curElem.description.length}/300</div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-top">
                                                                            Image of the product you are reviewing
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Image</small>
                                                                        <div className="imgUp">

                                                                            <input
                                                                                type="file"
                                                                                name="bonusImage"
                                                                                onChange={e => onInputFile(e, 'bonus', index)}
                                                                            />
                                                                            {
                                                                                loader.bonus[index].status ?
                                                                                    <>
                                                                                        <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                                        < i className="fa fa-spinner fa-spin resize-loader" />
                                                                                    </>
                                                                                    :
                                                                                    <div className={reviewData.bonuses.bonus[index].image !== '' ? 'color-image-name' : ''}>
                                                                                        <FaCloudUploadAlt />
                                                                                        {loader.bonus[index].bonusImageName}
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        <div className="img-box ">
                                                                            {curElem.image ?
                                                                                <img src={curElem.image} alt="" className="create-video-image" /> : ''}
                                                                        </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'BONUS')} />
                                                    </div>
                                                )
                                            })}

                                        </> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image6} alt="" /> Demo</h6>
                                        <div className="switch-single abs">
                                            <label className="switch">
                                                <input
                                                    onChange={(e) => handleToggle(e)}
                                                    type="checkbox"
                                                    name="Demo"
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {reviewData.demo.enable ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="singleTitle mb-3">
                                                        <span>Add Demo</span>
                                                        <FiPlusCircle style={{ cursor: 'pointer' }} onClick={() => handleAdd('DEMO')} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                reviewData.demo.demo.map((curElem, index) => {
                                                    return (
                                                        <div className="groupDetails" key={index}>
                                                            <div className="row">
                                                                <div className="col-md-6">

                                                                    {curElem.type !== 'file' ?
                                                                        <div className="form-group">
                                                                            <small className="form-text text-muted" style={{ textTransform: 'capitalize' }}>Enter {curElem.type.replace("customUrl", "Custom")} URL</small> {/* set dynamic url #sanjay sir(demo video option) */}
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Enter URL"
                                                                                className="form-control alt"
                                                                                value={curElem.src}
                                                                                name="src"
                                                                                onChange={e => handleChangeDemo(e, index)} />
                                                                        </div>
                                                                        :
                                                                        <div className="form-group">

                                                                            {/* <small className="form-text text-muted">Enter Video File</small> */}
                                                                            <div className="imgUp ">
                                                                                <input
                                                                                    type="file"
                                                                                    name="demoUrl"
                                                                                    onChange={e => onInputVideoFile(e, index)}
                                                                                />

                                                                                {
                                                                                    loader.demo[index].status ?
                                                                                        <>
                                                                                            <span style={{ position: 'absolute' }}>{percent}%</span>
                                                                                            < i className="fa fa-spinner fa-spin resize-loader" />
                                                                                        </>
                                                                                        :
                                                                                        <div className={reviewData.demo.demo[index].url !== '' ? 'color-image-name' : ''}>
                                                                                            <FaCloudUploadAlt />
                                                                                            {loader.demo[index].demoImageName}
                                                                                        </div>
                                                                                }

                                                                            </div>
                                                                            <div className="demo-box ">
                                                                                <img src={curElem.url} alt="" className="create-video-image" />
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>

                                                                <div className="col-md-6">


                                                                    <div className="form-group">
                                                                        <small className="form-text text-muted">Choose Video Type</small>
                                                                        <select
                                                                            className="form-control"
                                                                            value={curElem.type}
                                                                            name="type"
                                                                            onChange={(e) => handleChangeDemo(e, index)}
                                                                        >
                                                                            <option value={"file"}>Import file from computer</option>
                                                                            <option value={"youtube"}> YouTube </option>
                                                                            <option value={"vimeo"}>  Vimeo</option>
                                                                            <option value={"customUrl"}>URL</option>
                                                                        </select>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <FiTrash2 style={{ cursor: 'pointer' }} className="delIcon" onClick={() => handleDelete(index, 'DEMO')} />

                                                            <div className="text-light demo-message">
                                                                {demoDuration.limitMessage !== '' ?
                                                                    <p><RiErrorWarningFill fontSize={20} color={"crimson"} /> {demoDuration.limitMessage}</p> : ''}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>

                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="personal-details-box">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="form-box mt-5">
                                    <div className="lineTxt-wrap">
                                        <h6 className="lineTxt"><img src={image7} alt="" /> Color</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <small className="form-text text-muted">Primary Color</small>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="#f68802"
                                                    name="primary"
                                                    value={reviewData.color.primary}
                                                    required
                                                    onChange={handleChangeColor}
                                                />
                                                <button type="button" id="color-picker" style={{ backgroundColor: reviewData.color.primary !== '' ? reviewData.color.primary : '#F68802' }} onClick={() => setColorPicker({
                                                    ...colorPicker,
                                                    toggleText: false,
                                                    togglePrimary: true,
                                                    toggleSecondary: false
                                                })}>
                                                </button>
                                                {colorPicker.togglePrimary ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColorPicker({
                                                            ...colorPicker,
                                                            togglePrimary: false
                                                        })} />
                                                        <SketchPicker
                                                            color={reviewData.color.primary === '' ? '#f68802' : reviewData.color.primary}
                                                            onChange={(e) => handleColorPicker(e, 'primary')} />
                                                    </>
                                                    : ''}

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group" >
                                                <small className="form-text text-muted">Secondary Color</small>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="#e7a766"
                                                    name="secondary"
                                                    value={reviewData.color.secondary}
                                                    required
                                                    onChange={handleChangeColor}
                                                />

                                                <button type="button" id="color-picker" style={{ backgroundColor: reviewData.color.secondary !== '' ? reviewData.color.secondary : '#e7a766' }} onClick={() => setColorPicker({
                                                    ...colorPicker,
                                                    toggleText: false,
                                                    togglePrimary: false,
                                                    toggleSecondary: true
                                                })}>
                                                </button>
                                                {colorPicker.toggleSecondary ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColorPicker({
                                                            ...colorPicker,
                                                            toggleSecondary: false
                                                        })} />
                                                        <SketchPicker
                                                            color={reviewData.color.secondary === '' ? '#e7a766' : reviewData.color.secondary}
                                                            onChange={(e) => handleColorPicker(e, 'secondary')} />
                                                    </> : ''}

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <small className="form-text text-muted">Text Color</small>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="#dddcdb"
                                                    name="textColor"
                                                    value={reviewData.color.textColor}
                                                    required
                                                    onChange={handleChangeColor}
                                                />
                                                <button type="button" id="color-picker" style={{ backgroundColor: reviewData.color.textColor !== '' ? reviewData.color.textColor : '#dddcdb' }} onClick={() => setColorPicker({
                                                    ...colorPicker,
                                                    toggleText: true,
                                                    togglePrimary: false,
                                                    toggleSecondary: false
                                                })}>
                                                </button>

                                                {colorPicker.toggleText ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColorPicker({
                                                            ...colorPicker,
                                                            toggleText: false,
                                                        })} />
                                                        <SketchPicker
                                                            color={reviewData.color.textColor === '' ? '#dddcdb' : reviewData.color.textColor}
                                                            onChange={(e) => handleColorPicker(e, 'textColor')} />
                                                    </> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-10 mx-auto">
                            <div className="form-group">
                                <button type="submit" className={`${loader.submit ? 'text-light' : 'btn-change6'} btn-block themebtn`} disabled={loader.submit}>
                                    {loader.submit ? <> CREATING VIDEO < i className="fa fa-spinner fa-spin" /> </> : 'CREATE VIDEO'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section >

            <Footer />
        </>
    )
}

export default CeeateVideo;
