import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
import AudioFiles from "../AudioFiles";
import swal from "sweetalert";
import { setAlert } from "../../../actions/alert";
import Alert from "../../Alert";

const UploadMusic = ({ selectedSlideIndex }) => {

    const video = useSelector(state => state.videos)

    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState(false);


    let allowedExt = ['audio/mpeg'];

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);

    const fetchUserLibrary = () => {
        const data = { type: 'music' }
        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-uploaded-file`,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let val = res.data.data
                val = val.reverse()
                setUploadedFiles(val);
            }
            else {
                if (uploadedFiles.length === 1) {
                    setUploadedFiles([])
                }
            }
        }).catch(err => {

        })
    }

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('type', 'music');
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-user-media`, formData, options).then(res => {
                        if (res.data.status === true) {
                            dispatch(setAlert(res.data.message, 'success'));
                            fetchUserLibrary();
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'));
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for MP3 is 5MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const removeMedia = async (id) => {

        let formData = new FormData();
        formData.append('id', id);

        await axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchUserLibrary();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
        })
    }

    useEffect(() => {
        fetchUserLibrary();
    }, [])

    return (
        <>
            <Alert />
            <div className="innertab">
                <div className="tabSearch">
                    <button className="demoLink btn-block mt-0">
                        <i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload Music
                    </button>
                    <input className="absInp" type="file" onChange={(e) => onInputFile(e)} />
                </div>
                {
                    loader ?
                        <div className="progress mt-4">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%
                            </div>
                        </div>
                        : ''
                }
                <div className="innertab-scroll">
                    <div className="mediaList">
                        <ul>
                            {uploadedFiles.length > 0 ?
                                uploadedFiles.reverse().map((item, index) => {
                                    return (
                                        <AudioFiles
                                            selectedSlideIndex={selectedSlideIndex}
                                            title={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                                            url={item.url}
                                            currentUrl={currentUrl}
                                            setCurrentUrl={setCurrentUrl}
                                            type="uploaded"
                                            removeMedia={removeMedia}
                                            selectedAudio={selectedAudio}
                                            setSelectedAudio={setSelectedAudio}
                                            id={item.id}
                                            key={index}
                                        />
                                    )
                                })
                                : ''}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadMusic;
