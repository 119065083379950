import React, { useState, useEffect } from 'react';
import { Tab, Nav } from "react-bootstrap";
import ImageMedia from './ImageMedia';
import TemplateAsset from './TemplateAsset';
import UploadMedia from './UploadMedia';
import VideoMedia from './VideoMedia';

const Media = ({ slideData, selectImageLayer, setSelectImageLayer }) => {
    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    const [selectedTextIndex, setSelectedTextIndex] = useState(false)

    useEffect(() => {
        if (selectedSlideIndex !== -1 && selectedSlideIndex !== undefined) {
            let num = slideData[selectedSlideIndex].text.findIndex(({ is_selected }) => is_selected === true)
            setSelectedTextIndex(num)
        }
    })

    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="library-tab">Videos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="content-tab">Template Assets</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <ImageMedia
                                selectImageLayer={selectImageLayer}
                                selectedSlideIndex={selectedSlideIndex}
                                selectedTextIndex={selectedTextIndex}
                                setSelectImageLayer={setSelectImageLayer}
                                slideData={slideData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="library-tab">
                            <VideoMedia
                                selectImageLayer={selectImageLayer}
                                selectedSlideIndex={selectedSlideIndex}
                                selectedTextIndex={selectedTextIndex}
                                slideData={slideData}

                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">
                            <UploadMedia
                                selectImageLayer={selectImageLayer}
                                selectedSlideIndex={selectedSlideIndex}
                                selectedTextIndex={selectedTextIndex}
                                slideData={slideData}

                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="content-tab">
                            <TemplateAsset
                                slideData={slideData}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default Media;
