import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ProfileImage from "./ProfileImage";
import ProfileInfo from "./ProfileInfo";
import ProfilePassword from "./ProfilePassowrd";
import Title from "../Title";

const Profile = () => {
    return (
        <>
            <Title title="Profile" />
            <Navbar />
            <section className="profile-sec">
                <div className="container">
                    <ProfileImage />
                    <ProfileInfo />
                    <ProfilePassword />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Profile;