import styled from "styled-components";
export const Intro3style = styled.div`

.template-wrapper {
    position: absolute!important;
    top: 0;
    height: 100%!important;
    width: 100%!important;
    text-align:center;
    overflow: hidden;
}

.template-wrapper img {
    max-height: 100%;
   
}
  

.template-preview {
    position: absolute;
      width: 100%;
      height: auto;
      top: 0;
}

.product_wrapper {
    position: absolute;
    top: 80px;
    right: 23px;
    height: 182px;
}

.product_wrapper img {
   max-height:100%;
}

.video-player{
    width:180px!important;
    max-height:100%;
}

.video-player video{
    object-fit:unset;
}

.text-wrappers {
    position: absolute;
    top: 107px;
    left: 12px;
    width: 86%;
}

 p{
   margin-bottom:0px;
 }

.text-wrapper-2{
    position: absolute;
    bottom: 0px;
}

.text-wrappers-2 {
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 324px;
    left: 4px;
}

.text-wrappers-2 p {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    text-transform: uppercase;
    padding: 5px 0;
}
@media (max-width: 1199.98px) { 
    .product_wrapper {
        position: absolute;
        top: 58px;
        right: 21px;
        height: 153px;
    }
  .text-wrappers {
        position: absolute;
        top:68px;
        width: 86%;
        left: 12px;
    }
    
    .text-wrappers-2 {
        position: absolute;
        top: 280px;
        left: 4px;
    }
   
}
`;