import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../Alert";
import { setAlert } from "../../../actions/alert";
import AudioFiles from "../AudioFiles";

const Sonority = ({ selectedSlideIndex }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social.sonority);

    const [loader, setLoader] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [musicData, setMusicData] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [currentUrl, setCurrentUrl] = useState('');


    const connectSonority = () => {
        setShowDelete(true);
    }

    const onConfirm = () => {
        history.push('/integration')
    }

    const fetchSonorityMedia = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: 'https://regis12.amzitrage.com/fetch-all',
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': socialData[0].token }
        }).then(res => {
            if (res.data.status === true) {
                setMusicData(res.data.data);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        if (socialData.length > 0) {
            fetchSonorityMedia();
        }

        return () => {
            setMusicData(false);
        }
    }, [])

    return (
        <>
            <Alert />
            <div className="row m-2" style={{ height: "auto" }}>
                {
                    socialData.length > 0 ? <div className="alert alert-warning text-center" role="alert">
                        This music will apply to the current slide
                    </div>
                        : <div className="alert alert-warning text-center" role="alert">
                            You haven't connected your Sonority account yet. Click <a className="custom-anchor" onClick={() => connectSonority()}>Here</a> to connect and use those audios
                        </div>
                }
                {
                    loader ?
                        <div className="col-md-12 text-center">
                            <i className={`fa fa-spinner fa-spin`} style={{ color: '#ffa200', fontSize: '20px' }} />
                        </div> : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Audio Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.merge.map((item, index) => {
                            return (
                                <AudioFiles
                                    title="Uploaded VoiceOver"
                                    selectedSlideIndex={selectedSlideIndex}
                                    voiceOverName={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    key={index}
                                />
                            )
                        })
                        : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Music Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.music.map((item, index) => {
                            return (
                                <AudioFiles
                                    voiceOverName={item.title}
                                    title="Uploaded VoiceOver"
                                    selectedSlideIndex={selectedSlideIndex}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    key={index}
                                />
                            )
                        })
                        : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6>Voice Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.tts.map((item, index) => {
                            return (
                                <AudioFiles
                                    title="Uploaded VoiceOver"
                                    selectedSlideIndex={selectedSlideIndex}
                                    voiceOverName={item.title}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    key={index}
                                />
                            )
                        })
                        : ''
                }

            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText="Connect"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
            </SweetAlert>
        </>
    )
}

export default Sonority;