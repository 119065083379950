import React, { useState } from 'react';
import quote from "../../../StaticMediaFiles/image/testi_3_quote.png"
import { Testimonial3style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Testimonial3style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container p-0">
                        <div className="row m-0 ">
                            {props.templateText.map((curElem) => {
                                return (
                                    <div className="col-md-3 p-0" key={curElem.id}>
                                        <div className={`card p-0 card_${curElem.id}`}>
                                            <div className="name_wrapper">
                                                <span className="line"></span>
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={curElem.is_selected}
                                                    textStatus={curElem.name.is_selected}
                                                    layerId={curElem.id}
                                                    textId={curElem.name.id}
                                                    text={curElem.name.text}
                                                    style={curElem.name.style}
                                                    type={props.templateType}
                                                    setTextLength={props.setTextLength}
                                                    setMaxLimit={props.setMaxLimit}
                                                    templateNumber={props.templateNumber}
                                                    blockType={
                                                        {
                                                            tag: 'h1',
                                                        }
                                                    }
                                                />
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={curElem.is_selected}
                                                    textStatus={curElem.designation.is_selected}
                                                    layerId={curElem.id}
                                                    textId={curElem.designation.id}
                                                    text={curElem.designation.text}
                                                    style={curElem.designation.style}
                                                    type={props.templateType}
                                                    setTextLength={props.setTextLength}
                                                    setMaxLimit={props.setMaxLimit}
                                                    templateNumber={props.templateNumber}
                                                    blockType={
                                                        {
                                                            tag: 'h1',
                                                        }
                                                    }

                                                />
                                            </div>
                                            <div className={`image-wrapper ${layer.image && curElem.is_selected ? 'image-bg-border' : ''}`}>
                                                <MediaEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    mediaType="pImage"
                                                    layerId={curElem.id}
                                                    data={curElem.image}
                                                    type={curElem.image.includes("mp4") ? 'video' : 'image'}
                                                    setSelectImageLayer={props.setSelectImageLayer}
                                                />

                                            </div>
                                            <div className="quote">
                                                <img src={quote} alt="" />
                                            </div>
                                            <div className="circle rounded-circle"></div>
                                            <div className="circle-2 rounded-circle"></div>
                                            <div className="content">
                                                <TextEditor
                                                    layer={layer}
                                                    setLayer={setLayer}
                                                    layerStatus={curElem.is_selected}
                                                    textStatus={curElem.text.is_selected}
                                                    layerId={curElem.id}
                                                    textId={curElem.text.id}
                                                    text={curElem.text.text}
                                                    style={curElem.text.style}
                                                    type={props.templateType}
                                                    setTextLength={props.setTextLength}
                                                    setMaxLimit={props.setMaxLimit}
                                                    templateNumber={props.templateNumber}
                                                    blockType={
                                                        {
                                                            tag: 'p',
                                                        }

                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </Testimonial3style >

    );

};
export default Template;