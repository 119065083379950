import styled from "styled-components";
export const Dedicated1style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  

.product-wrappers {
    height: 160px;
    position: absolute;
    top: 77px;
    right: 13px;
    z-index: 1;
 }

 .product-wrappers img{
   max-height:100%
 }

.head-box {
    width: 100%;
    position: absolute;
    top: 53px;
    margin-left: 15px;
}

.head-box h1 {
    font-size: 30px;
}

.text-wrappers {
  width:100%;
  position: absolute;
  top: 122px;
  margin-left: 15px;
}

.video-player{
  width:220px!important;
  max-height:100%;
}



@media (max-width: 1199.98px) { 
  
    .text-wrappers {
      top: 135px;
    }
    .product-wrappers {
        height: 190px;
    }
    
    
}


`;