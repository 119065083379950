import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { baseURL } from "../../global/global";
import Navbar from "../Navbar";
import Title from "../Title";
import './editor.css'
import VideoTiles from './VideoTiles/VideoTiles'
import TextStyle from "./TextStyle";
import { Tab } from "react-bootstrap";
import { setAlert } from '../../actions/alert'
import { addVideoData } from '../../actions/videoAction'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import StoryEditor from "./StoryEditor";
import Media from "./Media/Media";
import VoiceOver from "./VoiceOver/VoiceOver";
import Music from "./Music/Music";
import Setting from "./Setting/Setting";
import Credit from "./Credits/Credit";
import EditorHeader from "./Header/EditorHeader";
import Preview from "./Preview/Preview";
import { updateSelectedTiles } from "../../actions/videoAction";
import { Conclusion1style } from "./Templates/Conclusion/1/style";

const Editor = ({ location }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const id = queryString.parse(location.search).id;
  const video = useSelector(state => state.videos)
  const [varientData, setVarientData] = useState([])
  const [editorLoader, setEditorLoader] = useState(true);
  const [slideData, setSlideData] = useState([])
  const [selectImageLayer, setSelectImageLayer] = useState(false)
  const [fonts, setFonts] = useState([]);

  const affilate = {
    enable: false,
    text: "",
    fontSize: 18,
    fontFamily: "",
    fontFamilyFile: "https://backend.reviewreel.io/temp/AguafinaScript-Regular.ttf",
    color: "#ffffff",
    bgColor: false,
    type: "affiliate_link",
    postion: "bottom-center"
  }
  const [textLength, setTextLength] = useState(0)
  const [maxLimit, setMaxLimit] = useState(0)


  const handleResponse = (val) => {
    val.slides.forEach((curElem) => {
      if (curElem.text.length > 0) {
        curElem.text[0].is_selected = true
        curElem.text.forEach((textElem) => {
          textElem.text.is_selected = true
        })
      }
      if (val.affiliate_link === "") {
        val.affiliate_link = affilate
      }
    })
    dispatch(addVideoData(val))
  }

  useEffect(() => {
    const data = { 'campaignId': id }
    setEditorLoader(true)
    if (id !== undefined) {
      axios({
        method: 'POST',
        url: `${baseURL}project-get`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem('state')).auth.token }
      }).then((res) => {
        if (res.data.status === true) {
          let val = res.data.data
          const checkSlideTrue = val.slides.findIndex(({is_selected}) => is_selected === true)
          if(checkSlideTrue === -1){
            val.slides[0].is_selected = true
          }
          handleResponse(val)
        }
        else {
          dispatch(setAlert(`You don't have video id ${id}!`, 'danger'))
          history.push(`/editor?id=${video.data.campaignId}`)
        }
      }).catch((error) => {
        console.log(error)
        setEditorLoader(false)
      })
    }

    return () => {
      dispatch(addVideoData(false))
    }
  }, [id])



  useEffect(() => {
    if (video.data && id !== undefined) {
      setSlideData(video.data.slides)
      setEditorLoader(false)
    }
  }, [video])


  const handleForwardSlide = () => {
    let selectedTileIndex
    if (slideData) {
      selectedTileIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    }
    if (slideData.length - 2 >= selectedTileIndex) {
      dispatch(updateSelectedTiles(selectedTileIndex + 1))
    }
  }

  const handleBackwardSlide = () => {
    let selectedTileIndex
    if (slideData) {
      selectedTileIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    }
    if (selectedTileIndex > 0) {
      dispatch(updateSelectedTiles(selectedTileIndex - 1))
    }
  }

  return (
    <>
      {
        !editorLoader ?
          video.data ?
            <>
              <Title title="Editor" />
              <Navbar />
              <section className="siteWrap">
                <div className="editorWrap myeditorWrap">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="story">

                    <div className="editor-left">
                      <div>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="story"
                          >
                            <StoryEditor
                              slideData={slideData}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="text"
                          >
                            <TextStyle
                              slideData={slideData}
                              fonts={fonts}
                              setFonts={setFonts}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="media"
                          >

                            <Media
                              slideData={slideData}
                              selectImageLayer={selectImageLayer}
                              setSelectImageLayer={setSelectImageLayer}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="audio"

                          >
                            <VoiceOver
                              slideData={slideData}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="backaudio"
                          >

                            <Music
                              slideData={slideData}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="settings"
                          >
                            <Setting
                              video={video}
                              slideData={slideData}
                              fonts={fonts}
                            />
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="credits"
                          >

                            <Credit
                              video={video}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                    <div className="editor-right">


                      <EditorHeader
                        video={video.data}
                      />

                      <div className="canvasEditor">
                        {varientData.length > 0}{
                          // <div className="template-limitbar float-right">{textLength} / {maxLimit}</div>
                        }
                        <div className="canvasEditor-main">
                          <Preview
                            setSelectImageLayer={setSelectImageLayer}
                            setTextLength={setTextLength}
                            setMaxLimit={setMaxLimit}
                          />

                        </div>
                        <div className="videoControll">
                          <div className="row">

                            <div className="col-12">
                              <div className="videoControll-nav">
                                <ul className="edit-vid-btn">
                                  <li>
                                    <i className="fa fa-step-backward" aria-hidden="true" onClick={handleBackwardSlide}></i>
                                  </li>
                                  <li><i className="fa fa-step-forward" aria-hidden="true" onClick={handleForwardSlide}></i></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </section>
              <div className="editorReel">
                <VideoTiles
                  slideData={slideData}
                />
              </div>
            </>

            : ''
          :
          <div className="loader-sec">
            <div className="loader">
            </div>
          </div>
      }
    </>
  )
}

export default Editor;