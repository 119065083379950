import React, { useState } from 'react';
import { Bonusdedicate4style } from "./style"
import TextEditor from '../../../../Preview/TextEditor';
import MediaEditor from '../../../../Preview/MediaEditor';
import TemplateLogo from '../../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../../Preview/TemplateWatermark';
import product_bg from "../../../../../../images/bonus_temp_4.png"
import price_bg from "../../../../../../images/bonus_tag-4.png"

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Bonusdedicate4style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 p-0">
                                <div className="background-white-space"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        width: '410px',
                                        left: ' -84px',
                                        height: '358px',
                                        backgroundImage: ` url( ${product_bg})`,

                                    }}
                                > </div>
                                <div className={` ${layer.image ? 'image-bg-border' : ''} ${props.templateText[0].image.toLowerCase().includes(".png") ? 'product-wrappers-2' : 'product-wrappers'}`}>
                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        data={props.templateText[0].image}
                                        mediaType="pImage"
                                        type={props.templateText[0].image.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    /> 
                                    </div>
                                <div className="price-tag">
                                    <img src={price_bg} alt="" />
                                    <div className="price-value">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].value.is_selected}
                                            layerId={props.templateText[0].id}
                                            // textId={props.templateText[0].value.id}
                                            textId={1}
                                            text={props.templateText[0].value.text}
                                            style={props.templateText[0].value.style}
                                            type={props.templateType}
                                            blockType={
                                                {
                                                    tag: 'span',
                                                }

                                            }
                                        />
                                    </div>
                                </div>

                                {/* </div> */}
                            </div>
                            <div className="col-sm-6">
                                <div className="product-intro">
                                    <div className="head-box">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].heading.is_selected}
                                            layerId={props.templateText[0].id}
                                            textId={props.templateText[0].heading.id}
                                            text={props.templateText[0].heading.text}
                                            style={props.templateText[0].heading.style}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'h1',
                                                }

                                            }
                                        />

                                    </div>
                                    <div className="text-wrappers ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            // textId={props.templateText[0].text.id}
                                            textId={2}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            type={props.templateType}
                                            setTextLength={props.setTextLength}
                                            templateNumber={props.templateNumber}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Bonusdedicate4style >

    );

};
export default Template;