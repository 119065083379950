import styled from "styled-components";
export const Description1style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
  .video-player{
    width:220px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.video-player{
    width:220px!important;
    max-height:100%!important;
} 

.product-wrappers{
  height: 240px;
  position: absolute;
  top: 48px;
  left: 28px;
}  

.product-wrappers img{
  max-height:100%
}

.head-box{
  position: absolute;
    top: 54px;
    left: 12px;
    width: 302px;
    height: auto;
}
.head-box h1{
  margin-bottom:0;
  width:100%;
  height:auto;
  overflow:hidden;
}
.text-wrappers{
  width: 302px;
  position: absolute;
  top: 155px;
  left: 12px;
}
.text-wrappers p{
    margin-bottom:0;
    width:100%;
    height:auto;
    overflow:hidden;
}

@media (max-width: 1199.98px) { 
  .head-box{
    position: absolute;
    top: 45px;
    left: 10px;
    width: 260px;
    height: auto;
  }
  .head-box h1{
    margin-bottom:0;
    width:100%;
    height:auto;
  }
  .text-wrappers{
    position: absolute;
    top: 135px;
    left: 10px;
    width: 260px;
    height: auto;
  }

  .product-wrappers{
    height: 222px;
    position: absolute;
    top: 44px;
    left: 14px;
  }  
  
}

`;