import React, { useEffect, useState } from "react";
import TemplateBody from "./TemplateBody";
import { useSelector } from "react-redux";

const Preview = (props) => {
    const video = useSelector(state => state.videos.data)
    const [fontLimit, setFontLimit] = useState(false)

    const selectedSlide = video.slides.find(({ is_selected }) => is_selected === true)

    const colors = video.color
    const [affStyle, setAffStyle] = useState({
        fontSize: '',
        fontFamily: '',
        position: 'bottom-center',
        color: '#ffffff',
        backgroundColor: '#282727'

    })

    useEffect(() => {
        setAffStyle({
            ...affStyle,
            fontSize: parseInt(video.affiliate_link.fontSize),
            fontFamily: video.affiliate_link.fontFamily,
            color: video.affiliate_link.color,
            backgroundColor: video.affiliate_link.bgColor === false ? '#282727' : video.affiliate_link.bgColor,
        })
    }, [video])

    return (
        <>
            <TemplateBody
                templateNumber={selectedSlide.templateNumber}
                templateType={selectedSlide.type}
                setSelectImageLayer={props.setSelectImageLayer}
                templateText={selectedSlide.text}
                backgroundData={selectedSlide.backgroundData}
                productImage={selectedSlide.productImage}
                colors={colors}
                logo={video.logo}
                waterMark={video.waterMark}
                textVarient={fontLimit ? fontLimit.texts : 0}
                setTextLength={props.setTextLength}
                setMaxLimit={props.setMaxLimit}
            />
            {video ?
                video.affiliate_link !== undefined ?
                    video.affiliate_link.enable ?
                        <div className={`text-light text-center affiliate-wrapper logo-${video.affiliate_link.postion === "" ? 'bottom-center' : video.affiliate_link.postion}`}>
                            <p style={affStyle} className="affiliate-text">{video.affiliate_link.text}</p>
                        </div> : '' : '' : ''
            }
        </>
    )
}
export default Preview;