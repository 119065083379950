import styled from "styled-components";
export const List1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top:10px;
}
.head-box h1{
  text-transform: uppercase;
  font-size: 42px;
  font-weight: bold;
  color:#fff;
}

.text-content{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.text-wrappers-1{
    width:510px;
    position: absolute;
    top: -12px;
    left: -2px;
}
.text-wrappers-2{
    width: 510px;
    position: absolute;
    top: 54px;
    left: 15px;
}
.text-wrappers-3{
    width: 510px;
    position: absolute;
    top: 119px;
    left: 50px;
}
.text-wrappers-4{
    width: 510px;
    position: absolute;
    top: 183px;
    left: 84px;
}

.text-wrappers p{
  width:100%;
}

.Feature-list {
    position: absolute;
    top: 40px;
}
 

@media (max-width: 1199.98px) { 
  
  .text-content {
     margin-bottom: 14px;
  }
    .Feature-list {
        position: absolute;
        top: 0px;
    }
 .text-wrappers-1 {
        width: 470px;
        position: absolute;
        top: 18px;
        left: -2px;
    }
    .text-wrappers-2 {
        width: 470px;
        position: absolute;
        top: 75px;
        left: 16px;
    }

    .text-wrappers-3 {
        width: 470px;
        position: absolute;
        top: 131px;
        left: 37px;
    }
    .text-wrappers-4 {
        width: 470px;
        position: absolute;
        top: 190px;
        left: 60px;
    }

}

`;