import React, { useState } from "react"
import { Props_Cons2style } from './style'
import MediaEditor from "../../../Preview/MediaEditor";
import hexRgb from "hex-rgb";
import TextEditor from "../../../Preview/TextEditor";
import TemplateLogo from "../../../Preview/TemplateLogo";
import TemplateWatermark from "../../../Preview/TemplateWatermark";


const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    const primary = hexRgb(props.colors.primary)
    const secondary = hexRgb(props.colors.secondary)


    return (
        <Props_Cons2style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    // data={Pros_and_Cons2}
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                // type='image'
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="card-wrapper-1">
                                    <span className="circle_wrapper">
                                        <div className="circle-shadow">
                                            <span className="circle-1 rounded-circle" style={{ backgroundColor: "#ee73d1" }}></span>
                                        </div>
                                    </span>
                                    <div className="shadow-wrapper">
                                        <div className="card box-1">
                                            <div className="card-body card-text">
                                                {props.templateText.map((curElem, index) => {
                                                    if (curElem.text.textType === "cons") {
                                                        return (
                                                            <>
                                                                <div className="text-wrappers" key={curElem.id} style={{ color: `${props.colors.textColor}` }}>
                                                                    <TextEditor
                                                                        layer={layer}
                                                                        setLayer={setLayer}
                                                                        layerStatus={curElem.is_selected}
                                                                        textStatus={curElem.text.is_selected}
                                                                        layerId={curElem.id}
                                                                        text={curElem.text.text}
                                                                        style={curElem.text.style}
                                                                        setTextLength={props.setTextLength}
                                                                        templateNumber={props.templateNumber}
                                                                        setMaxLimit={props.setMaxLimit}
                                                                        blockType={
                                                                            { tag: 'p', }
                                                                        }
                                                                    />
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">

                                <div className="arrow-box" >
                                    <div className="arrow_wrapper">
                                        {/* style={{ filter: `drop-shadow(4px 5px 3px rgba(${primary.red},${primary.green},${primary.blue},0.7))` }}> */}
                                        <div className="arrow-1" style={{ backgroundColor: "#ee7d31" }}>
                                            <span className="pro-text">CON'S</span>
                                        </div>
                                    </div>
                                    <div className="arrow_wrapper-2">
                                        {/* style={{ filter: `drop-shadow(5px -5px 3px rgba(${secondary.red}, ${secondary.green}, ${secondary.blue}, 0.7))` }}> */}
                                        <div className="arrow-2" style={{ backgroundColor: "#599bd5" }}>
                                            <span className="con-text">PRO'S</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card-wrapper-2">
                                    <span className="circle_wrapper-2">
                                        <div className="circle-shadow">
                                            <span className="circle-2 rounded-circle" style={{ backgroundColor: "##599bd5" }}></span>
                                        </div>
                                    </span>
                                    <div className="shadow-wrapper">
                                        <div className="card box-2">
                                            <div className="card-body card-text">
                                                {props.templateText.map((curElem, index) => {
                                                    if (curElem.text.textType === "pros") {
                                                        return (
                                                            <>
                                                                <div className="text-wrappers" key={curElem.id}>
                                                                    <TextEditor
                                                                        layer={layer}
                                                                        setLayer={setLayer}
                                                                        layerStatus={curElem.is_selected}
                                                                        textStatus={curElem.text.is_selected}
                                                                        layerId={curElem.id}
                                                                        text={curElem.text.text}
                                                                        templateNumber={props.templateNumber}
                                                                        style={curElem.text.style}
                                                                        setTextLength={props.setTextLength}
                                                                        setMaxLimit={props.setMaxLimit}
                                                                        blockType={
                                                                            { tag: 'p', }
                                                                        }

                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Props_Cons2style>

    );
};
export default Template;