import React, { useState } from 'react';
import { List6style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';
import feature  from "../../../../../images/Feature6.png"

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <List6style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    data={props.backgroundData.fileUrl}
                    mediaType="bgImage"
                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}

                />
                <div className="template-preview flex-column">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="head-box text-center">
                                  <img src={feature} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="Feature-list">
                                    {props.templateText[0] ?
                                        <div className="text-wrapper-1">
                                            <TextEditor
                                                layer={layer}
                                                setLayer={setLayer}
                                                layerStatus={props.templateText[0].is_selected}
                                                textStatus={props.templateText[0].text.is_selected}
                                                layerId={props.templateText[0].id}
                                                text={props.templateText[0].text.text}
                                                templateNumber={props.templateNumber}
                                                style={props.templateText[0].text.style}
                                                setTextLength={props.setTextLength}
                                                setMaxLimit={props.setMaxLimit}
                                                blockType={
                                                    {
                                                        tag: 'p',
                                                    }

                                                }
                                            />

                                        </div> : ''}
                                    {props.templateText[1] ?
                                        <div className="text-wrapper-2">
                                            <TextEditor

                                                layer={layer}
                                                setLayer={setLayer}
                                                layerStatus={props.templateText[1].is_selected}
                                                textStatus={props.templateText[1].text.is_selected}
                                                layerId={props.templateText[1].id}
                                                text={props.templateText[1].text.text}
                                                templateNumber={props.templateNumber}
                                                style={props.templateText[1].text.style}
                                                setTextLength={props.setTextLength}
                                                setMaxLimit={props.setMaxLimit}
                                                blockType={
                                                    {
                                                        tag: 'p',
                                                    }

                                                }
                                            />
                                        </div> : ''}

                                </div>
                            </div>
                            <div className="col-sm-6">

                                <div className="Feature-list-2">
                                    {props.templateText[2] ?
                                        <div className="text-wrapper-1">
                                            <TextEditor

                                                layer={layer}
                                                setLayer={setLayer}
                                                layerStatus={props.templateText[2].is_selected}
                                                textStatus={props.templateText[2].text.is_selected}
                                                layerId={props.templateText[2].id}
                                                text={props.templateText[2].text.text}
                                                style={props.templateText[2].text.style}
                                                setTextLength={props.setTextLength}
                                                setMaxLimit={props.setMaxLimit}
                                                blockType={
                                                    {
                                                        tag: 'p',
                                                    }

                                                }
                                            />

                                        </div> : ''}
                                    {props.templateText[3] ?
                                        <div className="text-wrapper-2">
                                            <TextEditor
                                                layer={layer}
                                                setLayer={setLayer}
                                                layerStatus={props.templateText[3].is_selected}
                                                textStatus={props.templateText[3].text.is_selected}
                                                layerId={props.templateText[3].id}
                                                text={props.templateText[3].text.text}
                                                style={props.templateText[3].text.style}
                                                setTextLength={props.setTextLength}
                                                setMaxLimit={props.setMaxLimit}
                                                blockType={
                                                    {
                                                        tag: 'p',
                                                    }

                                                }
                                            />
                                        </div> : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </List6style >

    );

};
export default Template;