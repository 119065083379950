import React, { useState } from "react";
import { Bonusdedicate6style } from "./style";
import TextEditor from '../../../../Preview/TextEditor';
import MediaEditor from '../../../../Preview/MediaEditor';
import TemplateLogo from "../../../../Preview/TemplateLogo";
import TemplateWatermark from "../../../../Preview/TemplateWatermark";


const Template = (props) => {

    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    // console.log(props.backgroundData.fileUrl, props.backgroundData.type)

    return (
        < Bonusdedicate6style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}   >
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}

                />
                <div className="circle-1">
                    <div className="circle-2">
                        <div className="circle-3">
                            <div className="price-value">
                                <TextEditor
                                    layer={layer}
                                    setLayer={setLayer}
                                    layerStatus={props.templateText[0].is_selected}
                                    textStatus={props.templateText[0].value.is_selected}
                                    layerId={props.templateText[0].id}
                                    // textId={props.templateText[0].value.id}
                                    textId={1}
                                    text={props.templateText[0].value.text}
                                    style={props.templateText[0].value.style}
                                    type={props.templateType}
                                    blockType={
                                        {
                                            tag: 'span',
                                        }

                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="template-preview">
                    <div className="container">
                        <div className="row" >

                            <div className="col-sm-6 ">
                                <div className="text-area">
                                    <div className="text-wrappers-1 ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].heading.is_selected}
                                            layerId={props.templateText[0].id}
                                            textId={props.templateText[0].heading.id}
                                            text={props.templateText[0].heading.text}
                                            style={props.templateText[0].heading.style}
                                            templateNumber={props.templateNumber}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'h1',
                                                }

                                            }
                                        />
                                    </div>
                                    <div className="text-wrappers-2 ">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            // textId={props.templateText[0].text.id}
                                            textId={2}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            type={props.templateType}
                                            setTextLength={props.setTextLength}
                                            templateNumber={props.templateNumber}
                                            setMaxLimit={props.setMaxLimit}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />
                                    </div>
                                </div>
                            </div >
                            <div className="col-sm-6 ">
                                <div className="media-bg"></div>
                                <div className="media-bg-2"></div>
                                <div className={` ${layer.image ? 'image-bg-border' : ''} ${props.templateText[0].image.toLowerCase().includes(".png") ? 'product-wrappers-2' : 'product-wrappers'}`}>

                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        data={props.templateText[0].image}
                                        mediaType="pImage"
                                        type={props.templateText[0].image.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    />
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >

        </ Bonusdedicate6style >
    );
};
export default Template;