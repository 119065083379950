import styled from "styled-components";
export const Dedicated3style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }

.product-wrappers {
  height: 99px;
  position: absolute;
  top: 135px;
  left: 82px;
  z-index: 1;
 }

 .product-wrappers img{
   max-height:100%
 }

 .video-player{
  width:144px!important;
  max-height:100%;
}

.head-box {
    width: 100%;
    position: absolute;
    top: 53px;
    left: 20px;
}

.head-box h1 {
    font-size: 30px;
}

.text-wrappers {
  width:100%;
  position: absolute;
  left: 18px;
  top: 120px;
}

@media (max-width: 1199.98px) { 
  
    .head-box {
      width: 100%;
      position: absolute;
      top: 26px;
      left: 20px;
   }
  .text-wrappers {
      top: 123px;
      left: 6px;
  }
  .product-wrappers {
    height: 100px;
    position: absolute;
    left: 36px;
    }
 
    
}


`;