import React from "react";

const SocialNetwork = (props) => {

    return (
        <>
            <li className={`${props.status ? '' : 'disable'} cursor-pointer`}  >
                <a href={`#${props.name}_new`}>
                    <div className="img-wrraper">
                        <img src={props.icon} alt={props.name} width="56px" />
                    </div>
                    <p className="mt-2 m-0">{props.name}</p>
                </a>
            </li>
        </>
    )
}

export default SocialNetwork;