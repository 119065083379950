import styled from "styled-components";
export const Bonusdedicate1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }

  .head-box{
    width: 100%;
    margin-top: 10px;
    
  }
  .video-player{
    width:180px!important;
    max-height:100%;
  }
  

 .product-wrappers {
  height: 117px;
  position: absolute;
  top: 25px;
  left: 58px;
  z-index: 1;
 }

 .product-wrappers img{
   max-height:100%
 }
 .price-tag{
    position: absolute;
    top: -13px;
    right: -59px;
    width: 147px;
 }
 .price-value span{
    position: absolute;
    top: 84px;
    left: 55px;
 }

.text-wrappers {
    width: 100%;
    position: absolute;
    top: 200px;
   
}
p,h1{
  margin-bottom:0px;
}


@media (max-width: 1199.98px) { 
  
  .product-wrappers {
      height: 117px;
      position: absolute;
      top: 33px;
      left: 26px;
      z-index: 1;
 }
 .price-tag {
    position: absolute;
    top: -1px;
    right: -65px;
    width: 147px;
}


`;