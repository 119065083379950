import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/LOGO.png"
import navBg from "../images/Navbg.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authAction";

const Navbar = (props) => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);

    const logout = () => {
        dispatch(logoutUser());
    }
    const [membership, setMembership] = useState([]);

    useEffect(() => {
        if (auth.user !== false) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            {console.log('render navbar')}
            <header style={{height:"76px"}}>
                <nav className={`navbar px-lg-5 navbar-expand-lg navbar-me ${props?.page === 'editor' ? '' : 'fixed-me'} navbar-light`} style={{ backgroundImage: `url(${navBg})` }}>
                    <div className="container">
                        <Link className="navbar-brand" to="/dashboard">
                            <img src={rebrand.data !== false ? rebrand.data.logo : logo} alt="logo" className="img-fluid logo-image-set" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="text-white"><i className="fas fa-bars"></i></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/dashboard" activeClassName="active">Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/projects" activeClassName="active">Projects</NavLink>
                                </li>
                                {
                                    auth.user.is_client_account == "0" ?
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                        </li>
                                        : ''
                                }
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/help-and-support">Help</NavLink>
                                </li>
                            </ul>


                            <div className="dropdown show">
                                <button className="dropdown-toggle navmenuid " type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <span><img src={auth.user?.image ? auth.user?.image : logo} alt="user-img" width="40px" height="45px" /></span>
                                    <div className="text">
                                        <h6 className="m-0">Welcome</h6>{auth.user?.name}
                                    </div>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                    {auth.user.is_client_account === "0" ?
                                        <>
                                            <Link className="dropdown-item" to="/profile">
                                                <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                            </Link>
                                            {auth.user.is_virtual_account === "0" ? <>
                                                <Link className="dropdown-item" to="/privacy">
                                                    <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                </Link>
                                                {auth.user.is_reseller_account === "0" ?

                                                    <Link className="dropdown-item" to="/account-management">
                                                        <span className="mr-2"><i className="fas fa-users" style={{ fontSize: "14px" }} ></i></span>
                                                        Account Management
                                                    </Link> : ''}

                                            </> : ''}
                                            {membership.includes("unlimited") || membership.includes("bundle") ?
                                                <Link to='/reel-merge' className="dropdown-item">
                                                    <span className="mr-2">
                                                        <i className="fas fa-sign-out-alt"></i>
                                                    </span> Reel Merge
                                                </Link>
                                                : ''
                                            }
                                            {auth.user.is_reseller_account === "0" && auth.user.is_virtual_account === "0"
                                                ?
                                                <Link className="dropdown-item" to="/upgrades">
                                                    <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                </Link> : ''}

                                        </> :
                                        <Link className="dropdown-item" to="/profile">
                                            <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                        </Link>}


                                    <Link className="dropdown-item" to="/help-and-support">
                                        <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                    </Link>
                                    <span className="dropdown-item cursor-pointer" onClick={(e) => logout()}>
                                        <span className="mr-2">
                                            <i className="fas fa-sign-out-alt"></i>
                                        </span> Sign Out
                                    </span>

                                </div>



                            </div>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}


export default React.memo(Navbar);