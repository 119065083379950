import React from 'react'
import supportImg from '../../images/help-footer.png';

function CustomerSupport() {
    return (
        <div>
            <section className="customer-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6   ">
                            <div className="support-img"><img className="img-fluid" src={supportImg} alt=""/></div>
                        </div>

                        <div className="col-md-6   ">
                            <div className="customer-content  text-white ">
                                <h6>CUSTOMER SUPPORT</h6>
                                <h5>Can't find your answer?</h5>
                                <p>We're here to help! From general communication to <br /> specific ReviewReel
                                    platform questions, please reach <br /> out so we can assist
                                </p>
                                <a className="btn-change7" target="_blank" href="https://support.vineasx.com/">CONTACT OUR HELPDESK</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default CustomerSupport
