import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { baseURL } from '../../global/global';
import SweetAlert from "react-bootstrap-sweetalert";
import { setAlert } from "../../actions/alert";
import fevicon_img from "../../images/fvb.png"
import SocialIcons from "./SocialIcons";
import syvidIcon from "../../images/logo-syvideo.svg";
import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/youtube-active.png"
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import facebookDisable from "../../images/FB_DISABLE.png";
import youtubeDisable from "../../images/YT_DISABLE.png";
import syvidDisable from "../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../images/SCRIPT_DISABLE.png";
import trimDisable from "../../images/TRIM_DISABLE.png";
import axios from "axios";
import closeModalx from "../../images/closeModal.svg"


let intVideo;
const Render = ({ curElem, fetchProject }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const socials = useSelector(state => state.social)

    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');
    const [socialData, setSocialData] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [currSocial, setCurrSocial] = useState([]);
    const [selectIcon, setSelectIcon] = useState(false);
    const [loader, setLoader] = useState(false)

    const [progress, setProgress] = useState({
        status: curElem.status,
        slide: {
            renderProgress: 0,
        }
    })
    const [campiId, setCampId] = useState({
        id: false,
        name: ''
    })
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: curElem.outputVideo,
        integration_id: '',
        type: '',
        network: ''
    })

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (socialData === false) {
            setSocialData(socials)
        }
    }, [socials]);

    const handleDelete = (id, name) => {
        setCampId({
            ...campiId,
            id: id,
            name: name
        })
        setShowDelete(true);
    }
    const checkVideoProgress = (id) => {
        const data = { "campaiginId": id }
        axios({
            method: "POST",
            url: `${baseURL}project-status-check`,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                if (res.data.data.status === "1") {
                    setProgress({
                        ...progress,
                        status: res.data.data.status,
                        slide: {
                            ...progress.slide,
                            renderProgress: res.data.data.slide.renderProgress
                        }
                    })
                }
                else {
                    setProgress({
                        ...progress,
                        status: res.data.data.status
                    })
                }
                if (res.data.data.status === "3" || res.data.data.status === "2") {
                    removeInterval();
                }

            } else {
                clearInterval(intVideo);
            }

        }).catch(err => {
            console.log(err)
            clearInterval(intVideo);
        })

    }

    const currentSocial = (social, type, icon, url) => {
        if (social.length > 0) {
            setCurrSocial(social);
            setSelectIcon(icon);
            setPostData({
                ...postData,
                type: type,
                integration_id: social[0].id,
                network: icon,
            });
        }
    }

    const removeInterval = () => {
        clearInterval(intVideo);
        fetchProject(0);
    }


    useEffect(() => {
        if (progress.status === "1" || progress.status === "4" ||
            progress.status === "5" || progress.status === "6" ||
            progress.status === "7" || progress.status === "8") {

            intVideo = setInterval(() => {
                checkVideoProgress(curElem.campaignId);
            }, 5000)

        }
    }, []);


    const onConfirm = () => {
        if (campiId.id) {
            setButton('Deleting...');
            axios({
                method: "POST",
                url: `${baseURL}delete-campaign`,
                data: { id: campiId.id },
                headers: { 'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem('state')).auth.token }
            }).then((res) => {
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, "success"))
                    fetchProject(0)
                }
                else {
                    dispatch(setAlert(res.data.message, "danger"))
                }
                setButton('Delete');
                setShowDelete(false)
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}${postData.type}`,
            data: postData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'))
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setShowModel(false);
            setLoader(false);
        }).catch(err => {
            setShowModel(false);
            setLoader(false);
        })
    }

    return (
        <>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="video-card">
                    <div className="video-img">
                        {progress.status === "0" ?
                            <div className="card-wrapper-2 " style={{
                                width: "100%",
                                height: '192px',
                                backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                borderRadius: "10px",
                            }}>
                                <div className="icon-img text-center">
                                    <img src={fevicon_img} alt="" className="img-fluid"
                                        style={{
                                            width: '42px',
                                            height: '100%',
                                            marginTop: '26px',

                                        }} />
                                    <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Awaiting Customization</p>
                                </div>
                            </div> :
                            progress.status === "1" ?
                                <div className="card-wrapper-3 " style={{
                                    width: "100%",
                                    height: '192px',
                                    backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                    borderRadius: "10px",
                                }}>
                                    <div className="icon-img text-center">
                                        <img src={fevicon_img} alt="" className="img-fluid"
                                            style={{
                                                width: '42px',
                                                height: '100%',
                                                marginTop: '26px',

                                            }} />
                                        {progress !== false && progress.slide.renderProgress !== "" && progress.slide !== undefined ?
                                            <>
                                                {progress.slide.renderProgress === "awatingRender" ?
                                                    <p className="md-txt font-italic mb-0 text-light mt-3 mb-2" style={{ fontWeight: "500" }}>Awaiting Rendering</p>
                                                    :
                                                    <>
                                                        <p className="md-txt font-italic mb-0 text-light mt-3 mb-2" style={{ fontWeight: "500" }}>Rendering</p>
                                                        <div className="progress mt-3" style={{ margin: "0 5px" }}>
                                                            <div className="progress-bar progress-bar-striped progress-bar-animated  text-center" role="progressbar" aria-valuenow={Number((parseInt(progress.slide.renderProgress > 100 ? 100 : progress.slide.renderProgress)).toFixed(0))} aria-valuemin="0" aria-valuemax="100" style={{ width: parseInt(progress.slide.renderProgress) + '%' }}>{parseInt(progress.slide.renderProgress)}%</div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            :
                                            ""
                                        }

                                    </div>

                                </div> :
                                progress.status === "2" ? <div className="card-wrapper" style={{
                                    width: "100%",
                                    height: '192px',
                                    borderRadius: "10px",
                                }}>
                                    <img
                                        src={curElem.outputVideoThumb !== '' ? curElem.outputVideoThumb : fevicon_img}
                                        alt=""
                                        style={{
                                            borderRadius: "10px",
                                        }}
                                    />

                                </div> :
                                    progress.status === "3" ?
                                        <div className="card-wrapper " style={{
                                            width: "100%",
                                            height: '192px',
                                            backgroundImage: "linear-gradient(rgb(255, 126, 115) -11%, rgb(201, 6, 24) 39%, rgb(162, 0, 15) 59%, rgb(162, 0, 15) 78%)",
                                            borderRadius: "10px",
                                        }}>
                                            <div className="icon-img text-center">
                                                <img src={fevicon_img} alt="" className="img-fluid"
                                                    style={{
                                                        width: '42px',
                                                        height: '100%',
                                                        marginTop: '26px',
                                                    }} />
                                                <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Rendering Failed</p>
                                            </div>

                                        </div> :
                                        progress.status === "4" ? <div className="card-wrapper-4 " style={{
                                            width: "100%",
                                            height: '192px',
                                            backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                            borderRadius: "10px",
                                        }}>
                                            <div className="icon-img text-center">
                                                <img src={fevicon_img} alt="" className="img-fluid"
                                                    style={{
                                                        width: '42px',
                                                        height: '100%',
                                                        marginTop: '26px',
                                                    }} />
                                                <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Download Resource</p>
                                            </div>

                                        </div> :
                                            progress.status === "5" ? <div className="card-wrapper-4 " style={{
                                                width: "100%",
                                                height: '192px',
                                                backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                                borderRadius: "10px",
                                            }}>
                                                <div className="icon-img text-center">
                                                    <img src={fevicon_img} alt="" className="img-fluid"
                                                        style={{
                                                            width: '42px',
                                                            height: '100%',
                                                            marginTop: '26px',
                                                        }} />
                                                    <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>In Queue</p>
                                                </div>

                                            </div> :
                                                progress.status === "6" ? <div className="card-wrapper-4 " style={{
                                                    width: "100%",
                                                    height: '192px',
                                                    backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                                    borderRadius: "10px",
                                                }}>
                                                    <div className="icon-img text-center">
                                                        <img src={fevicon_img} alt="" className="img-fluid"
                                                            style={{
                                                                width: '42px',
                                                                height: '100%',
                                                                marginTop: '26px',
                                                            }} />
                                                        <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Merging Slides</p>
                                                    </div>

                                                </div> : progress.status === "7" ? <div className="card-wrapper-4 " style={{
                                                    width: "100%",
                                                    height: '192px',
                                                    backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                                    borderRadius: "10px",
                                                }}>
                                                    <div className="icon-img text-center">
                                                        <img src={fevicon_img} alt="" className="img-fluid"
                                                            style={{
                                                                width: '42px',
                                                                height: '100%',
                                                                marginTop: '26px',
                                                            }} />
                                                        <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Adding Music</p>
                                                    </div>

                                                </div> : progress.status === "8" ? <div className="card-wrapper-4 " style={{
                                                    width: "100%",
                                                    height: '192px',
                                                    backgroundImage: "linear-gradient(rgb(1,220,188) 11%, rgb(11,185,254,255) 39%,  rgb(11,185,254,255) 78%)",
                                                    borderRadius: "10px",
                                                }}>
                                                    <div className="icon-img text-center">
                                                        <img src={fevicon_img} alt="" className="img-fluid"
                                                            style={{
                                                                width: '42px',
                                                                height: '100%',
                                                                marginTop: '26px',
                                                            }} />
                                                        <p className="md-txt font-italic mb-0 text-light mt-4" style={{ fontWeight: "500" }}>Uploading To Cloud</p>
                                                    </div>

                                                </div> : ''
                        }
                        <div className="ClipsReel-overlay">
                            <p className="mt-4 text-center text-white">{curElem.created}</p>
                            <ul className="list-inline m-0 px-2 d-flex justify-content-around">
                                <li className="text-center">
                                    <Link to={`/editor?id=${curElem.campaignId}`}>
                                        <span ><i className="far fa-edit"></i></span>Edit
                                    </Link>
                                </li>
                                {curElem.outputVideo !== "" && curElem.status === "2" ?
                                    <li className="text-center">
                                        <a href={`${curElem.outputVideo}`}>
                                            <span><i className="far fa-arrow-alt-circle-down"></i></span>
                                            Download
                                        </a>
                                    </li> : ''}
                                {curElem.outputVideo !== "" && curElem.status === "2" ?
                                    <li className="text-center" onClick={() => setShowModel(true)}>
                                        <a href="#">
                                            <span><i className="fas fa-cloud-upload-alt"></i></span>
                                            Publish
                                        </a>
                                    </li>
                                    :
                                    ''}
                                <li className="text-center" onClick={() => handleDelete(curElem.campaignId, curElem.campaingName)}>
                                    <a href="#"><span><i className="far fa-trash-alt"></i></span>
                                        Delete
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="video-title">
                        <div className="row">
                            <div className="col-md-10 col-10 d-flex align-items-center">
                                <div className="video-name  text-white">
                                    <p className="m-0">{curElem.campaingName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`modal ${showModel ? 'show-modal' : ''} `}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h5 className="modal-title" style={{ width: "100%" }}>Publish This Video</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => setShowModel(false)}
                                    aria-label="Close">
                                    <span aria-hidden="true">
                                        <img src={closeModalx} alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">

                                        <div className="icon-response">
                                            <ul className="list-inline d-flex  flex-wrap justify-content-center">
                                                <div
                                                    onClick={() => currentSocial(socialData.facebook, 'upload-to-facebook', 'facebook', curElem.url)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.facebook.length > 0 ? facebookIcon : facebookDisable}
                                                        status={socials.facebook.length > 0 ? true : false}
                                                        name="Facebook"
                                                        selectIcon={selectIcon === "facebook" ? "select-social" : ''}

                                                    />
                                                </div>
                                                <div
                                                    onClick={() => currentSocial(socialData.youtube, 'upload-to-youtube', 'youtube', curElem.url)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.youtube.length > 0 ? youtubeIcon : youtubeDisable}
                                                        status={socials.youtube.length > 0 ? true : false}
                                                        name="Youtube"
                                                        selectIcon={selectIcon === "youtube" ? "select-social" : ''}


                                                    />
                                                </div>
                                                <div
                                                    onClick={() => currentSocial(socialData.syvid, 'push-to-app', 'syvid', curElem.url)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.syvid.length > 0 ? syvidIcon : syvidDisable}
                                                        status={socials.syvid.length > 0 ? true : false}
                                                        name="Syvid"
                                                        selectIcon={selectIcon === "syvid" ? "select-social" : ''}

                                                    />
                                                </div>
                                                <div
                                                    onClick={() => currentSocial(socialData.scriptreel, 'push-to-app', 'scriptreel', curElem.url)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.scriptreel.length > 0 ? scriptIcon : scriptDisable}
                                                        status={socials.scriptreel.length > 0 ? true : false}
                                                        name="ScriptReel"
                                                        selectIcon={selectIcon === "scriptreel" ? "select-social" : ''}

                                                    />
                                                </div>
                                                <div
                                                    onClick={() => currentSocial(socialData.trimreel, 'push-to-app', 'trimreel', curElem)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.trimreel.length > 0 ? trimIcon : trimDisable}
                                                        status={socials.trimreel.length > 0 ? true : false}
                                                        name="TrimReel"
                                                        selectIcon={selectIcon === "trimreel" ? "select-social" : ''}

                                                    />
                                                </div>
                                                <div
                                                    onClick={() => currentSocial(socialData.livereel, 'push-to-app', 'livereel', curElem.url)}
                                                >
                                                    <SocialIcons
                                                        icon={socials.livereel.length > 0 ? liveIcon : liveDisable}
                                                        status={socials.livereel.length > 0 ? true : false}
                                                        name="LiveReel"
                                                        selectIcon={selectIcon === "livereel" ? "select-social" : ''}
                                                    />
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-body">
                                    <form className="gdpr-update-email" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Title</small>
                                            <input type="text" className="form-control" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter Title" />
                                        </div>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Description</small>
                                            <textarea className="form-control" required name="description" onChange={(e) => onInputChange(e)} placeholder="Enter Description" />
                                        </div>
                                        <div className="form-group">
                                            <small className="form-text text-muted">Account Type</small>
                                            <select
                                                className="form-control"
                                                required name="integration_id"
                                                onChange={(e) => onInputChange(e)}
                                            >
                                                <option disabled selected>select account</option>
                                                {
                                                    currSocial.length > 0 ?
                                                        currSocial.map((item, index) => {
                                                            return (
                                                                <option key={index} style={{ backgroundColor: "#000" }} value={item.id}>{item?.username}</option>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn-custom btn-change7" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Publish
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Campaign "{campiId.name}"
            </SweetAlert>
        </>
    );
}

export default Render;
