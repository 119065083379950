import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import ConnectFacebook from "./ConnectFacebook";
import ConnectYoutube from "./ConnectYoutube";
import ConnectReelapps from "./ConnectReelapps";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import Webinar from "../dashboard/Webinar";
import { useSelector } from "react-redux";
import sonorityIcon from "../../images/logo-sonority.png";
import syvidDisable from "../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../images/SCRIPT_DISABLE.png";
import sonorityDisable from "../../images/SONORITY_DISABLE.png";
import trimDisable from "../../images/TRIM_DISABLE.png";
import videoIcon from "../../images/videoIcon.png";
import videoDisable from "../../images/videoDisable.png";
import Title from "../Title";

const Integration = () => {

    const socialData = useSelector(state => state.social);

    return (
        <>
            <Title title="Integration" />
            <Navbar />

            <div className="warraper">
                <IntegrationHead />

                <section className="my-connection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-white">My Connection</h4>
                            </div>
                        </div>

                        <ConnectFacebook data={socialData.facebook} />
                        <ConnectYoutube data={socialData.youtube} />
                        <ConnectReelapps icon={socialData.syvid.length > 0 ? syvidIcon : syvidDisable} name="Syvid" type="syvid" data={socialData.syvid} />
                        <ConnectReelapps icon={socialData.scriptreel.length > 0 ? scriptIcon : scriptDisable} name="ScriptReel" type="scriptreel" data={socialData.scriptreel} />
                        <ConnectReelapps icon={socialData.trimreel.length > 0 ? trimIcon : trimDisable} name="TrimReel" type="trimreel" data={socialData.trimreel} />
                        <ConnectReelapps icon={socialData.livereel.length > 0 ? liveIcon : liveDisable} name="LiveReel" type="livereel" data={socialData.livereel} />
                        <ConnectReelapps icon={socialData.sonority.length > 0 ? sonorityIcon : sonorityDisable} name="Sonority" type="sonority" data={socialData.sonority} />
                        <ConnectReelapps icon={socialData.videoreel.length > 0 ? videoIcon : videoDisable} name="VideoReel" type="videoreel" data={socialData.videoreel} />

                    </div>
                </section>

                <Webinar />
            </div>

            <Footer />
        </>
    )
}

export default Integration;