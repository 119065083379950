import styled from "styled-components";
export const Bonusdedicate5style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }
  .video-player{
    width:160px!important;
    max-height:100%;
  }
  .product-wrappers {
    height: 132px;
    position: absolute;
    top: 94px;
    right: 35px;
    z-index: 1;
  }
 
 .product-wrappers-2
  {
    height: 146px;
    position: absolute;
    top: 89px;
    right: 48px;
    z-index: 1;
  }
 

 .product-wrappers img{
   max-height:100%
 }

 .price-tag{
    width: 110px;
    height: 47px;
    border: 2px solid red;
    background: red;
    position: absolute;
    border-radius: 10px;
    top: 40px;
 }
 .price-value{
    width: 110px;
    position: absolute;
    top: 9px;
    right: 3px;
    letter-spacing: 2px;
   
 }

 .head-box {
    width: 100%;
    position: absolute;
    top: 113px;
    right: 19px;
    padding-left: 10px;
  }

.text-wrappers {
  width:100%;
  height:auto;
  position: absolute;
  right: 22px;
  top: 232px;
  display:flex;
  padding-left: 10px;
}
.text-wrappers p {
  width:100%;
  height:auto;
  flex-wrap:wrap;
}


@media (max-width: 1199.98px) { 
    .text-wrappers {
        top: 200px;
    }
    .product-wrappers {
       height: 95px;   
       position: absolute;
       top: 90px;
       right: 39px;
    }
    .product-wrappers-2{
      height: 110px;
      position: absolute;
      top: 80px;
      right: 48px;
      z-index: 1;
    }
    .head-box {
        width: 100%;
        position: absolute;
        top: 67px;
        right: 19px;
        padding-left: 10px;
    }
    .price-tag {
        width: 98px;
        height: 40px;
        border: 2px solid red;
        background: red;
        position: absolute;
        border-radius: 10px;
        top: 13px;
    }
    .price-value {
        width: 98px;
        position: absolute;
        top: 6px;
        right:-2px;
    }


}


`;