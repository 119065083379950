import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { setSelectedTextStatus } from '../../../actions/videoAction'
import { useDispatch, useSelector } from 'react-redux'

const MediaEditor = (props) => {

  const dispatch = useDispatch()
  const slides = useSelector(state => state.videos.data.slides)

  const selectedSlideIndex = slides.findIndex(({ is_selected }) => is_selected === true)
  const selectedTextIndex = slides[selectedSlideIndex].text.findIndex(({ is_selected }) => is_selected === true)

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    props.setSelectImageLayer(false)
  }, [selectedSlideIndex])

  const handleMedia = (type, layerId) => {
    if (type === "pImage") {
      props.setLayer({
        ...props.layer,
        image: true,
        text: false,
        bgImage: false
      })
      props.setSelectImageLayer(true)
      if (layerId !== undefined) {
        dispatch(setSelectedTextStatus(layerId, selectedSlideIndex, selectedTextIndex))
      }
    }
    else if (type === "bgImage") {
      props.setLayer({
        ...props.layer,
        image: false,
        text: false,
        bgImage: true
      })
      props.setSelectImageLayer(false)
    }
  }

  useEffect(() => {
    setLoader(true)
  }, [props.data]);



  return (
    <>
      {props.type === "video" ?
        <>
          {loader ?
            <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}}>
              <i className="fa fa-spinner fa-spin mr-2" style={props.mediaType === "bgImage" ? { fontSize: "50px" } : {}} />
            </div> : ''}
          <ReactPlayer
            url={props.data}
            playing={true}
            loop={true}
            className={` ${props.mediaType === "pImage" ? 'video-player' : ''}`}
            onClick={() => handleMedia(props.mediaType, props.layerId)}
            onPlay={() => setLoader(false)}
            muted
            style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}

          />
        </>
        :
        <>
          {loader ?
            <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}} >
              <i className="fa fa-spinner fa-spin mr-2" style={props.mediaType === "bgImage" ? { fontSize: "50px" } : {}} />
            </div> : ''}
          <img
            src={props.data}
            alt=""
            onClick={() => handleMedia(props.mediaType, props.layerId)}
            onLoad={() => setLoader(false)}
            style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}
          />
        </>
      }
    </>
  )
}

export default MediaEditor