const initialState = {
    varient: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'ADD_TEMPLATE_VARIENTS':
            return {
                ...state,
                varient: action.payload
            }
        default:
            return state
    }
}