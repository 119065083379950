import styled from "styled-components";
export const Conclusion3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }

 .head-box{
    position: absolute;
    top: 2px;
    max-height: 100%;
    width: 80px;
  }

  h1{
    text-transform: uppercase;
    line-height: 1.1;
    font-size: 32px;
    font-weight:bold;
    pointer-event:none;
  }

  p{
    margin-bottom:0px; 
   }

  .text-wrappers{
    padding-top: 25px;
    height: auto;
    width: 86%;
    margin-left: 45px
  }

  .text-wrappers p,.text-wrappers-2 p {
    padding:8px;
    width:100%;
    max-height:100%;
    overflow:hidden;
  }

 .background-color{
   width: 520px;
    background-color: #000;
    clip-path: circle(75.9% at 66% 50%);
    height: 360px;
    position: absolute;
    top: 0;
 }
  

  .text-wrappers-2{
    padding-top:25px;
    margin-left: 43px;
    width: 86%;
  }

@media (max-width: 1199.98px) { 
  .head-box{
    width:30px;
  }
  
  .text-wrappers {
    padding-top: 25px;
    height: auto;
    width: 73%;
  }

  .text-wrappers-2 {
    padding-top:25px;
    height: auto;
    width: 73%;
    overflow: hidden;
  }

 .text-wrappers p,.text-wrappers-2 p {
  width: 100%;
  height: 100%;
  overflow:hidden;
 }

 h1 {
  text-transform: uppercase;
  line-height: 1;
  font-size: 30px;
  left:21px;
}
   
}


`;