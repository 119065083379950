import styled from "styled-components";
export const Props_Cons4style = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&display=swap');
*{box-sizing: border-box;}

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
    max-height: 100%;
   
  }
.template-preview {
    width:100%;
    position: absolute;
    top:0;
}
.box-1{
    width: 213px;
    height: 150px;
    background: #139148;
    position: absolute;
    top: 25px;
    left: 18px;
    display:flex;
    justify-content:space-evenly;
}

.thumb{
    height: 66px;
    width: 34px;
    color: #fff;
}
.thumb-2{
    height: 66px;
    width: 34px;
    color: #fff;
    padding-top: 15px;
}

.box-1 span{
   font-size:30px;
   padding-top:10px;
   font-family: 'Lato', sans-serif;
   color:#fff;
   font-weight:800;
}

.pros-img{
    padding-top: 22px;
}
.box-2{
    position: absolute;
    top: 80px;
}
 .box-2 svg{
    width: 245px;
    height: 270px;
}
.box-4 svg{
    width: 245px;
    height: 270px;
}
.box-3{
    width: 213px;
    height: 150px;
    background: #F34E4E;
    position: absolute;
    top: 25px;
    display:flex;
    justify-content:space-evenly;
    right: 30px;
}

.box-3 span{
    font-size:30px;
    padding-top:13px;
    font-family: 'Lato', sans-serif;
    color:#fff;
    font-weight:800;
 }
 .cons-img{
     width:30px;
 }
 
 .cons{
     padding-top: 22px;
 }


.box-4{
    position: absolute;
    top: 80px;
    right: 8px;
}
.box-text-wrapper{
    position: absolute;
    top: 45px;
    left: 16px;
}
.box-text-wrapper-2{
    position: absolute;
    top: 45px;
    left: 16px;
}
.text-content{
    width:223px;
    margin-top:23px
}

@media (max-width: 1199.98px){

  .box-1 {
    width: 213px;
    height: 100px;
    position: absolute;
    top: 11px;
  }

  .thumb {
    height: 64px;
    width: 27px;
    color: #fff;
    margin-top: -4px;
  }
   .box-1 span {
        font-size: 25px;
        padding-top: 11px;
    }

 .box-2 {
    position: absolute;
    top: 62px;
  }

   .box-2 svg{
    width: 235px;
    height: 234px;
  } 

 .box-4 svg {
    width: 235px;
    height: 234px;
 }

 .box-3 {
    width: 213px;
    height: 100px;
    position: absolute;
    top: 11px;
    right: 20px;
 }
 
 .box-3 span {
    font-size: 25px;
    padding-top: 11px;
  }
 
  
 .thumb-2 {
    height: 64px;
    width: 27px;
    padding-top: 4px;
  }

 
 .box-4 {
    position: absolute;
    top: 62px;
  }





}

`;