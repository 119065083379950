import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/LOGO.png";
import loginBg from "../../images/BG.png";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import Title from "../Title";
import { RiLockLine } from "react-icons/ri";

const ResetPassword = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: "",
        confirm_password: "",
        password_token: queryString.parse(location.search).token,
    });
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false,
    });

    const validatePassword = (password) => {
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (!pattern.test(password)) {
            return "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long.";
        }
        return "";
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });

        if (passwordMsg.validation) {
            setPasswordMsg({ msg: "", validation: false });
        }

        // Validate password on input change
        if (name === "password") {
            const validationMessage = validatePassword(value);
            if (validationMessage) {
                setPasswordMsg({ msg: validationMessage, validation: true });
            } else {
                setPasswordMsg({ msg: "", validation: false });
            }
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        if (!password.password || !password.confirm_password) {
            setPasswordMsg({ msg: "All fields are required.", validation: true });
            setLoader(false);
            return;
        }

        const validationMessage = validatePassword(password.password);
        if (validationMessage) {
            setPasswordMsg({ msg: validationMessage, validation: true });
            setLoader(false);
            return;
        }

        if (password.password !== password.confirm_password) {
            setPasswordMsg({ msg: "Passwords do not match!", validation: true });
            setLoader(false);
            return;
        }

        dispatch(resetPassword(password, setLoader, history));
    };

    return (
        <>
            <Title title="Reset Password" />
            <Alert />
            <section className="logoimg" style={{ backgroundImage: `url(${loginBg})` }}>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="login-box">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center">
                                <div className="login-content text-white">
                                    <div className="login-head-text">
                                        <span className="welcome-text">WELCOME TO</span><br />
                                        <h1>ReviewReel</h1>
                                    </div>
                                    <div className="login-head-para">
                                        <p>Instantly create impactful, engaging, and ready-to-upload product video reviews in any niche using ReviewReel’s powerful A.I. that does all the work for you.</p>
                                    </div>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="login-form text-white" onSubmit={onFormSubmit}>
                                    <h4><span>Reset</span> Password</h4>
                                    <div className="form-group mt-4">
                                        <label>New Password</label>
                                        <div className="form-group2">
                                            <span className="mail-2"><RiLockLine /></span>
                                            <span className="line"></span>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control side-part"
                                                placeholder="************"
                                                required
                                                onChange={onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <div className="form-group2">
                                            <span className="mail-2"><RiLockLine /></span>
                                            <span className="line"></span>
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <p style={{ fontSize: 13, margin: "5px", color: "white" }}>
                                        {passwordMsg.validation ? passwordMsg.msg : ""}
                                    </p>
                                    <button type="submit" className="form-control btn-change6 mt-4" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                    </button>
                                    <div className="forgot text-center mt-4">
                                        <Link to="/login" className="text-center">
                                            Login ? <span>Click here</span>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <LoginFooter />
            </section>
        </>
    );
}

export default ResetPassword;
