import styled from "styled-components";
export const Bonusdedicate3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
  }
  .video-player{
    width:178px!important;
    max-height:100%;
  }
  
  .video-player video{
    object-fit:unset;
  }

.product-wrappers {
    height: 150px;
    position: absolute;
    top: 145px;
    left: 45px;
    z-index: 1;
  }

  .product-wrappers img{
    max-height:100%
  }
  .price-tag{
    position: absolute;
    top: -18px;
    left: -30px;
    width: 150px;
   
  }
  .price-value{
      position: absolute;
      top: 98px;
      left: 42px;
  }

.product-intro{
    position: absolute;
    left: 0;
    top: 110px;
    width: 100%;
}


.text-wrappers {
  width: 100%;
}

.text-wrappers p {
  margin-left: 6px;
}


@media (max-width: 1199.98px) { 
  
    .text-wrappers {
        width: 250px;
        top: 120px;
    }
    
    .product-wrappers {
        height: 110px;
        position: absolute;
        top: 153px;
        left: 36px;
    }
    .video-player{
        width:150px!important;
        max-height:100%;
     }

}


`;