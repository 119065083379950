import React, { useState } from 'react';
import { Description5style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        < Description5style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    // data={Description2}
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                // type='image'
                />
                <div className="template-preview-background"></div>
                    <div className="template-preview">
                        <div className="container">
                            <div className="row">



                                <div className="col-sm-6">

                                    <div className="head-box">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[0].is_selected}
                                            textStatus={props.templateText[0].text.is_selected}
                                            layerId={props.templateText[0].id}
                                            text={props.templateText[0].text.text}
                                            style={props.templateText[0].text.style}
                                            setTextLength={props.setTextLength}
                                            setMaxLimit={props.setMaxLimit}
                                            templateNumber={props.templateNumber}
                                            // maxLimit={props.textVarient.length > 0 ?  props.textVarient[0].maxLimit : 0}
                                            blockType={
                                                {
                                                    tag: 'h1',
                                                }

                                            }
                                        />
                                    </div>
                                    <div className="text-wrappers">
                                        <TextEditor
                                            layer={layer}
                                            setLayer={setLayer}
                                            layerStatus={props.templateText[1].is_selected}
                                            textStatus={props.templateText[1].text.is_selected}
                                            layerId={props.templateText[1].id}
                                            text={props.templateText[1].text.text}
                                            style={props.templateText[1].text.style}
                                            setTextLength={props.setTextLength}
                                            templateNumber={props.templateNumber}
                                            setMaxLimit={props.setMaxLimit}
                                            // maxLimit={props.textVarient.length > 0 ?  props.textVarient[1].maxLimit : 0}
                                            blockType={
                                                {
                                                    tag: 'p',
                                                }

                                            }
                                        />

                                    </div>

                                </div>
                                <div className="col-sm-6">
                                    <div className={` ${layer.image ? 'image-bg-border' : ''} ${props.productImage.toLowerCase().includes(".png")?"product-wrappers":"product-wrappers-2"}`}>
                                        <MediaEditor
                                            // data={Product_Description2}
                                            layer={layer}
                                            setLayer={setLayer}
                                            data={props.productImage}
                                            mediaType="pImage"
                                            myStyle={{
                                                width: '100%',
                                            }}
                                            type={props.productImage.includes("mp4") ? 'video' : 'image'}
                                            setSelectImageLayer={props.setSelectImageLayer}
                                        // type='image'
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                {/* </div> */}
            </div>

        </ Description5style >

    );

};
export default Template;