import styled from "styled-components";
export const Bonuses2style = styled.div`
.template-wrapper {
   width: 100%;
   height: 100%;
   position: absolute;
   top:0;
   text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top: 0px;
    margin-left: -20px;
}
.head-box h1{
   color:#fff;
   font-size:28px;
}

.text-content{
  display: flex;
  justify-content:center;
  align-items: center;
  margin-bottom: 8px;
}

.text-wrappers-1{
   border-radius: 40px;
    width: 400px;
    padding-top: 5px;
    padding-left: 5px;
    height: 56px;
    position: absolute;
    top: -26px;
    left: -7px;
    background: #fff;
}
.text-wrappers-2{
    border-radius: 40px;
    width: 400px;
    padding-top: 5px;
    padding-left: 5px;
    height: 56px;
    position: absolute;
    top: 47px;
    left: 66px;
    background: #fff;
}
.text-wrappers-3{
    border-radius: 40px;
    width: 400px;
    padding-top: 5px;
    padding-left: 5px;
    height: 56px;
    position: absolute;
    top: 117px;
    left: 144px;
    background: #fff;
}

.text-wrappers-4{
    border-radius: 40px;
    width: 400px;
    padding-top: 5px;
    padding-left: 5px;
    height: 56px;
    position: absolute;
    left: 218px;
    top: 185px;
    background: #fff;
}
.shapes {
    position: absolute;
    top: -60px;
    right: -4px;
}    

.text-value {
    padding-top: 25px;
    width: 75px;
}
    
.circle{
    width: 74px;
    height: 72px;
    background: red;
}


p{
  margin-bottom:0px!important;
  padding:0 5px;
}

.Bonuses-list {
    position: absolute;
    top: 69px;
    left: 12px;
}

@media (max-width: 1199.98px) { 
    
.head-box {
    margin-top: -3px;
}

.text-wrappers-1{
   width: 322px;
    height: 52px;
    top: 4px;
    left: -7px;
}
 .text-wrappers-2{
    width: 322px;
    height: 52px;
    top:61px;
    left: 68px;
 }
 .text-wrappers-3{
    width: 322px;
    height: 52px;
    top: 117px;
    left: 136px;
 }
 
 .text-wrappers-4{
    width: 322px;
    height: 52px;
    left: 207px;
    top: 174px
 }
 .text-value{
   padding-top:10px;
 }
 .Bonuses-list {
    position: absolute;
    top: 33px;
 }
.shapes {
    position: absolute;
    top: -46px;
}
.circle {
    width: 62px;
    height: 60px;
}
.text-content{
    margin-bottom: 15px;
  }
}

`;