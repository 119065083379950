import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import WebFont from "webfontloader";
import {
  removeFontBgColor,
  updateFontBgColor,
  updateFontColor,
  updateFontFamily,
  updateFontSize, updateTextAlign,
  updateTextBold,
  updateTextItalic, updateTextLineHeight,
  updateTextLower,
  updateTextStrike,
  updateTextUnderline,
  updateTextUpper,
  applyToAllSlide
} from "../../actions/videoAction";
import hexRgb from "hex-rgb";
import { SketchPicker } from "react-color";
import rgbHex from "rgb-hex";




const TextStyle = ({ slideData, fonts, setFonts }) => {

  const dispatch = useDispatch();

  const selectedSlide = slideData.find(({ is_selected }) => is_selected === true)
  const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)

  const [googleFonts, setGoogleFonts] = useState({ data: [] });
  const [slideAll, setSlideAll] = useState(false);

  const [selectedText, setSelectedText] = useState(false)
  const [selectedTextIndex, setSelectedTextIndex] = useState(false)

  const [textColor, setTextColor] = useState({
    enable: false,
    r: 0,
    g: 0,
    b: 0
  });
  const [showBgColor, setShowBgColor] = useState({
    enable: false,
    r: 0,
    g: 0,
    b: 0
  });




  const [styleState, setStyleState] = useState({
    fontColor: false,
    fontFamily: "",
    bgColor: "",
    fontSize: 40,
    align: "center",
    bold: false,
    italic: false,
    upperCase: false,
    lowerCase: false,
    underline: false,
    strike: false,
    lineHeight: 1
  })


  useEffect(() => {
    if (slideData) {
      setSelectedText(slideData[selectedSlideIndex].text.find(({ is_selected }) => is_selected === true))
      setSelectedTextIndex(slideData[selectedSlideIndex].text.findIndex(({ is_selected }) => is_selected === true))
    }
  }, [slideData, selectedSlideIndex])

  useEffect(() => {

    if (selectedText !== false && slideData[selectedSlideIndex].text.length > 0 && selectedText !== undefined) {
      if (selectedText.heading !== undefined) {
        if (selectedText.heading.is_selected === true) {
          setStyleState((selectedText.heading.style))
        }
      }
      if (selectedText.name !== undefined) {
        if (selectedText.name.is_selected === true) {
          setStyleState((selectedText.name.style))
        }
      }
      if (selectedText.designation !== undefined) {
        if (selectedText.designation.is_selected === true) {
          setStyleState((selectedText.designation.style))
        }
      }
      if (selectedText.value !== undefined) {
        if (selectedText.value.is_selected === true) {
          setStyleState((selectedText.value.style))
        }
      }
      if (selectedText.text.is_selected === true) {
        setStyleState((selectedText.text.style))
      }
    }
  })


  useEffect(() => {
    if (styleState.fontColor !== false && styleState.fontColor !== "") {
      const val = hexRgb(styleState.fontColor)
      setTextColor({
        ...textColor,
        r: val.red,
        g: val.green,
        b: val.blue
      })
    }
    else {
      setTextColor({
        ...textColor,
        r: 0,
        g: 0,
        b: 0
      })
    }
  }, [styleState.fontColor])


  useEffect(() => {
    if (styleState.bgColor !== false && styleState.bgColor !== "") {
      const val = hexRgb(styleState.bgColor)
      setShowBgColor({
        ...showBgColor,
        r: val.red,
        g: val.green,
        b: val.blue
      })
    }
    else {
      setShowBgColor({
        ...showBgColor,
        r: 0,
        g: 0,
        b: 0
      })
    }
  }, [styleState.bgColor])

  const applyChanges = () => {
    if (slideAll === false) {
      setSlideAll(true);
      dispatch(applyToAllSlide(styleState));
    }
  }

  const onInputChange = (e, type) => {
    setSlideAll(false);
    if (type === 'size') {
      dispatch(updateFontSize(e.target.value, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
    } else if (type === 'font_family') {
      dispatch(updateFontFamily(e.target.value, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
    }

  }

  const onInputColor = (e) => {
    setSlideAll(false);
    let rgbaColor = e.hex
    setTextColor({
      ...textColor,
      r: e.rgb.r,
      g: e.rgb.g,
      b: e.rgb.b
    })
    dispatch(updateFontColor(rgbaColor, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  const onInputBgColor = (e) => {
    let rgbaColor = e.hex

    setSlideAll(false);
    setShowBgColor({
      ...showBgColor,
      r: e.rgb.r,
      g: e.rgb.g,
      b: e.rgb.b
    })
    dispatch(updateFontBgColor(rgbaColor, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  // Getting hex color RGB number 
  const getHexNumbers = (color, type) => {
    let rgbArray = color.split("(")
    rgbArray = rgbArray[1].split(",")
    let splitB = rgbArray[2].split(")")

    const r = rgbArray[0]
    const g = rgbArray[1]
    const b = splitB[0]
    if (type === 'TEXT') {
      setTextColor({
        ...textColor,
        r: r,
        g: g,
        b: b
      })
    }
    else {
      setShowBgColor({
        ...showBgColor,
        r: r,
        g: g,
        b: b
      })
    }
  }
  const onChangeColor = (color) => {
    setSlideAll(false);
    getHexNumbers(color, 'TEXT')
    let hex = '#' + rgbHex(color);
    dispatch(updateFontColor(hex, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  const onChangeBgColor = (color) => {
    setSlideAll(false);
    getHexNumbers(color, 'BG')
    let hex = '#' + rgbHex(color);
    dispatch(updateFontBgColor(hex, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  const removeBgColor = () => {
    setSlideAll(false);
    setShowBgColor({
      ...showBgColor,
      r: 0,
      g: 0,
      b: 0
    })
    dispatch(removeFontBgColor(selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  const onTextAlign = (align) => {
    setSlideAll(false);
    dispatch(updateTextAlign(align, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
  }

  const onChangeLineHeight = (e) => {
    setSlideAll(false);
    dispatch(updateTextLineHeight(e.target.value, selectedSlideIndex, selectedTextIndex, selectedSlide.type))
  }

  const onTextStyle = (type) => {
    setSlideAll(false);
    if (type === 'bold') {
      if (styleState.bold === true) {
        dispatch(updateTextBold(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextBold(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    } else if (type === 'italic') {
      if (styleState.italic === true) {
        dispatch(updateTextItalic(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextItalic(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    } else if (type === 'uppercase') {
      if (styleState.upperCase === true) {
        dispatch(updateTextUpper(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextUpper(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    } else if (type === 'lowercase') {
      if (styleState.lowerCase === true) {
        dispatch(updateTextLower(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextLower(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    } else if (type === 'underline') {
      if (styleState.underline === true) {
        dispatch(updateTextUnderline(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextUnderline(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    } else if (type === 'strike') {
      if (styleState.strike === true) {
        dispatch(updateTextStrike(false, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      } else {
        dispatch(updateTextStrike(true, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
      }
    }
  }


  useEffect(() => {
    axios({
      method: 'POST',
      url: 'https://adminserver.reelapps.io/fetch-fonts',
      data: {},
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      if (res.data.status === true) {
        setFonts(res.data.data);
        let arr = res.data.data.map((item, index) => {
          return (
            item.value
          )
        })
        setGoogleFonts({ ...googleFonts, data: arr });
      }
    }).catch(err => {
      console.log(err)
    })
  }, [])
  useEffect(() => {
    if (googleFonts.data.length > 0) {
      googleFonts.data.forEach(element => {
        WebFont.load({
          google: {
            families: [element]
          }
        });
      });

    }
  }, [googleFonts.data])

  return (

    <div className="tabInner">
      {selectedText ?
        <div className="txt-format">
          <h2>Font Style</h2>
          <div className="fontStyle">
            <div className="fontStyle-block">
              <div className="row">
                <div className="col-8 ">
                  <select
                    classnameprefix='mySelect'
                    id="sel-font"
                    name="font_family"
                    className='cursor-pointer text-hover-effect'
                    onChange={(e) => onInputChange(e, 'font_family')} >
                    <option disabled selected>Select Font</option>
                    {
                      fonts.length > 0 ?
                        fonts.map((font, index) => {
                          return (
                            <option style={{ fontFamily: font.value }}
                              selected={font.value === styleState.fontFamily}
                              value={font.value} key={index}>{font.name}</option>
                          )
                        })
                        : ''
                    }
                  </select>
                </div>
                <div className="col-4" data-toggle="tooltip" title="Font Size">
                  <input
                    id="f-size"
                    type="number"
                    className="edit-input-font-size text-hover-effect"
                    name="f-size"
                    min="10"
                    value={styleState.fontSize}
                    onChange={(e) => onInputChange(e, 'size')}
                  />
                </div>
              </div>
            </div>
            <div className="fontStyle-block">
              <div className="row">
                <div className="col-2">
                  <div
                    className={`${styleState.bold === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect `}
                    onClick={() => onTextStyle('bold')}
                    data-toggle="tooltip"
                    title="Text Bold"
                  ><span className="font-weight-bold">T</span>
                  </div>

                </div>
                <div className="col-2">

                  <div
                    className={`${styleState.italic === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextStyle('italic')}
                    data-toggle="tooltip"
                    title="Text Italic"
                  ><span className="font-italic">T</span>
                  </div>

                </div>
                <div className="col-2">

                  <div
                    className={`${styleState.upperCase === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextStyle('uppercase')}
                    data-toggle="tooltip"
                    title="Text Uppercase"
                  ><span>TT</span></div>
                </div>

                <div className="col-2">

                  <div
                    className={`${styleState.lowerCase === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextStyle('lowercase')}
                    data-toggle="tooltip"
                    title="Text Lowercase"
                  ><span>tt</span></div>
                </div>

                {/* <div className="col-2">

                <div
                  className={`${styleState.underline === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                  onClick={() => onTextStyle('underline')}
                  data-toggle="tooltip"
                  title="Text Underline"
                ><span className="text-decoration-underline">T</span></div>
              </div>

              <div className="col-2">

                <div
                  className={`${styleState.strike === true ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                  onClick={() => onTextStyle('strike')}
                  data-toggle="tooltip"
                  title="Text Strike"
                ><span className="text-decoration-line-through">T</span></div>
              </div> */}

              </div>
            </div>
            <div className="fontStyle-block">
              <div className="row">
                <div className="col-2">

                  <div
                    className={`${styleState.align === 'left' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextAlign('left')}
                    data-toggle="tooltip"
                    title="Text Left"
                  ><i className="fa fa-align-left" aria-hidden="true"></i></div>
                </div>

                <div className="col-2">

                  <div
                    className={`${styleState.align === 'center' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextAlign('center')}
                    data-toggle="tooltip"
                    title="Text Center"
                  ><i className="fa fa-align-center" aria-hidden="true"></i></div>
                </div>

                <div className="col-2">

                  <div
                    className={`${styleState.align === 'right' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextAlign('right')}
                    data-toggle="tooltip"
                    title="Text Right"
                  ><i className="fa fa-align-right" aria-hidden="true"></i></div>
                </div>

                <div className="col-2">

                  <div
                    className={`${styleState.align === 'justify' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                    onClick={() => onTextAlign('justify')}
                    data-toggle="tooltip"
                    title="Text Justify"
                  ><i className="fa fa-align-justify" aria-hidden="true"></i></div>
                </div>

                <div className="col-4">

                  <div
                    className={'form-format'}
                    data-toggle="tooltip"
                    title="Text Line Height"
                  >
                    <input
                      id="t-lineheight"
                      className="edit-input-lineheight text-hover-effect"
                      type="number"
                      name="Lineheight"
                      value={styleState.lineHeight}
                      min="1"
                      step="0.1"
                      onChange={(e) => onChangeLineHeight(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fontStyle-block-wrap">
            <h2>Font Color</h2>
            <div className="fontStyle-block">
              <div className="row">
                <div className="col-6">
                  <div className="inpWrap">
                    <input
                      className="pl-5"
                      type="text"
                      value={styleState.fontColor}
                      readOnly
                    />
                    <span
                      className="colorValue cursor-pointer"
                      id="t-color-btn"
                      style={{ background: `${styleState.fontColor !== false ? styleState.fontColor : '#fff'}` }}
                      onClick={() => setTextColor({
                        ...textColor,
                        enable: true
                      })}
                    ></span>
                    <span className="label">HEX</span>

                    {textColor.enable ?
                      <>
                        <div className="swatch-cover" onClick={(e) => setTextColor({
                          ...textColor,
                          enable: false
                        })} />
                        <SketchPicker color={styleState.fontColor} onChange={(e) => onInputColor(e)} />
                      </> : null}

                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={textColor.r} readOnly />
                    <span className="label">R</span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={textColor.g} readOnly />
                    <span className="label">G</span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={textColor.b} readOnly />
                    <span className="label">B</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="color-palette">
              <ul>

                <li>
                  <div
                    style={{ background: "#FFF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(255, 255, 255)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#E1E1E1" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(225, 225, 225)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#D7D7D7" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(215, 215, 215)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#CCCCCC" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(204, 204, 204)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#C2C2C2" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(194, 194, 194)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#B7B7B7" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(183, 183, 183)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#A1A1A1" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(161, 161, 161)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#959595" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(149, 149, 149)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#E8C9FF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(232, 201, 255)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#C87EFF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(200, 126, 255)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#F34AFF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(243, 74, 255)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#D19DF8" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(209, 157, 248)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#9B33E9" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(155, 51, 233)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#8133DB" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(129, 51, 219)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#7033D5" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(112, 51, 213)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#23803F" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(35, 128, 63)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#54C423" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(84, 196, 35)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#606888" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(96, 104, 136)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#7C83AC" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(124, 131, 172)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#5B69BE" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(91, 105, 190)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#30409B" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(48, 64, 155)")}>
                  </div>
                </li>

                <li>
                  <div
                    style={{ background: "#223CDB" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeColor("rgb(34, 60, 219)")}>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="fontStyle-block-wrap">
            <h2>Font Background</h2>
            <div className="fontStyle-block">
              <div className="row">
                <div className="col-6">
                  <div className="inpWrap">
                    <input
                      className="pl-5"
                      type="text"
                      value={styleState.bgColor !== false ? styleState.bgColor : ''}
                      readOnly
                    />
                    <span
                      className="colorValue cursor-pointer"
                      style={{ backgroundColor: `${styleState.bgColor !== false ? styleState.bgColor : '#fff'}` }}
                      onClick={() => setShowBgColor({
                        ...showBgColor,
                        enable: true
                      })}
                    ></span>
                    {showBgColor.enable ?
                      <>
                        <div className="swatch-cover" onClick={(e) => setShowBgColor({
                          ...showBgColor,
                          enable: false
                        })}
                        />

                        <SketchPicker color={styleState.bgColor !== false ? styleState.bgColor : '#fff'}
                          onChange={(e) => onInputBgColor(e)}
                        />
                      </> : null}
                    <span className="label">HEX</span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={showBgColor.r} />
                    <span className="label">R</span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={showBgColor.g} />
                    <span className="label">G</span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="inpWrap">
                    <input className="text-center" type="text" value={showBgColor.b} />
                    <span className="label">B</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="color-palette">
              <ul>

                <li style={{ position: 'relative', overflow: 'hidden' }}>
                  <div
                    style={{ background: "#FFF" }}
                    className="color-palette-single cursor-pointer no-color"
                    onClick={() => removeBgColor()}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#E1E1E1" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(225, 225, 225)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#D7D7D7" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(215, 215, 215)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#CCCCCC" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(204, 204, 204)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#C2C2C2" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(194, 194, 194)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#B7B7B7" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(183, 183, 183)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#A1A1A1" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(161, 161, 161)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#959595" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(149, 149, 149)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#E8C9FF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(232, 201, 255)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#C87EFF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(200, 126, 255)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#F34AFF" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(243, 74, 255)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#D19DF8" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(209, 157, 248)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#9B33E9" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(155, 51, 233)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#8133DB" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(129, 51, 219)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#7033D5" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(112, 51, 213)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#23803F" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(35, 128, 63)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#54C423" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(84, 196, 35)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#606888" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(96, 104, 136)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#7C83AC" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(124, 131, 172)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#5B69BE" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(91, 105, 190)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#30409B" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(48, 64, 155)")}
                  >
                  </div>
                </li>


                <li>
                  <div
                    style={{ background: "#223CDB" }}
                    className="color-palette-single cursor-pointer"
                    onClick={() => onChangeBgColor("rgb(255, 255, 255)")}
                  >
                  </div>
                </li>

              </ul>
            </div>
          </div>

          <div className="fontStyle-block-wrap">
            <div className="itemDisp">
              <div className="itemDisp-left">
                Apply to all slides
              </div>
              <div className="itemDisp-right">
                <div className="switch-single">
                  <label className="switch">
                    <input
                      type="checkbox"
                      // value={slideAll}
                      checked={slideAll}
                      disabled={slideAll}
                      onChange={(e) => applyChanges()}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div> : ''}
    </div >
  );
}

export default TextStyle;
