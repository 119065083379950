import styled from "styled-components";
export const Bonuses5style = styled.div`

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,800&display=swap');
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    padding-top: 10px;
    width: 108px;
    margin: 0 auto;
}
.head-box h1{
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    color: #0e0d0d;
    background: #fff;
}

.text-content{
  display: flex;
  align-items: center;
}

.text-wrappers {
    margin-left: 2px;
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-flow: wrap;
}
.text-wrappers p{
    width: 99%;
    margin-bottom: 0px;
    padding-left: 5px;
}

.bonus-list-1{
    position: absolute;
    top: 4px;
    background: #fff;
    height: 49px;
    width: 94%;
    box-shadow: 18px 10px 5px #000;
}

.bonus-list-2 {
    position: absolute;
    top: 81px;
    background: #fff;
    height: 49px;
    width: 94%;
    box-shadow: 18px 10px 5px #000;
}
.bonus-list-3 {
    position: absolute;
    top: 158px;
    background: #fff;
    height: 49px;
    width: 94%;
    box-shadow: 18px 10px 5px #000;

}
.bonus-list-4 {
    position: absolute;
    top: 236px;
    background: #fff;
    height: 49px;
    width: 94%;
    box-shadow: 18px 10px 5px #000;
}

.bulits{
  display: flex;
  align-items: center;
  margin-top: -11px;
  width: 100px;
  height: 67px;
  background: yellow;
  box-shadow: -1px 10px 5px #000;
 }

 
@media (max-width: 1199.98px) { 
     .bulits {
        width: 108px;
        height: 58px;
        margin-top:-5px;
     }
     .bonus-list-2 {
        position: absolute;
        top: 73px;
        background: #fff;
        height: 49px;
        width: 94%;
    }
     .bonus-list-3 {
        position: absolute;
        top: 136px;
        background: #fff;
        height: 49px;
        width: 94%;
    }
     .bonus-list-4 {
        position: absolute;
        top: 199px;
        background: #fff;
        height: 49px;
        width: 94%;
    }
    
     

}

`;