import React, { useState } from "react"
import { Props_Cons4style } from './style'
import MediaEditor from "../../../Preview/MediaEditor";
import TextEditor from "../../../Preview/TextEditor";
import TemplateLogo from "../../../Preview/TemplateLogo";
import TemplateWatermark from "../../../Preview/TemplateWatermark";
import {FaRegThumbsUp}  from "react-icons/fa";
import {FaRegThumbsDown}  from "react-icons/fa";
const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });

    return (
        <Props_Cons4style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    // data={Pros_and_Cons2}
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                // type='image'
                />
                <div className="template-preview">
                    <div className="container">
                        <div className="row gx-3 gy-3">
                            <div className="col-sm-6">
                                <div className="box-wrapper-1">
                                    <div className="box-1">
                                        <div className="pros-sign">
                                        <FaRegThumbsUp className="thumb"/>
                                        </div>
                                        <span>PRO's</span>
                                    </div>
                                    <div className="box-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.00525555 8 8.005">
                                            <path d="M 0 1 L 0 8 L 8 8 L 8 0 C 2.633 -0.068 0.95 0.538 0 1" fill="#E4FBF7" />
                                        </svg>
                                        <div className="box-text-wrapper" >

                                            {props.templateText.map((curElem, index) => {

                                                if (curElem.text.textType === "pros") {
                                                    return (


                                                        <div className="text-content" key={curElem.id}>
                                                            <TextEditor
                                                                layer={layer}
                                                                setLayer={setLayer}
                                                                layerStatus={curElem.is_selected}
                                                                textStatus={curElem.text.is_selected}
                                                                layerId={curElem.id}
                                                                text={curElem.text.text}
                                                                style={curElem.text.style}
                                                                setTextLength={props.setTextLength}
                                                                setMaxLimit={props.setMaxLimit}
                                                                templateNumber={props.templateNumber}
                                                                blockType={
                                                                    { tag: 'p', }
                                                                }

                                                            />
                                                        </div>

                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="box-3">
                                <div className="cons-sign">
                                            <FaRegThumbsDown className="thumb-2" />
                                        </div>
                                    <span>CON's</span>
                                </div>
                                <div className="box-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.00525555 8 8.005">
                                            <path d="M 0 1 L 0 8 L 8 8 L 10 0 C 2.633 -0.068 0.95 0.538 0 1" fill="#FDE3D4" />
                                        </svg>
                                    <div className="box-text-wrapper-2" >

                                        {props.templateText.map((curElem, index) => {

                                            if (curElem.text.textType === "cons") {
                                                return (


                                                    <div className="text-content" key={curElem.id}>
                                                        <TextEditor
                                                            layer={layer}
                                                            setLayer={setLayer}
                                                            layerStatus={curElem.is_selected}
                                                            textStatus={curElem.text.is_selected}
                                                            layerId={curElem.id}
                                                            text={curElem.text.text}
                                                            style={curElem.text.style}
                                                            setTextLength={props.setTextLength}
                                                            setMaxLimit={props.setMaxLimit}
                                                            templateNumber={props.templateNumber}
                                                            blockType={
                                                                { tag: 'p', }
                                                            }

                                                        />
                                                    </div>

                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Props_Cons4style>
    );
};
export default Template;