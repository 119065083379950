import styled from "styled-components";
export const Conclusion1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img{
     max-height:100%;
  }

  .template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
  }

  .head-box{
    padding-top:56px;
  }
  .head-box h1{
    margin-bottom: 0!important;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 48px;
    text-transform: uppercase;
    padding-right: 10px;
    padding-top: 14px;
    letter-spacing: 2px;
  }
  .text-wrappers{
    padding-top:8px;
    height:auto;
    width:100%;
  }
  .text-wrappers p,.text-wrappers-2 p {
    padding:8px;
    width:100%;
    max-height:100%;
    overflow:hidden;
  }
  .text-wrappers-2{
    padding-top:10px;
    width:100%;
    height:132px;
  }

@media (max-width: 1199.98px) { 
  .text-wrappers {
    padding-top: 0;
    height:auto;
  }
  
  .head-box{
    padding-top:40px;
  }
  
  .head-box h1 {
   padding-top:10px;
    font-size: 40px;
  }
   
  .text-wrappers-2 {
    padding-top:16px;
    height:auto;
    overflow: hidden;
  }
.text-wrappers p,.text-wrappers-2 p {
  width: 100%;
  height: 100%;
  overflow:hidden;
}
   
}


`;