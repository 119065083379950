let TemplateComponents = {};

TemplateComponents["introduction"] = {};
TemplateComponents["prosAndCons"] = {};
TemplateComponents["productDescription"] = {};
TemplateComponents["features"] = {};
TemplateComponents["featureDedicated"] = {};
TemplateComponents["testimonials"] = {};
TemplateComponents["demo"] = {};
TemplateComponents["bonuses"] = {};
TemplateComponents["bonusesDedicated"] = {};
TemplateComponents["conclusion"] = {};


/* Introduction */

TemplateComponents["introduction"]["1"] =
    require("../components/editor/Templates/Introduction/1/Template").default;
TemplateComponents["introduction"]["2"] =
    require("../components/editor/Templates/Introduction/2/Template").default;
TemplateComponents["introduction"]["3"] =
    require("../components/editor/Templates/Introduction/3/Template").default;
TemplateComponents["introduction"]["4"] =
    require("../components/editor/Templates/Introduction/4/Template").default;
TemplateComponents["introduction"]["5"] =
    require("../components/editor/Templates/Introduction/5/Template").default;
TemplateComponents["introduction"]["6"] =
    require("../components/editor/Templates/Introduction/6/Template").default;

// /* ProsAndCons*/

TemplateComponents["prosAndCons"]["1"] =
    require("../components/editor/Templates/ProsAndCons/1/Template").default;
TemplateComponents["prosAndCons"]["2"] =
    require("../components/editor/Templates/ProsAndCons/2/Template").default;
TemplateComponents["prosAndCons"]["3"] =
    require("../components/editor/Templates/ProsAndCons/3/Template").default;
TemplateComponents["prosAndCons"]["4"] =
    require("../components/editor/Templates/ProsAndCons/4/Template").default;
TemplateComponents["prosAndCons"]["5"] =
    require("../components/editor/Templates/ProsAndCons/5/Template").default;
TemplateComponents["prosAndCons"]["6"] =
    require("../components/editor/Templates/ProsAndCons/6/Template").default;

// /* ProductDescription */

TemplateComponents["productDescription"]["1"] =
    require("../components/editor/Templates/ProductDescription/1/Template").default;
TemplateComponents["productDescription"]["2"] =
    require("../components/editor/Templates/ProductDescription/2/Template").default;
TemplateComponents["productDescription"]["3"] =
    require("../components/editor/Templates/ProductDescription/3/Template").default;
TemplateComponents["productDescription"]["4"] =
    require("../components/editor/Templates/ProductDescription/4/Template").default;
TemplateComponents["productDescription"]["5"] =
    require("../components/editor/Templates/ProductDescription/5/Template").default;
TemplateComponents["productDescription"]["6"] =
    require("../components/editor/Templates/ProductDescription/6/Template").default;

// // Features

TemplateComponents["features"]["1"] =
    require("../components/editor/Templates/Feature/1/Template").default;
TemplateComponents["features"]["2"] =
    require("../components/editor/Templates/Feature/2/Template").default;
TemplateComponents["features"]["3"] =
    require("../components/editor/Templates/Feature/3/Template").default;
TemplateComponents["features"]["4"] =
    require("../components/editor/Templates/Feature/4/Template").default;
TemplateComponents["features"]["5"] =
    require("../components/editor/Templates/Feature/5/Template").default;
TemplateComponents["features"]["6"] =
    require("../components/editor/Templates/Feature/6/Template").default;

// // Features_dedicated

TemplateComponents["featureDedicated"]["1"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/1/Template").default;
TemplateComponents["featureDedicated"]["2"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/2/Template").default;
TemplateComponents["featureDedicated"]["3"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/3/Template").default;
TemplateComponents["featureDedicated"]["4"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/4/Template").default;
TemplateComponents["featureDedicated"]["5"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/5/Template").default;
TemplateComponents["featureDedicated"]["6"] =
    require("../components/editor/Templates/Feature/FeatureDedicated/6/Template").default;

// // Testimonial

TemplateComponents["testimonials"]["1"] =
    require("../components/editor/Templates/Testimonial/1/Template").default;
TemplateComponents["testimonials"]["2"] =
    require("../components/editor/Templates/Testimonial/2/Template").default;
TemplateComponents["testimonials"]["3"] =
    require("../components/editor/Templates/Testimonial/3/Template").default;
TemplateComponents["testimonials"]["4"] =
    require("../components/editor/Templates/Testimonial/4/Template").default;
TemplateComponents["testimonials"]["5"] =
    require("../components/editor/Templates/Testimonial/5/Template").default;
TemplateComponents["testimonials"]["6"] =
    require("../components/editor/Templates/Testimonial/6/Template").default;

// // Demo

TemplateComponents["demo"]["1"] =
    require("../components/editor/Templates/Demo/1/Template").default;


// // Bonuses

TemplateComponents["bonuses"]["1"] =
    require("../components/editor/Templates/Bonuses/1/Template").default;
TemplateComponents["bonuses"]["2"] =
    require("../components/editor/Templates/Bonuses/2/Template").default;
TemplateComponents["bonuses"]["3"] =
    require("../components/editor/Templates/Bonuses/3/Template").default;
TemplateComponents["bonuses"]["4"] =
    require("../components/editor/Templates/Bonuses/4/Template").default;
TemplateComponents["bonuses"]["5"] =
    require("../components/editor/Templates/Bonuses/5/Template").default;
TemplateComponents["bonuses"]["6"] =
    require("../components/editor/Templates/Bonuses/6/Template").default;

// // BonusesDedicated

TemplateComponents["bonusesDedicated"]["1"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/1/Template").default;
TemplateComponents["bonusesDedicated"]["2"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/2/Template").default;
TemplateComponents["bonusesDedicated"]["3"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/3/Template").default;
TemplateComponents["bonusesDedicated"]["4"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/4/Template").default;
TemplateComponents["bonusesDedicated"]["5"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/5/Template").default;
TemplateComponents["bonusesDedicated"]["6"] =
    require("../components/editor/Templates/Bonuses/BonusDedicated/6/Template").default;

// // Conclusion

TemplateComponents["conclusion"]["1"] =
    require("../components/editor/Templates/Conclusion/1/Template").default;
TemplateComponents["conclusion"]["2"] =
    require("../components/editor/Templates/Conclusion/2/Template").default;
TemplateComponents["conclusion"]["3"] =
    require("../components/editor/Templates/Conclusion/3/Template").default;
TemplateComponents["conclusion"]["4"] =
    require("../components/editor/Templates/Conclusion/4/Template").default;
TemplateComponents["conclusion"]["5"] =
    require("../components/editor/Templates/Conclusion/5/Template").default;
TemplateComponents["conclusion"]["6"] =
    require("../components/editor/Templates/Conclusion/6/Template").default;

export default TemplateComponents;
