import styled from "styled-components";
export const Bonuses1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top:2px;
}
.head-box h1{
    color:#fff;
    font-size:28px;
}
svg {
    position: absolute;
    left: 8px;
    top: -1px;
}

.track {
    stroke-width: 2px;
    stroke: #787373;
    fill: none;
    stroke-dasharray: 192px;
    stroke-dashoffset: -52;
}

.circle {
    position: absolute;
    top: 9px;
    left: 19px;
    width: 75px;
    height: 75px;
    background-color: #F2003C;

}
.track {
    stroke-width: 2px;
    stroke: #787373;
    fill: none;
    stroke-dasharray: 160px;
    stroke-dashoffset: -40;
}
.bonus-wrapper-1{
    position: absolute;
    top: -27px;
    left: -5px;
    
}

 .bonus-wrapper-2{
    position: absolute;
    top: 53px;
    left: 40px;
}

 .bonus-wrapper-3{
    position: absolute;
    top: 135px;
    left: 94px;
}

 .bonus-wrapper-4{
    position: absolute;
    top: 216px;
    left: 149px;
}

.bonus-shape-2{
    width:377px;
    height: 60px;
    background: rgb(255,255,255);
    position: absolute;
    left: 50px;
    top: 20px;
}

.bonus-shape-3{
    position: absolute;
    left: 430px;
    top: 20px;
    width: 14px;
    height: 60px;
    background: rgb(255,255,255);

  } 
  .bonus-shape-1{
    position: absolute;
    z-index:1;
  }

 .bonus-shape-4{
    position: absolute;
    left: 447px;
    top: 20px;
    width: 14px;
    height: 60px;
    background: rgb(255,255,255);
  }

.bonus-shape-5{
    position: absolute;
    left: 464px;
    top: 20px;
    width: 14px;
    height: 60px;
    background: #F2003C;
}
.track-sm{
    display:none;
}
.text-wrappers-2{
    margin-left: 54px;
    width: 318px;
    height:auto;
    display:flex;

}
 .text-wrappers-2 p{
    Padding:5px 5px;
    margin-bottom:0px;
    height:auto;
    width:100%;
    flex-wrap:wrap;
 }


@media (max-width: 1199.98px) { 
    .track-sm{
        display:block;
    }
    .track-lg{
        display:none;
    }

    svg{
        width:90px!important;
        height:90px!important;
        position: absolute;
        left: 10px;
        top: 0px;
    }

    .head-box{
        margin-top:0px;
    }
    .head-box h1{
        font-size:30px;
    }

    .track {
        stroke-dasharray: 124px;
        stroke-dashoffset: -28;
        position: absolute;
    }
    .circle {
        position: absolute;
        top: 10px;
        left: 21px;
        width: 60px;
        height: 60px;
    }
    .bonus-shape-2 {
        width: 304px;
        height: 52px;
        position: absolute;
        left: 50px;
        top: 12px;
    }
     .bonus-shape-3 {
        top: 12px;
        height:52px;
        left:357px;
    }
    .bonus-shape-4 {
        top: 12px;
        height:52px;
        left:374px;
    }
    .bonus-shape-5 {
        top: 12px;
        height:52px;
        left:391px;
    }
    .bonus-wrapper-1{
        position: absolute;
        top: -22px;
        left: -3px;
    }
    
     .bonus-wrapper-2{
        position: absolute;
        top: 43px;
        left: 34px;
    }
    
     .bonus-wrapper-3{
        position: absolute;
        top: 109px;
        left: 75px;
    }
    
     .bonus-wrapper-4{
        position: absolute;
        top: 177px;
        left: 113px;
    }
    .text-wrappers-2{
        margin-left:33px;
        width:269px;
        height:auto;
    
    }
     .text-wrappers-2 p{
        height:auto;
        width:100%;
     }
    
    
}


`;