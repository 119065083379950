import React, { useRef, useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import { useSelector, useDispatch } from 'react-redux';
import { updateEditedText, setSelectedTextStatus, setSelectedInnerTextStatus, updateEditedInnerText } from '../../../actions/videoAction'
import './templateCss.css'

const TextEditor = (props) => {
    const contentEditable = useRef()
    const dispatch = useDispatch()
    const slides = useSelector(state => state.videos.data.slides)
    const selectedSlideIndex = slides.findIndex(({ is_selected }) => is_selected === true)
    const selectedTextIndex = slides[selectedSlideIndex].text.findIndex(({ is_selected }) => is_selected === true)


    const [tempText, setTempText] = useState({
        status: false,
        text: props.text
    })
    useEffect(() => {
        setTempText({
            ...tempText,
            text: props.text
        })
    },[selectedSlideIndex])

    const [myStyle, setMyStyle] = useState({
        textAlign: '',
        backgroundColor: '',
        fontWeight: '',
        color: '',
        fontFamily: '',
        fontStyle: '',
        fontSize: 18,
        lineHeight: 1,
        textTransform: '',
        textDecoration: '',
        outLine: '0px !important'
    })


    useEffect(() => {
        setMyStyle({
            ...myStyle,
            textAlign: props.style.align,
            backgroundColor: props.style.bgColor,
            fontWeight: props.style.bold ? 'bold' : '',
            color: props.style.fontColor,
            fontFamily: props.style.fontFamily,
            fontSize: parseInt(props.style.fontSize),
            fontStyle: props.style.italic ? 'italic' : '',
            lineHeight: props.style.lineHeight,
            textTransform: props.style.lowerCase ? 'lowercase' : props.style.upperCase ? 'uppercase' : 'none',
            textDecoration: props.style.strike ? 'line-through' : props.style.underline ? 'underline' : 'none'
        })
    }, [props.style])

    let sanitizeConf = {
        allowedTags: [],
    };



    useEffect(() => {
        if (tempText.status) {
            let val = sanitizeHtml(tempText.text, sanitizeConf)
            let selectedTeIndex = slides[selectedSlideIndex].text.findIndex(({ is_selected }) => is_selected === true)
            if (props.textId !== undefined) {
                dispatch(updateEditedInnerText(val, props.layerId, props.textId, selectedSlideIndex, selectedTeIndex, props.type))
            }
            else {
                dispatch(updateEditedText(val, selectedSlideIndex, selectedTeIndex))
            }
        }
    }, [tempText.text])

    const handleChange = (e) => {
        setTempText({
            ...tempText,
            status: true,
            text: e.target.value
        })
    }

    const handleSelectTextLayer = (layerId, textId, type) => {
        props.setLayer({
            ...props.layer,
            image: false,
            text: true,
            bgImage: false
        })
        if (textId !== undefined) {
            dispatch(setSelectedInnerTextStatus(layerId, textId, selectedSlideIndex, selectedTextIndex, type))
        }
        else {
            dispatch(setSelectedTextStatus(layerId, selectedSlideIndex, selectedTextIndex))
        }

    }



    return (
        <>
            <ContentEditable
                ref={contentEditable}
                html={tempText.text}
                onClick={() => handleSelectTextLayer(props.layerId, props.textId, props.type)}
                disabled={false}
                onChange={(e) => handleChange(e)}
                tagName={props.blockType.tag}
                style={myStyle}
                className={`${props.layer.text === true && props.layerStatus && props.textStatus ? 'template-selectes-layer no-outline' : 'no-outline'}`}
            />
        </>

    )
}

export default TextEditor