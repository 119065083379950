import React, { useEffect,  useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import LoginFooter from "./auth/LoginFooter";
import { Link,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { masterLoginUser } from "../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../actions/authAction";
import { GoMail } from "react-icons/go";
import { RiLockLine } from "react-icons/ri";
import Title from "./Title";
import Alert from "./Alert";
import loginBg from "../images/BG.png";
import logo from "../images/LOGO.png";

const MasterLogin = () => {


  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  if (auth.isAuthenticated) {
    history.push("/dashboard");
  }

  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    useremail: "",
    email:"",
    password: "",
  });

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(masterLoginUser(user, setLoader,history));
  };



  return (
    <>
      <Title title="MasterLogin" />
      <Alert />
      <section
        className="logoimg"
        style={{ backgroundImage: "url(" + loginBg + ")" }}
      >
        <div className="container">
          <div className="row pt-4">
            <div className="col-md-4">
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>

          <div className="login-box">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="login-content text-white ">
                  <div className="login-head-text ">
                    <span className="welcome-text">WELCOME TO </span>
                    <br />
                    <h1> ReviewReel</h1>
                  </div>
                  <div className="login-head-para ">
                    <p>
                      Instantly create impactful, engaging, and ready-to-upload
                      product video reviews in any niche using ReviewReel’s
                      powerful A.I. that does all the work for you.
                    </p>
                  </div>
                  <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <form
                  className="login-form text-white"
                  method="post"
                  onSubmit={(e) => onFormSubmit(e)}
                >
                  <h4>
                    <span>Sign In</span> to Your Account Now
                  </h4>
                  <div className="form-group mt-4">
                    <label>User Email Address</label>
                    <div className="form-group2">
                      <span className="mail ">
                        {" "}
                        <GoMail />
                      </span>
                      <span className=" line"> </span>
                      <input
                        type="email"
                        name="useremail"
                        className="form-control"
                        placeholder="Email Address"
                        required
                        value={user.useremail}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-4">
                    <label>Admin Email Address</label>
                    <div className="form-group2">
                      <span className="mail ">
                        {" "}
                        <GoMail />
                      </span>
                      <span className=" line"> </span>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        required
                        value={user.email}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Admin Password</label>
                    <div className="form-group2">
                      <span className="mail-2">
                        {" "}
                        <RiLockLine />
                      </span>
                      <span className=" line"> </span>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="************"
                        required
                        value={user.password}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="form-control btn-change6 mt-4"
                    disabled={loader}
                  >
                    {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ""}{" "}
                    Sign In to ReviewReel
                  </button>
                  <div className="forgot text-center mt-4">
                    <Link to="/forget-password" className="text-center ">
                      Forgot your account details? <span>Click here</span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <LoginFooter />
      </section>
    </>
  );
};

export default connect(null, { masterLoginUser })(withRouter(MasterLogin));
