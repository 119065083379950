import React, { useState } from 'react';
import { Bonuses4style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';
import price_tag from '../../../../../images/price-box.png'

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <Bonuses4style >
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''} `}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}

                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                />
                <div className="template-preview flex-column">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="head-box text-center">
                                    <h1>BONUS</h1>
                                </div>
                            </div>
                        </div>
                     
                        {props.templateText.map((curElem) => {
                            return (
                                <div className="row ">
                                    <div className="col-sm-12">
                                        <div className={`bonus-wrapper-${curElem.id}` } >

                                            <div className="bonus-shape" >
                                                <div className="text-wrappers">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.text.is_selected}
                                                        layerId={curElem.id}
                                                        templateNumber={props.templateNumber}
                                                        //    textId={curElem.text.id}
                                                        textId={2}
                                                        text={curElem.text.text}
                                                        style={curElem.text.style}
                                                        type={props.templateType}
                                                        blockType={
                                                            {
                                                                tag: 'p',
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="bonus-img">
                                                <img src={price_tag} alt="" />
                                                <div className="price-value">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.value.is_selected}
                                                        templateNumber={props.templateNumber}
                                                        layerId={curElem.id}
                                                        // textId={curElem.value.id}
                                                        textId={1}
                                                        text={curElem.value.text}
                                                        style={curElem.value.style}
                                                        type={props.templateType}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        blockType={
                                                            {
                                                                tag: 'span',
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            </div>

        </Bonuses4style >

    );

};
export default Template;