import React, { useState, useEffect } from "react";
import youtubeIcon from "../../images/youtube-active.png"
import youtubeDisable from "../../images/YT_DISABLE.png"
import GoogleLogin from "react-google-login";
import axios from "axios";
import { baseURL } from "../../global/global";
import googleIcon from "../../images/youtubeconect.png";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { fetchSocialAccounts } from "../../actions/socialAction";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../Alert";

const ConnectYoutube = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [membership, setMembership] = useState([]);

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    })

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({ ...intData, id: id });
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Deleting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-social-network`,
            data: intData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const connectYoutube = (code) => {
        axios({
            method: "POST",
            url: `${baseURL}save-youtube`,
            data: JSON.stringify({ code: code, user_id: auth.user.id }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {

        })
    }

    const handleFaceBook = (renderProps) => {
        let limit = 10
        if (membership.includes('commercial') && membership.length === 1) {
            limit = 10
        }
        if (props.data.length < limit + 1) {
            renderProps.onClick()
        }
        else {
            dispatch(setAlert(`You Have Connected  Maximum ${limit} Youtube Accounts`, "warning"))
        }
    }

    useEffect(() => {
        if (auth.user !== false) {
            if (auth.user.membership !== '' && auth.user.membership !== null) {
                setMembership(auth.user.membership.split('__'))
            }
        }
    }, [auth])

    return (
        <>
            <Alert />
            <div className="connection-box mt-3" id="Youtube_new">
                <div className="row">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className="youtube-logo">
                            <img src={props.data.length > 0 ? youtubeIcon : youtubeDisable} width="56px" alt="youtube" />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div key={index} className="facebook-connection text-white   d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>Youtube</h6>
                                                <p className="m-0">{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Youtube</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <GoogleLogin
                                clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                render={(renderProps) => (
                                    <button onClick={() => handleFaceBook(renderProps)} type="button" className="btn-change7 youtube-connect">
                                        <img src={googleIcon} alt="" />
                                    </button>

                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                scope="https://www.googleapis.com/auth/youtube"
                                responseType="code"
                                accessType="offline"
                                prompt="consent"
                                cookiePolicy={"single_host_origin"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete youtube account
            </SweetAlert>
        </>
    )
}

export default ConnectYoutube;