import { Tab, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useSelector } from "react-redux";
import AudioFiles from "../AudioFiles";
import InfiniteScroll from "react-infinite-scroll-component";
import UploadMusic from "./UploadMusic";

const Music = ({ slideData }) => {
    const auth = useSelector(state => state.auth);
    const video = useSelector(state => state.videos)

    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);

    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    const [selectedAudio, setSelectedAudio] = useState(false)

    useEffect(() => {
        if (video !== undefined && selectedSlideIndex !== -1) {
            // setSelectedAudio(video.data.slides[selectedSlideIndex].audio)
        }
    }, [video, selectedSlideIndex])

    const fetchAudio = (page = 1) => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}load-library-audio`,
            data: JSON.stringify({ search_type: 'music', page_number: page, 'keyword': '' }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAudioData(audioData.concat(res.data.data));
                setPageCount(page);
            } else {
                setError(res.data.message);
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
    }

    useEffect(() => {
        // if (music) {
            fetchAudio();
        // }
        return () => {
            setAudioData([]);
        }
    }, [])

    // Upload Audio



    return (
        <>
            <div className="tabInner">
                <div className="media-block">
                    <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-tab2">
                        <Nav variant="pills" className="inTabNav full">
                            <Nav.Item>
                                <Nav.Link eventKey="voice-tab2">Music</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="upload-audio2">Upload Music</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="voice-tab2">
                                <div className="innertab">
                                    <div className="alert alert-warning text-center">
                                        Music would be applied to the entire campaign
                                    </div>
                                    <div className="innertab-scroll alt" id="scrollableAudio">
                                        <div className="mediaList">
                                            <ul className="row">
                                                <InfiniteScroll
                                                    dataLength={audioData.length} //This is important field to render the next data
                                                    next={() => fetchAudio(pageCount + 1)}
                                                    hasMore={true}
                                                    scrollableTarget="scrollableAudio"
                                                >
                                                    {
                                                        audioData.length > 0 ?
                                                            audioData.map((audio, index) => {
                                                                return (
                                                                    <AudioFiles
                                                                        title={audio.title}
                                                                        selectedSlideIndex={selectedSlideIndex}
                                                                        url={audio.url}
                                                                        selectedAudio={selectedAudio}
                                                                        setSelectedAudio={setSelectedAudio}
                                                                        currentUrl={currentUrl}
                                                                        setCurrentUrl={setCurrentUrl}
                                                                        key={index} />
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                    {
                                                        loader ?
                                                            <div className="col-md-12">
                                                                <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" style={{color : '#ffa200'}}/></h4>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        error ?
                                                            <div className="col-md-12">
                                                                <h6 className="text-center">{error}</h6>
                                                            </div>
                                                            : ''
                                                    }
                                                </InfiniteScroll>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="upload-audio2">
                                <UploadMusic />
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </div>
            </div>
        </>
    );
}

export default Music;
