import styled from "styled-components";
export const Props_Cons6style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
     max-height: 100%;
   }
  
 .template-preview {
    width: 100%;
    position: absolute;
    top: 0;
 }
.shape {
    position: absolute;
    top: -16px;
    left: 151px;
}
.shape img {
   width:290px
}
.main-line{
    width: 7px;
    height: 275px;
    background: #000;
    position: absolute;
    left: 291px;
    top: 100px;
}

.text-wrappers {
    position: absolute;
    top: 130px;
    left: 0.5%;
}

.text-content {
    width: 270px;
    margin-top:5px;
}

.text-wrappers-2 {
    position: absolute;
    top: 130px;
    left: 55%;
}

p{
    margin-bottom:0;
}

@media(max-width: 1199.98px){ 
    .text-wrappers-2 {
        position: absolute;
        top:88px;
        left: 55%;
    }
    
    .text-wrappers {
        position: absolute;
        top:88px;
        left: 1%;
    }
    .center-shape {
        width: 23px;
        height: 285px;
    }
    
    .shape {
        position: absolute;
        top: -16px;
        left: 22%;
    }
    .shape img{
        width:250px;
    }
    .main-line {
        width: 7px;
        height: 235px;
        background: #000;
        position: absolute;
        left: 234px;
        top: 85px;
    }
    
    .text-content {
        width: 215px;
        margin-top:0px;
    }
    
  }

`;