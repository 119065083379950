import React, { useState } from 'react';
import { List4style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {
    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    return (
        <List4style>
            <div className={`template-wrapper  ${layer.bgImage ? 'image-bg-border' : ''}`}>
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}

                    myStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                // type='image'
                />
                <div className="template-preview flex-column">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="head-box text-center">
                                    <h1>Feature list</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="Feature-list text-center ">
                                    {props.templateText.map((curElem) => {
                                        let random = ""
                                        let val = curElem.id

                                        if(curElem.id > 4){
                                            val = val %4
                                        }

                                        if(val === 1){
                                            random = 'color-1'
                                        }
                                        else if(val === 2){
                                            random = 'color-2'
                                        }
                                        else if(val === 3){
                                            random = 'color-3'
                                        }
                                        else if(val === 4){
                                            random = 'color-4'
                                        }

                                        return (
                                            <div className="text-content" key={curElem.id}>
                                                <div className="bulits">
                                                   <span className={random}>{curElem.id}</span>
                                                </div>
                                                <div className="text-wrappers ">
                                                    <TextEditor
                                                        layer={layer}
                                                        setLayer={setLayer}
                                                        layerStatus={curElem.is_selected}
                                                        textStatus={curElem.text.is_selected}
                                                        layerId={curElem.id}
                                                        text={curElem.text.text}
                                                        style={curElem.text.style}
                                                        setTextLength={props.setTextLength}
                                                        setMaxLimit={props.setMaxLimit}
                                                        templateNumber={props.templateNumber}
                                                        blockType={
                                                            {
                                                                tag: 'p',
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </List4style >

    );

};
export default Template;