import styled from "styled-components";
export const List1style = styled.div`
.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
}

.template-wrapper img{
    max-height:100%;
}

.template-preview {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
}
.head-box{
    margin-top:10px;
}
.head-box h1{
  text-transform: uppercase;
  font-size: 42px;
  font-weight: bold;
}

.text-content{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.text-wrappers{
   margin-left:15px;
   width:585px;
}

.text-wrappers p{
  width:100%;
}

.Feature-list {
    position: absolute;
    top: 40px;
}

.circle{
  background: #000;
  width: 18px;
  height: 18px;
     
}
.triangle{
 clip-path: polygon(49% 24%, 100% 50%, 41% 78%, 0% 100%, 25% 50%, 0% 0%);
 background: #000;
 width: 35px;
 height: 35px;
}

.bulits{
  display: flex;
  align-items: center;
  margin-top: -11px;
 }
 

@media (max-width: 1199.98px) { 
  
  .text-content {
     margin-bottom: 14px;
  }
  .Feature-list {
    position: absolute;
    top: 0px;
}

.text-wrappers {
  width: 488px;
}

 

}

`;