import styled from "styled-components";
export const Props_Cons3style = styled.div`

.template-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    text-align:center;
  }

  .template-wrapper img {
    max-height: 100%;
   
  }
.template-preview {
    width:100%;
    position: absolute;
    top:0;
}
.card-wrapper {
    width:100%;
    max-height:100%;
    position: absolute;
    top:54px;
   
}

.card-wrapper-2 {
    width:100%;
    max-height:100%;
    position: absolute;
    top:54px;
   
}

.circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #00A002;
    top: -45px;
    left: 0;
    position: absolute;
    z-index: 1;
}

.circle-2 {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #BF0100;
    top: -43px;
    right: 28px;
    position: absolute;
    z-index: 1;
}

.polyLine {
    max-height: 100%;
    position: absolute;
    top: 1px;
    left: 76px;

}
.polyLine::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 271px;
    left: 218px;
    top: 0;
    background: inherit;
    border-radius: 50px;
}
.polyLine::before {
    content: '';
    position: absolute;
    width: 225px;
    height: 8px;
    left: 0;
    top: 0;
    background: inherit;
    border-radius: 50px;
}

.polyLine-2 {
    max-height: 100%;
    position: absolute;
    top: 2px;
    left: -8px;
}

.polyLine-2::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 271px;
    left: 0;
    top: 0;
    background: inherit;
    border-radius: 50px;
}

.polyLine-2::before {
    content: '';
    position: absolute;
    width: 225px;
    height: 8px;
    left: 0;
    top: 0;
    background: inherit;
    border-radius: 50px;
}

.circle span {
    display: flex;
    justify-content: center;
    margin: 33% auto;
    color:#fff;
    font-size:20px;
}

.circle-2 span {
    text-align: center !important;
    display: flex;
    justify-content: center;
    margin: 36% 7%;
    z-index: 1;
    color:#fff;
    font-size:20px;
}
.text-wrappers {
    position: absolute;
    top: 64px;
}
.text-wrappers-2 {
    position: absolute;
    top: 64px;
    left:18px;
}

.text-wrappers{
    left: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.text-wrappers-2{
    left: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.text-wrappers p {
    
}
.text-content{
    width: 240px;
    height: 64px;
    background: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    overflow: hidden;
    padding-left: 6px;
    padding-right: 6px;
}

.green-box {
    width: 54px;
    height: 64px;
    background-color: #00A002;
    font-size: 31px;
    display: inline-block;
    color: #fff;
    padding-top: 8px;
    box-sizing: border-box;
}

.green-box-2 {
    width: 54px;
    height: 64px;
    background-color: #00A002;
    font-size: 31px;
    display: inline-block;
    color: #fff;
    padding-top: 8px;
    box-sizing: border-box;
}
@media (max-width: 1199.98px){
    .circle {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #00A002;
        top: -45px;
        left: 0;
        position: absolute;
        z-index: 1;
    }
    .circle-2 {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #BF0100;
        top: -43px;
        right: 28px;
        position: absolute;
        z-index: 1;
    }
    
    .polyLine {
        top: 1px;
        left: 85px;
    }
    
    .polyLine-2 {
        top: 2px;
        left: -8px;
    }
    .polyLine::after {
        content: '';
        position: absolute;
        width: 6px;
        height: 256px;
        left: 164px;
        top: -13px;
    }
    .polyLine::before {
        content: '';
        position: absolute;
        width: 183px;
        height: 6px;
        left: -13px;
        top: -13px;
    }
    
    .polyLine-2::after {
        position: absolute;
        width: 6px;
        height: 256px;
        left: 1px;
        top: -14px;
    }
    
    .polyLine-2::before {
        content: '';
        position: absolute;
        width: 183px;
        height: 6px;
        left: 3px;
        top: -14px;
    }
    
    .text-wrappers {
        left: -18px;
        top:35px;
    }
    .text-wrappers-2 {
        top:35px;
    }
     .text-content {
        width: 188px;
        height: 64px;
    }
    
    .circle span {
        font-size: 18px;
    }
    .circle-2 span {
        font-size: 18px;
        margin: 34% 7%;
    }
    
}


`;