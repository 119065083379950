import React from 'react';
import TemplateComponents from "../../../global/TemplateComponent";


const TemplateBody = (props) => {
    let DynamicTemplate;
    if (props.templateType !== undefined && props.templateNumber !== undefined) {
        DynamicTemplate = TemplateComponents[props.templateType][props.templateNumber];
    }
    return (
        <DynamicTemplate {...props} />

    );
};

export default TemplateBody;
