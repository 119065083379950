import React, { useState } from 'react';
import { Intro2style } from "./style"
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from '../../../Preview/TemplateLogo';
import TemplateWatermark from '../../../Preview/TemplateWatermark';

const Template = (props) => {

    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });

    return (
        <Intro2style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`} >
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    data={props.backgroundData.fileUrl}
                    mediaType="bgImage"
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}
                    className="temp-media-design"
                />
                {/* </span> */}
                <div className="template-preview">

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="text-wrappers">
                                    <TextEditor
                                        layerId={props.templateText[0].id}
                                        text={props.templateText[0].text.text}
                                        style={props.templateText[0].text.style}
                                        layerStatus={props.templateText[0].is_selected}
                                        textStatus={props.templateText[0].text.is_selected}
                                        templateNumber={props.templateNumber}
                                        // maxLimit={props.textVarient.length > 0 ?  props.textVarient[0].maxLimit : 0}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        layer={layer}
                                        setLayer={setLayer}
                                        blockType={
                                            {
                                                tag: 'h1',
                                            }

                                        }
                                    />
                                </div>
                                <div className="text-wrappers-2">
                                    <TextEditor
                                        layerId={props.templateText[1].id}
                                        text={props.templateText[1].text.text}
                                        style={props.templateText[1].text.style}
                                        layerStatus={props.templateText[1].is_selected}
                                        textStatus={props.templateText[1].text.is_selected}
                                        // maxLimit={props.textVarient.length > 0 ?  props.textVarient[1].maxLimit : 0}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        layer={layer}
                                        setLayer={setLayer}
                                        blockType={
                                            {
                                                tag: 'p',
                                            }

                                        }
                                    />
                                </div>

                            </div>
                            <div className="col-sm-7">
                                <div className={`product_wrapper ${layer.image ? 'image-bg-border' : ''}`}>
                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        mediaType="pImage"
                                        data={props.productImage}
                                        type={props.productImage.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </Intro2style>

    );

};
export default Template;


