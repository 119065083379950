import React, { useEffect, useState } from "react";
import icon from "../../images/ICON.png"
import icon2 from "../../images/ICON2.png"
import icon3 from "../../images/ICON3.png"
import group56 from "../../images/Group 56.png"
import image3 from "../../images/Mask-Group-3.png"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addScrapText } from "../../actions/videoAction";
import closeModalx from "../../images/closeModal.svg"

const DashboardHead = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl('https://player.vimeo.com/video/720204131');
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <section className="bashboard-banner" style={{ backgroundImage: "url(" + + ")" }}>
                <div id="bg-video">
                    {/* <iframe src="https://player.vimeo.com/video/719048408?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
                    <video style={{width:"100%"}} autoPlay muted loop>
                        <source src="https://reeelapps-app.s3.us-west-2.amazonaws.com/ReviewReel_bg_video.mp4"/>
                   </video>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-7">
                            <div className="banner-content text-white">
                                <h4>WELCOME TO</h4>
                                {
                                    rebrand.data === false ? <h1><span>Review</span>Reel</h1> : <h1>{rebrand.data.name}</h1>
                                }
                                <p>Create compelling and in-depth product review videos without writing anything. Watch how {rebrandData.data ? rebrandData.data.name : 'ReviewReel'}’s powerful A.I. writes your entire script for you, letting you create 100s of traffic-getting review videos in any niche.</p>
                                <div className="watch-demo-btn mt-5">
                                    <button onClick={(e) => addFrameUrl()} type="button" data-toggle="modal" data-target="#myModal"><span>
                                        <i className="fas fa-play"></i></span> Watch Demo Video
                                    </button>
                                </div>

                                <div className={`modal ${showModal ? 'show-modal' : ''}`}>
                                    <div className="modal-dialog m-0 mx-auto" role="document">
                                        <div className="modal-content">

                                            <button onClick={(e) => closePopUp('')} type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                                <img src={closeModalx} alt=""/>
                                            </button>

                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen={true}></iframe>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 offset-lg-1 p-lg-0">
                            <div className="banner-items">
                                {/* <Link to="/add-script?type=content"> */}
                                <Link to="/create-video">
                                    <div className="banner-items-list">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                <img src={icon2} alt="icon" />
                                            </div>
                                            <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                <p className="m-0">Create New Project</p>
                                            </div>
                                            <div className="col-md-2 col-sm-2 col-2 d-flex align-items-center">
                                                <span><img src={group56} alt="right-icon" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/projects">
                                    <div className="banner-items-list bg2 mt-4">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                <img src={icon} alt="icon" />
                                            </div>
                                            <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                <p className="m-0">View Reviews Video</p>
                                            </div>
                                            <div className="col-md-2 col-sm-2  col-2 d-flex align-items-center">
                                                <span><img src={group56} alt="right-icon" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardHead;