import React, { useState } from "react";
import { Intro4style } from "./style";
import TextEditor from '../../../Preview/TextEditor';
import MediaEditor from '../../../Preview/MediaEditor';
import TemplateLogo from "../../../Preview/TemplateLogo";
import TemplateWatermark from "../../../Preview/TemplateWatermark";


const Template = (props) => {

    const [layer, setLayer] = useState({
        bgImage: true,
        image: false,
        text: false
    });
    // console.log(props.backgroundData.fileUrl, props.backgroundData.type)

    return (
        <Intro4style>
            <div className={`template-wrapper ${layer.bgImage ? 'image-bg-border' : ''}`}   >
                <TemplateLogo logo={props.logo} />
                <TemplateWatermark watermark={props.waterMark} />
                <MediaEditor
                    layer={layer}
                    setLayer={setLayer}
                    mediaType="bgImage"
                    data={props.backgroundData.fileUrl}
                    type={props.backgroundData.type}
                    setSelectImageLayer={props.setSelectImageLayer}

                />

                <div className="template-preview">
                    <div className="container">
                        <div className="row" >
                            <div className="col-sm-6 ">
                                <div className="media-border"></div>
                                <div className="media-bg"></div>
                                <div className={` ${layer.image ? 'image-bg-border' : ''} ${props.productImage.toLowerCase().includes(".png") ?'product-wrappers-2':'product-wrappers'}`}>
                                    <MediaEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        data={props.productImage}
                                        mediaType="pImage"
                                        myStyle={{
                                            width: '100%',
                                        }}
                                        type={props.productImage.includes("mp4") ? 'video' : 'image'}
                                        setSelectImageLayer={props.setSelectImageLayer}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 ">
                                <div className="text-area">
                                <div className="text-wrappers-1 ">
                                    <TextEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        layerStatus={props.templateText[0].is_selected}
                                        textStatus={props.templateText[0].text.is_selected}
                                        layerId={props.templateText[0].id}
                                        text={props.templateText[0].text.text}
                                        style={props.templateText[0].text.style}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        // maxLimit={props.textVarient.length > 0 ? props.textVarient[0].maxLimit : 0}
                                        blockType={
                                            {
                                                tag: 'h1',
                                            }
                                        }
                                    />
                                </div>
                                <div className="text-wrappers-2 ">
                                    <TextEditor
                                        layer={layer}
                                        setLayer={setLayer}
                                        layerStatus={props.templateText[1].is_selected}
                                        textStatus={props.templateText[1].text.is_selected}
                                        layerId={props.templateText[1].id}
                                        text={props.templateText[1].text.text}
                                        style={props.templateText[1].text.style}
                                        templateNumber={props.templateNumber}
                                        setTextLength={props.setTextLength}
                                        setMaxLimit={props.setMaxLimit}
                                        // maxlimit={props.textVarient.length > 0 ?  props.textVarient[1].maxLimit : 0}
                                        blockType={
                                            {
                                                tag: 'p',
                                            }
                                        }
                                    />
                                </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </Intro4style >
    );
};
export default Template;