import React,{useEffect} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import Title from "../Title";
import { loadUser } from "../../actions/authAction";
import { useDispatch } from "react-redux";


const Dashboard = () => {

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(loadUser())
    },[])

    return (
        <>
            <Title title="Dashboard" />
            <Navbar />
            <DashboardHead />
            <DashboardContent />
            <DashboardAbout />
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;