import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";


export const onSelectStoryTile = (slideIndex, scriptIndex, tts) => (dispatch) => {
    const data = { tts, slideIndex }
    dispatch({
        type: 'UPDATE_STORY_TILE',
        payload: { slideIndex, scriptIndex }
    })
    dispatch(setTtsData(tts, slideIndex, true))
}

export const updateCampaingName = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_CAMPAIGN_NAME', payload: data })
}

export const addVideoData = (data) => (dispatch) => {
    dispatch({ type: 'ADD_VIDEO_DATA', payload: data })
}

export const addNewTiles = (data) => (dispatch) => {
    dispatch({ type: 'ADD_NEW_TILES', payload: data })
}

export const addScrapText = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SCRAP_TEXT', payload: data });
}

export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateSlidesData = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}

export const updateVideoThumbnail = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_THUMBNAIL', payload: data });
}

export const updateStoryStatus = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_STORY_STATUS', payload: data })
}

export const changeTempVariation = (templateData, selectedSlideIndex) => (dispatch) => {
    const data = { templateData, selectedSlideIndex }
    dispatch({ type: 'CHANGE_TEMPLATE_VARIANT', payload: data })
}

export const generateSlideData = (text, uniq_id, setLoadSlide, type = false) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}add-slide`,
        data: JSON.stringify({ text, uniq_id }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(addNewTiles(res.data.data));
            if (type === false) {
                dispatch(updateStoryStatus(uniq_id));
            }
        }
        setLoadSlide(false);
    }).catch(err => {
        setLoadSlide(false);
    })
}

export const generateBlankSlide = (keyword, uniq_id, setLoaderNew) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}add-slide`,
        data: JSON.stringify({ keyword, uniq_id }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(addNewTiles(res.data.data));
            dispatch(updateSelectedTiles(uniq_id - 1))
        }
        setLoaderNew(false);
    }).catch(err => {
        setLoaderNew(false);
    })
}



export const changeImage = (keyword, type, index) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}search-media`,
        data: JSON.stringify({ keyword: keyword, search_type: type, page: 1 }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            let resArray = res.data.data[Math.floor(Math.random() * res.data.data.length)];
            let jsonData = {
                poster: resArray.thumb,
                index: index,
                file_url: resArray.image
            }

            dispatch({ type: 'UPDATE_SLIDE_IMAGES', payload: jsonData });

        }
    }).catch(err => {

    })
}
export const addNewSide = (data) => (dispatch) => {
    if (data.length > 0) {
        if (data[0].text.length > 0) {
            data[0].text.forEach((curElem) => {
                curElem.is_selected = true
                curElem.text.is_selected = true
            })
        }
        if (data[1].text.length > 0) {
            data[1].text.forEach((curElem) => {
                curElem.is_selected = true
                curElem.text.is_selected = true
            })
        }
    }
    else {
        if (data.text.length > 0) {
            data.text.forEach((curElem) => {
                curElem.is_selected = true
                curElem.text.is_selected = true
            })
        }
    }
    dispatch({ type: 'ADD_NEW_SLIDE', payload: data })
}


export const updateSlides = (data) => (dispatch) => {
    dispatch({ type: 'DELETE_SLIDE', payload: data })
}
export const deleteActiveSlide = (data, index) => (dispatch) => {
    dispatch({ type: 'DELETE_SLIDE', payload: data })
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: index })
}

/* Text Styles */
export const updateFontSize = (fontSize, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { fontSize, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_FONT_SIZE', payload: data });
}

export const updateFontFamily = (fontFamily, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {
    let data = { fontFamily, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_FONT_FAMILY', payload: data });
}

export const updateFontColor = (color, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { color, selectedSlideIndex, selectedTextIndex, type }

    dispatch({ type: 'UPDATE_FONT_COLOR', payload: data });
}

export const updateFontBgColor = (color, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {
    let bgData = { color, selectedSlideIndex, selectedTextIndex, type }

    dispatch({ type: 'UPDATE_FONT_BG_COLOR', payload: bgData });
}


export const removeFontBgColor = (selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {
    const data = { selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'REMOVE_FONT_BG_COLOR', payload: data });
}

export const updateTextBold = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { status, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_FONT_BOLD', payload: data });

}

export const updateTextItalic = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { status, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_FONT_ITALIC', payload: data });
}

export const updateTextUpper = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch, getState) => {

    let lowerFalse = false
    let data
    const val = getState().videos.data.slides[selectedSlideIndex].text[selectedTextIndex]

    if (status === true) {
        data = { status, selectedSlideIndex, selectedTextIndex, lowerFalse, type }
    }
    else {
        if (type === "testimonials") {
            if (val.name.is_selected === true) {
                lowerFalse = val.name.style.lowerCase

            } else if (val.designation.is_selected) {
                lowerFalse = val.designation.style.lowerCase
            }
            else {
                lowerFalse = val.text.style.lowerCase
            }
        }
        data = { status, selectedSlideIndex, selectedTextIndex, lowerFalse, type }
    }
    dispatch({ type: 'UPDATE_FONT_UPPER', payload: data });
}

export const updateTextLower = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch, getState) => {

    let upperFalse = false
    let data
    if (status === true) {
        data = { status, selectedSlideIndex, selectedTextIndex, upperFalse, type }
    }
    else {
        upperFalse = getState().videos.data.slides[selectedSlideIndex].text[selectedTextIndex].text.style.upperCase
        data = { status, selectedSlideIndex, selectedTextIndex, upperFalse, type }
    }
    dispatch({ type: 'UPDATE_FONT_LOWER', payload: data });
}

export const updateTextUnderline = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch, getState) => {

    let strikeFalse = false
    let data
    if (status === true) {
        data = { status, selectedSlideIndex, selectedTextIndex, strikeFalse, type }
    }
    else {
        strikeFalse = getState().videos.data.slides[selectedSlideIndex].text[selectedTextIndex].text.style.strike
        data = { status, selectedSlideIndex, selectedTextIndex, strikeFalse, type }
    }
    dispatch({ type: 'UPDATE_FONT_UNDERLINE', payload: data });

}

export const updateTextStrike = (status, selectedSlideIndex, selectedTextIndex, type) => (dispatch, getState) => {


    let underlineFalse = false
    let data
    if (status === true) {
        data = { status, selectedSlideIndex, selectedTextIndex, underlineFalse, type }
    }
    else {
        underlineFalse = getState().videos.data.slides[selectedSlideIndex].text[selectedTextIndex].text.style.underline
        data = { status, selectedSlideIndex, selectedTextIndex, underlineFalse, type }
    }
    dispatch({ type: 'UPDATE_FONT_STRIKE', payload: data });

}


export const updateTextAlign = (align, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { align, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_FONT_ALIGN', payload: data });

}


export const updateTextLineHeight = (value, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { value, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_TEXT_LINE_HEIGHT', payload: data });

}

export const updateSlideTextImage = (src, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    const data = { src, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_SLIDE_TEXT_IMAGE', payload: data })
}
export const updateSlideProductImage = (src, selectedSlideIndex) => (dispatch) => {
    const data = { src, selectedSlideIndex }
    dispatch({ type: 'UPDATE_SLIDE_PRODUCT_IMAGE', payload: data })
}

export const updateSlideImage = (image, selectedSlideIndex) => (dispatch) => {

    const data = { image, selectedSlideIndex }
    dispatch({ type: 'UPDATE_SLIDE_IMAGE', payload: data });


}

export const updateSlideVideo = (video, selectedSlideIndex) => (dispatch) => {

    const data = { video, selectedSlideIndex }
    dispatch({ type: 'UPDATE_SLIDE_VIDEO', payload: data });

}

export const removeTtsGenStatus = (data) => (dispatch) => {
    dispatch({ type: 'REMOVE_TTS_GEN_DATA', payload: data });
}

export const updateTtsText = (text, selectedSlideIndex) => (dispatch) => {
    let data = { text, selectedSlideIndex }
    dispatch({ type: 'UPDATE_TTS_TEXT', payload: data });
}

export const updateLogoWatermark = (type, data) => (dispatch) => {
    dispatch({ type: type, payload: data });
}
export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}
export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}

export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}


export const setTtsData = (tts, selectedSlideIndex, enable) => (dispatch) => {
    const data = { tts, selectedSlideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (tts.url !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
    }

}

export const removeTtsFromSlides = (selectedSlideIndex) => (dispatch) => {
    const tts = {
        text: "",
        languageId: "",
        voiceId: "",
        translateText: ""
    }
    const data = { tts, selectedSlideIndex }

    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
}

export const addBgMusic = (url, selectedSlideIndex) => (dispatch) => {

    const data = { url, selectedSlideIndex }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {

    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })

}

export const updateTtsGenStatus = (index, status) => (dispatch) => {
    let jsonData = {
        index: index,
        status: status
    }
    dispatch({ type: 'UPDATE_TTS_GEN_STATUS', payload: jsonData });
}






export const updateDimmerStatus = (status) => (dispatch) => {
    dispatch({ type: 'REMOVE_DIMMER_LAYER', payload: status })
}


//my credits

export const setIntroStatus = (status) => (dispatch) => {

    dispatch({ type: 'SET_INTRO_STATUS', payload: status })
}

export const setOutroStatus = (status) => (dispatch) => {

    dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
}

export const removeIntro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_INTRO' })
}

export const removeOutro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_OUTRO' })
}

export const updateIntroOutro = (type, src) => (dispatch) => {
    dispatch({ type: type, payload: src });
}

export const handleDragStatus = () => (dispatch) => {
    dispatch({ type: 'UPDATE_DRAG_STATUS' })
}
export const addOutroImage = (data) => (dispatch) => {
    dispatch({ type: 'SET_OUTRO_IMAGE', payload: data });
}



export const updateSlideSound = (status, index) => (dispatch) => {
    let jsonData = {
        status: status,
        index: index
    }

    dispatch({ type: 'UPDATE_SOUND_STATUS', payload: jsonData })
}
export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}
export const updateTempVariation = (id, index) => (dispatch) => {
    let data = {
        index: index,
        variation: id
    }

    dispatch({ type: 'UPDATE_TEMPLATE_VARIATION', payload: data });
}

export const applyToAllSlide = (selectedText) => (dispatch) => {

    dispatch({ type: 'APPLY_TO_ALL_SLIDE', payload: selectedText })
}

export const replaceWord = (data, text, index) => (dispatch) => {
    let jsonData = {
        data: data,
        text: text,
        index: index
    }

    dispatch({ type: 'REMOVE_WHITE_SPACE', payload: index });
    dispatch({ type: 'REPLACE_WORDS', payload: jsonData })
}




// my Actions  


export const updateEditedText = (text, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {
    let data = { text, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_SLIDE_TEXT', payload: data });
}

export const updateEditedInnerText = (val, layerId, textId, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { val, layerId, textId, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'UPDATE_SLIDE_INNER_TEXT', payload: data });

}



export const setSelectedTextStatus = (layerId, selectedSlideIndex, selectedTextIndex) => (dispatch) => {

    let data = { layerId, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'SET_TEXT_EDITABLE', payload: data })
}

export const setSelectedLayer = (layerId, textId, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {
    let data = { layerId, textId, selectedSlideIndex, selectedTextIndex, type }
    dispatch({ type: 'SET_LAYER_EDITABLE_TESTIMONIAL', payload: data })
}

export const setSelectedInnerTextStatus = (layerId, textId, selectedSlideIndex, selectedTextIndex, type) => (dispatch) => {

    let data = { layerId, textId, selectedSlideIndex, selectedTextIndex }
    if (type === "testimonials") {
        dispatch(setSelectedLayer(layerId, textId, selectedSlideIndex, selectedTextIndex, type))
        dispatch({ type: 'SET_INNER_TEXT_EDITABLE_TESTIMONIAL', payload: data })
    }
    else if (type === "bonuses" || type === "bonusesDedicated") {
        dispatch(setSelectedLayer(layerId, textId, selectedSlideIndex, selectedTextIndex, type))
        dispatch({ type: 'SET_INNER_TEXT_EDITABLE_BONUSES', payload: data })
    }
    else {
        dispatch({ type: 'SET_INNER_TEXT_EDITABLE_FDEDICATED', payload: data })
    }
}

export const addAffiliateText = (text) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_TEXT', payload: text })
}

export const updateAffiliateStatus = (enable) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_STATUS', payload: enable })
}

export const updateAffiliatePos = (pos) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_POS', payload: pos })
}
export const updateAffiliateSize = (size) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_FONTSIZE', payload: size })
}
export const updateAffiliateFamily = (family) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_FONTFAMILY', payload: family })
}
export const updateAffiliateColor = (color) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_COLOR', payload: color })
}
export const updateAffiliateBgColor = (bgColor) => (dispatch) => {
    dispatch({ type: 'SET_AFFILIATE_BGCOLOR', payload: bgColor })
}





